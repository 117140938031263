import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { ContractDocxLabels } from "common/AppConstants";
import DatePicker from "common/DatePickerInput";
import { useContract } from "hooks/useContract";
import { GET_DOCUMENT_SIGNERS_ARR, UPDATE_DOCUMENT } from "graphql/contracts";
import { useStore } from "utils/store";
import { IPerson } from "models/projects";
import { getPermissions } from "permissions/utils";

import FeeCalculatorModal from "./FeeCalculatorModal";
import { ContractDocxStyles as styles } from "./styles";
import { ISignerArr } from "../utils";
import { IContract, IFillableFields, ITags } from "..";

interface ITagProps {
  item: ITags;
  index: number;
}

const tagColors = ["#FEFEC8", "#D3FED3", "#FFDCDC", "#DDD9FF", "#E7E7E7"];

const TagItem = forwardRef((props: ITagProps, ref: any) => {
  const { item, index } = props;

  const { tags, setTags } = useContract();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [expanded, setExpanded] = useState(false);
  const [tagValue, setTagValue] = useState("");

  useEffect(() => {
    if (Boolean(ref)) {
      const element = ref.current.dom.get(item.id!);
      if (Boolean(element)) {
        setTagValue(element.innerText);
      }
    }
  }, [item.id, ref]);

  return (
    <Box>
      <Box
        sx={[styles.flex_Acenter, { cursor: "pointer" }]}
        onClick={() => {
          setExpanded(!expanded);
          const element = ref.current.dom.get(item.id!);
          if (!expanded) {
            element.style.backgroundColor = tagColors[index % 5];
            element.style.padding = "5px";
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          } else {
            element.style.backgroundColor = "transparent";
            element.style.padding = "0px";
          }
        }}
      >
        <Typography
          sx={[
            styles.field_label,
            styles.text_wrap,
            { fontSize: "14px", width: "90%" },
          ]}
        >
          {item.tagName}
        </Typography>
        {expanded ? (
          <KeyboardArrowUp sx={{ ml: 1 }} color="primary" />
        ) : (
          <KeyboardArrowDown sx={{ ml: 1 }} color="primary" />
        )}
      </Box>
      {!expanded && (
        <Typography
          noWrap
          sx={[
            styles.field_label,
            { fontSize: "10px", textOverflow: "ellipsis", mt: 0.75 },
          ]}
        >
          {item.preview}
        </Typography>
      )}
      {expanded && (
        <TextField
          size="small"
          fullWidth
          value={tagValue}
          onChange={(e) => {
            setTagValue(e.target.value);
            const element = ref.current.dom.get(item.id!);
            element.innerText = e.target.value;
            const tempTags = [...tags];
            tempTags[index].preview = e.target.value;
            setTags([...tempTags]);
          }}
          onFocus={() => {
            const element = ref.current.dom.get(item.id!);
            element.style.backgroundColor = tagColors[index % 5];
            element.style.padding = "5px";
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
          onBlur={() => {
            const element = ref.current.dom.get(item.id!);
            element.style.backgroundColor = "transparent";
            element.style.padding = "0px";
          }}
          multiline
          inputProps={{ style: styles.text_input }}
          sx={{ mt: 0.75 }}
          disabled={
            !(
              Boolean(ROLE_PERMISSIONS) &&
              foldersEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
            )
          }
        />
      )}
    </Box>
  );
});

interface IFillableFieldItem {
  item: IFillableFields;
  index: number;
  setFeeFieldId: Dispatch<SetStateAction<string>>;
  setFeeCalculatorModal: Dispatch<SetStateAction<boolean>>;
  selectedSigners: ISignerArr[];
  setSelectedSigners: Dispatch<SetStateAction<ISignerArr[]>>;
  contractData: IContract | null;
  refetch: any;
  currency: string;
}

const FillableFieldItem = forwardRef((props: IFillableFieldItem, ref: any) => {
  const {
    item,
    index,
    setFeeFieldId,
    selectedSigners,
    setSelectedSigners,
    contractData,
    currency,
  } = props;

  const { fillableFields, setFillableFields } = useContract();
  const params = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [signersArr, setSignersArr] = useState<ISignerArr[]>([]);
  const [multiPersonArr, setMultiPersonArr] = useState<IPerson[]>([]);

  const editable = useMemo(() => {
    return (
      contractData?.documentStatus === "FULLY_EXECUTED" ||
      contractData?.documentStatus === "PARTIALLY_EXECUTED" ||
      contractData?.documentStatus === "APPROVED" ||
      contractData?.documentStatus === "RESCINDED" ||
      contractData?.documentStatus === "ARCHIVED" ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        foldersEditIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
      )
    );
  }, [ROLE_PERMISSIONS, contractData?.documentStatus, foldersEditIndex]);

  const { loading: gettingArr } = useQuery(GET_DOCUMENT_SIGNERS_ARR, {
    variables: {
      document_id: params?.contract_id,
      project_id: params?.project_id,
    },
    skip: !Boolean(params?.contract_id) || !Boolean(params?.project_id),
    onCompleted: (data) => {
      const { getDocumentSigners } = data;
      const { status, fields } = getDocumentSigners;
      if (status) {
        const parsedJSON = Boolean(fields) ? JSON.parse(fields) : null;
        if (Boolean(parsedJSON)) {
          setSignersArr(parsedJSON);
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    let tempSigners: any = [];
    if (contractData) {
      const additionalDetailsJSON = contractData?.additionalDetails;
      const parsedAdditionalDetailsJSON = Boolean(additionalDetailsJSON)
        ? JSON.parse(additionalDetailsJSON!)
        : null;
      if (Boolean(parsedAdditionalDetailsJSON)) {
        if (parsedAdditionalDetailsJSON?.SIGNERS) {
          tempSigners = [...parsedAdditionalDetailsJSON?.SIGNERS];
        }
      }
    }
    setSelectedSigners([...tempSigners]);
  }, [contractData, setSelectedSigners]);

  useEffect(() => {
    if (
      item.fieldMultiple &&
      Boolean(item.fieldArr) &&
      item.fieldValue !== "Not Provided"
    ) {
      setMultiPersonArr(item.fieldArr);
    }
  }, [item.fieldArr, item.fieldMultiple, item.fieldValue]);

  useEffect(() => {
    setLoading(gettingArr);
  }, [gettingArr, setLoading]);

  return (
    <Fragment>
      {(item.fieldType === "text" ||
        item.fieldType === "date" ||
        item.fieldType === "number" ||
        item.fieldType === "currency" ||
        item.fieldType === "percentage") &&
        !item.fieldMultiple && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={[styles.field_label, { fontSize: "14px" }]} noWrap>
              {item.fieldName}
            </Typography>
            {(item.fieldType === "text" ||
              item.fieldType === "number" ||
              item.fieldType === "currency" ||
              item.fieldType === "percentage") && (
              <TextField
                disabled={editable}
                size="small"
                fullWidth
                type={item.fieldType === "text" ? "text" : "number"}
                value={
                  item.fieldType === "text"
                    ? item.fieldValue
                    : item.fieldValue === "" ||
                      item.fieldValue === "Not Provided"
                    ? ""
                    : parseFloat(item.fieldValue.replaceAll(" ", ""))
                }
                onChange={(e) => {
                  const element = ref.current.dom.get(item.id!);
                  if (item.fieldType === "currency") {
                    element.innerText = `${currency}${e.target.value}`;
                  } else if (item.fieldType === "percentage") {
                    element.innerText = `${e.target.value}%`;
                  } else {
                    element.innerText = e.target.value;
                  }
                  const tempFields = [...fillableFields];
                  tempFields[index].fieldValue = e.target.value;
                  setFillableFields([...tempFields]);
                }}
                placeholder={item.fieldDescription}
                onFocus={() => {
                  const element = ref.current.dom.get(item.id!);
                  element.style.backgroundColor = `#FFFF00`;
                  element.style.padding = "5px";
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
                onBlur={() => {
                  const element = ref.current.dom.get(item.id!);
                  element.style.backgroundColor = `transparent`;
                  element.style.padding = "0px";
                }}
                inputProps={{ style: styles.text_input }}
              />
            )}
            {item.fieldType === "date" && !item.fieldMultiple && (
              <DatePicker
                disabled={editable}
                textValue={
                  Boolean(item.fieldValue) && item.fieldValue !== "Not Provided"
                    ? moment(new Date(item.fieldValue)).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(item.fieldValue) ? new Date(item.fieldValue) : null
                }
                onChange={(newValue: any) => {
                  const element = ref.current.dom.get(item.id!);
                  element.innerText = moment(newValue).format("DD MMMM YYYY");
                  const tempFields = [...fillableFields];
                  tempFields[index].fieldValue =
                    moment(newValue).format("YYYY-MM-DD");
                  setFillableFields([...tempFields]);
                }}
                onOpen={() => {
                  const element = ref.current.dom.get(item.id!);
                  element.style.backgroundColor = `#FFFF00`;
                  element.style.padding = "5px";
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
                onCloseCustom={() => {
                  const element = ref.current.dom.get(item.id!);
                  element.style.backgroundColor = `transparent`;
                  element.style.padding = "0px";
                }}
              />
            )}
          </Grid>
        )}
      {item.fieldType === "fees" && !item.fieldMultiple && (
        <Fragment>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Box sx={[styles.flex_Acenter, styles.text_wrap]}>
              <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                {item.fieldName}
              </Typography>
              <Typography sx={[styles.text_input, { ml: 1 }]}>
                {item.fieldValue === "" || item.fieldValue === "Not Provided"
                  ? ""
                  : `${currency}${parseFloat(
                      item.fieldValue
                    ).toLocaleString()}`}
              </Typography>
            </Box>
          </Grid>
          {contractData?.documentStatus !== "FULLY_EXECUTED" &&
            contractData?.documentStatus !== "PARTIALLY_EXECUTED" &&
            contractData?.documentStatus !== "APPROVED" &&
            contractData?.documentStatus !== "RESCINDED" && (
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={4}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  size="small"
                  onClick={() => {
                    const element = ref.current.dom.get(item.id);
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                    setFeeFieldId(item.id);
                    // handleUpdate();
                    // setFeeCalculatorModal(true);
                  }}
                >
                  Calculate
                </Button>
              </Grid>
            )}
        </Fragment>
      )}
      {item.fieldType === "sign" && !item.fieldMultiple && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]} noWrap>
            {item.fieldName}
          </Typography>
          <Autocomplete
            disabled={editable}
            size="small"
            fullWidth
            options={signersArr}
            disableClearable
            value={
              signersArr.length > 0
                ? signersArr.find(
                    (x: any) => x.anchor_string === item.fieldValue
                  ) || {
                    signer_email: "",
                    signer_name: "",
                    anchor_string: "",
                    user_type: "",
                  }
                : {
                    signer_email: "",
                    signer_name: "",
                    anchor_string: "",
                    user_type: "",
                  }
            }
            onChange={(_, newValue) => {
              let tempSigners = [...selectedSigners];
              const index1 = tempSigners.findIndex(
                (x: any) => x.anchor_string === item.fieldValue
              );
              if (index1 > -1) {
                tempSigners[index1] = newValue;
              } else {
                tempSigners.push(newValue);
              }
              setSelectedSigners([...tempSigners]);

              const tempFields = [...fillableFields];
              tempFields[index].fieldValue = newValue.anchor_string;
              setFillableFields([...tempFields]);

              const element = ref.current.dom.get(item.id!);
              element.innerText = `${newValue.anchor_string}`;
            }}
            onFocus={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `#FFFF00`;
              element.style.padding = "5px";
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            onBlur={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `transparent`;
              element.style.padding = "0px";
            }}
            getOptionLabel={(opt) => opt.signer_name}
            renderOption={(props1, opt, _, __) => (
              <li
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                {...props1}
              >
                <Typography textAlign="center">{opt.signer_name}</Typography>
                <Typography
                  sx={[styles.field_label, { fontSize: "13px" }]}
                >{`(${opt.user_type})`}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: styles.text_input,
                }}
              />
            )}
          />
        </Grid>
      )}
      {item.fieldMultiple && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
            {item.fieldName}
          </Typography>
          <Autocomplete
            disabled={editable}
            size="small"
            fullWidth
            options={multiPersonArr}
            disableClearable
            value={
              multiPersonArr.length > 0
                ? multiPersonArr.find(
                    (x: any) => x.name === item.fieldValue
                  ) || {
                    name: "",
                    email: "",
                  }
                : {
                    name: "",
                    email: "",
                  }
            }
            onChange={(_, newValue) => {
              const tempFields = [...fillableFields];
              tempFields[index].fieldValue = newValue.name;
              setFillableFields([...tempFields]);
              const element = ref.current.dom.get(item.id!);
              element.innerText =
                Boolean(item.resolveTo) && item.resolveTo === "email"
                  ? `${newValue.email}`
                  : `${newValue.name}`;
            }}
            onFocus={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `#FFFF00`;
              element.style.padding = "5px";
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            onBlur={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `transparent`;
              element.style.padding = "0px";
            }}
            getOptionLabel={(opt) => opt.name}
            renderOption={(props1, opt, _, __) => (
              <li
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                {...props1}
              >
                <Typography textAlign="center">{opt.name}</Typography>
                <Typography
                  sx={[styles.field_label, { fontSize: "13px" }]}
                >{`(${opt.email})`}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: styles.text_input,
                }}
              />
            )}
          />
        </Grid>
      )}
      {item.fieldType === "image" && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
            {item.fieldName}
          </Typography>
        </Grid>
      )}
    </Fragment>
  );
});

interface IProps {
  setFieldsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  contractData: IContract | null;
  selectedSigners: ISignerArr[];
  setSelectedSigners: Dispatch<SetStateAction<ISignerArr[]>>;
  refetch: any;
  currency: string;
  feeCalculatorAdded: boolean;
}

const FillableFields = forwardRef((props: IProps, ref: any) => {
  const {
    setFieldsDrawerOpen,
    contractData,
    selectedSigners,
    setSelectedSigners,
    refetch,
    currency,
    feeCalculatorAdded,
  } = props;

  const { fillableFields, tags } = useContract();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [feeCalculatorModal, setFeeCalculatorModal] = useState(false);
  const [feeFieldId, setFeeFieldId] = useState("");

  const [fireUpdateContractApi, { loading: updating }] = useMutation(
    UPDATE_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocument } = data;
        const { status, message } = updateDocument;
        if (status) {
          setFeeCalculatorModal(true);
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUpdate = () => {
    if (Boolean(ref?.current?.dom.get("fillable-fields"))) {
      const element = ref?.current?.dom.get("fillable-fields")!;
      element.innerText = JSON.stringify(fillableFields);
      for (let i = 0; i < fillableFields.length; i = i + 1) {
        const ele = ref?.current?.dom.get(fillableFields[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
    } else {
      for (let i = 0; i < fillableFields.length; i = i + 1) {
        const ele = ref?.current?.dom.get(fillableFields[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "fillable-fields",
        },
        JSON.stringify(fillableFields)
      );
    }

    if (Boolean(ref?.current?.dom.get("finka-tags"))) {
      const element = ref?.current?.dom.get("finka-tags")!;
      element.innerText = JSON.stringify(tags);
      for (let i = 0; i < tags.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tags[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
    } else {
      for (let i = 0; i < tags.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tags[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "finka-tags",
        },
        JSON.stringify(tags)
      );
    }

    const blob = new Blob([ref.current.getContent()], { type: "text/html" });
    const file = new File([blob], `${contractData?.name}.html`, {
      type: "text/html",
    });

    let additionalDetails: any = null;
    if (selectedSigners.length > 0) {
      const additionalDetailsJSON = contractData?.additionalDetails;
      const parsedAdditionalDetailsJSON = Boolean(additionalDetailsJSON)
        ? JSON.parse(additionalDetailsJSON!)
        : null;
      if (Boolean(parsedAdditionalDetailsJSON)) {
        additionalDetails = {
          ...parsedAdditionalDetailsJSON,
          SIGNERS: selectedSigners,
        };
      } else {
        additionalDetails = { SIGNERS: selectedSigners };
      }
    }

    fireUpdateContractApi({
      variables: {
        payload: {
          fetchDocumentId: contractData?.id,
          name: contractData?.name,
          isFolder: contractData?.isFolder,
          ...(Boolean(additionalDetails) && {
            additionalDetails: JSON.stringify(additionalDetails),
          }),
        },
        file: file,
      },
    });
  };

  useEffect(() => {
    setLoading(updating);
  }, [setLoading, updating]);

  return (
    <Grid
      container
      spacing={2}
      sx={styles.drawer_container}
      alignItems="center"
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography sx={styles.drawer_header_text}>
          {ContractDocxLabels.FILLABLE_FIELDS}
        </Typography>
        <IconButton size="small" onClick={() => setFieldsDrawerOpen(false)}>
          <Close fontSize="small" />
        </IconButton>
      </Grid>
      {feeCalculatorAdded && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={[styles.flex_Acenter, { justifyContent: "space-between" }]}>
            <Typography
              sx={[
                styles.field_label,
                { fontSize: "16px", mr: 1, fontWeight: 600 },
              ]}
            >
              Fee Calculator
            </Typography>
            <Button
              size="small"
              onClick={() => {
                if (
                  Boolean(ROLE_PERMISSIONS) &&
                  foldersEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
                    ?.FOLDERS_EDIT
                ) {
                  handleUpdate();
                }
                setFeeCalculatorModal(true);
              }}
            >
              Calculate
            </Button>
          </Box>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            {ContractDocxLabels.OVERALL_FIELDS}
          </Typography>
          <Chip sx={styles.chip} label={fillableFields.length} size="small" />
        </Box>
      </Grid>
      {fillableFields.map((item, index) => (
        <FillableFieldItem
          key={item.id}
          item={item}
          index={index}
          ref={ref}
          setFeeFieldId={setFeeFieldId}
          setFeeCalculatorModal={setFeeCalculatorModal}
          selectedSigners={selectedSigners}
          setSelectedSigners={setSelectedSigners}
          contractData={contractData}
          refetch={refetch}
          currency={currency}
        />
      ))}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            {ContractDocxLabels.OVERALL_TAGS}
          </Typography>
          <Chip sx={styles.chip} label={tags.length} size="small" />
        </Box>
      </Grid>
      {tags.map((item, index) => (
        <Grid item lg={12} md={12} sm={12} xs={12} key={item.id}>
          <TagItem item={item} index={index} ref={ref} />
        </Grid>
      ))}
      {feeCalculatorModal && (
        <FeeCalculatorModal
          open={feeCalculatorModal}
          setOpen={setFeeCalculatorModal}
          feeFieldId={feeFieldId}
          contractData={contractData}
          refetch={refetch}
          ref={ref}
        />
      )}
    </Grid>
  );
});

export default FillableFields;
