import React, { Fragment, forwardRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { images } from "assets/images";
import { EngagementStatusArr, ProjectTypeArr } from "utils/constants";
import RadioButtonWithLabel from "common/RadioButtonWithLabel";
import { GlobalLabels } from "common/AppConstants";
import { colors } from "theme/colors";
import {
  CALCULATE_FEE,
  GET_PROJ_DEETS_FEE_CALCULATOR,
} from "graphql/contracts";
import { GET_ARTIST_CLASS_ARR } from "graphql/meta";
import { useStore } from "utils/store";

import { ContractDocxStyles as styles } from "./styles";
import {
  IFeeCalculatorFields,
  feeCalculatorInitialFields,
  feeCalculatorValidationSchema,
} from "../utils";
import { IContract } from "..";

interface IProjectDetails {
  id: string;
  name: string;
  projectType: string;
  projectCategory: string;
  categoryLoading: number;
  collectiveAgreementCast: string;
  collectiveAgreementCrew: string;
  currency: string;
  inAggregate: number;
}

interface IFeeCalculatorModalProps extends IModalProps {
  feeFieldId: string;
  contractData: IContract | null;
  refetch: any;
}

const FeeCalculatorModal = forwardRef(
  (props: IFeeCalculatorModalProps, ref: any) => {
    const { open, setOpen, contractData, refetch } = props;

    // const { fillableFields, setFillableFields, tags } = useContract();
    const params = useParams();
    const { setLoading } = useStore();

    const [initialValues, setInitialValues] = useState<IFeeCalculatorFields>(
      feeCalculatorInitialFields
    );
    const [projectDetails, setProjectDetails] =
      useState<IProjectDetails | null>(null);
    const [showDetails, setShowDetails] = useState(false);
    const [artistClassArr, setArtistClassArr] = useState<string[]>([]);
    const [feeDetails, setFeeDetails] = useState<any>(null);

    const { loading } = useQuery(GET_PROJ_DEETS_FEE_CALCULATOR, {
      variables: { id: params?.project_id },
      skip: !params?.project_id,
      onCompleted: (data) => {
        // setLoading(false);
        const { project } = data;
        const { status, project: rawProject, inAggregate } = project;
        if (status) {
          setProjectDetails({ ...rawProject, inAggregate });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

    const { loading: loadingAC } = useQuery(GET_ARTIST_CLASS_ARR, {
      variables: {
        filter: { artist_type_id: 1 },
      },
      onCompleted: (data) => {
        // setLoading(false);
        const { artistClass } = data;
        const { status, artistClass: rawArtistClasses } = artistClass;
        if (status) {
          setArtistClassArr(rawArtistClasses.map((x: any) => x.name));
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

    // const [fireUpdateContractApi, { loading: updating }] = useMutation(
    //   UPDATE_DOCUMENT,
    //   {
    //     onCompleted: (data) => {
    //       setLoading(false);
    //       const { updateDocument } = data;
    //       const { status, message } = updateDocument;
    //       if (status) {
    //         refetch();
    //         toast.success(message, { delay: 10 });
    //       } else {
    //         toast.error(message, { delay: 10 });
    //       }
    //     },
    //   }
    // );

    const [fireCalculateFeeApi, { loading: calculating }] = useMutation(
      CALCULATE_FEE,
      {
        onCompleted: (data) => {
          const { calculateFee } = data;
          const { status, response, message } = calculateFee;
          if (status) {
            const parsedResponse = Boolean(response)
              ? JSON.parse(response)
              : null;
            setFeeDetails(parsedResponse);
          } else {
            toast.error(message, { delay: 10 });
          }
        },
      }
    );

    const handleClose = () => {
      setOpen(false);
    };

    const handleDialogClose = (_: any, reason: string) => {
      if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
        handleClose();
      }
    };

    const handleCalculate = (values: IFeeCalculatorFields) => {
      fireCalculateFeeApi({
        variables: {
          payload: {
            projectId: params?.project_id,
            fetchDocumentId: params?.contract_id,
            ...values,
          },
        },
      });
    };

    const handleSave = () => {
      // const ele = ref.current.dom.get(feeFieldId);
      // ele.innerText = Boolean(feeDetails)
      //   ? `${
      //       (CurrencyMapping as any)[
      //         (projectDetails as IProjectDetails).currency
      //       ]
      //     }${parseFloat(feeDetails?.FEES).toLocaleString()}`
      //   : `${
      //       (CurrencyMapping as any)[
      //         (projectDetails as IProjectDetails).currency
      //       ]
      //     }0`;

      // const tempFields = [...fillableFields];
      // const index = tempFields.findIndex((x) => x.id === feeFieldId);
      // if (index > -1) {
      //   tempFields[index] = {
      //     ...tempFields[index],
      //     fieldValue: Boolean(feeDetails) ? feeDetails?.FEES : "0",
      //   };
      // }
      // setFillableFields([...tempFields]);
      // if (Boolean(ref?.current?.dom.get("fillable-fields"))) {
      //   const element = ref?.current?.dom.get("fillable-fields")!;
      //   element.innerText = JSON.stringify(tempFields);
      // } else {
      //   ref?.current?.dom.add(
      //     ref?.current?.getBody(),
      //     "div",
      //     {
      //       style: "display:none;",
      //       id: "fillable-fields",
      //     },
      //     JSON.stringify(fillableFields)
      //   );
      // }

      // if (Boolean(ref?.current?.dom.get("finka-tags"))) {
      //   const element = ref?.current?.dom.get("finka-tags")!;
      //   element.innerText = JSON.stringify(tags);
      // } else {
      //   ref?.current?.dom.add(
      //     ref?.current?.getBody(),
      //     "div",
      //     {
      //       style: "display:none;",
      //       id: "finka-tags",
      //     },
      //     JSON.stringify(tags)
      //   );
      // }

      // const blob = new Blob([ref.current.getContent()], { type: "text/html" });
      // const file = new File([blob], `${contractData?.name}.html`, {
      //   type: "text/html",
      // });
      // fireUpdateContractApi({
      //   variables: {
      //     payload: {
      //       fetchDocumentId: contractData?.id,
      //       name: contractData?.name,
      //       isFolder: contractData?.isFolder,
      //     },
      //     file: file,
      //   },
      // });
      refetch();
      handleClose();
    };

    useEffect(() => {
      if (Boolean(contractData)) {
        const parsedAD =
          Boolean(contractData?.additionalDetails) ||
          contractData?.additionalDetails !== "null"
            ? JSON.parse(contractData?.additionalDetails as string)
            : {};

        if (Boolean(parsedAD)) {
          setFeeDetails(parsedAD?.FEE_CALCULATIONS);
          setInitialValues({
            artistType: "Cast",
            employmentStatus:
              parsedAD?.FEE_CALCULATIONS?.employment_status || "PAYG",
            engagementStatus:
              parsedAD?.FEE_CALCULATIONS?.engagement_status || "",
            engagementStatusUnits:
              parsedAD?.FEE_CALCULATIONS?.engagement_status_units || 1,
            castClass: parsedAD?.FEE_CALCULATIONS?.cast_class || "",
            ageStatus: parsedAD?.FEE_CALCULATIONS?.age_status || "Adult",
            hrsWorked:
              parsedAD?.FEE_CALCULATIONS?.hrs_worked || "ORDINARY TIME",
            rateType: parsedAD?.FEE_CALCULATIONS?.rate_type || "AWARD RATE",
            feeAsk: parsedAD?.FEE_CALCULATIONS?.fee_ask || 0,
          });
        }
      }
    }, [contractData]);

    useEffect(() => {
      setLoading(calculating || loading || loadingAC);
    }, [calculating, loading, loadingAC, setLoading]);

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={feeCalculatorValidationSchema}
        onSubmit={handleCalculate}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
        }) => {
          return (
            <Dialog
              open={open}
              onClose={handleDialogClose}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle
                sx={[
                  styles.flex_JCsb_Acenter,
                  { backgroundColor: colors.primary },
                ]}
              >
                <Typography
                  sx={[styles.modal_header_text, { color: colors.secondary }]}
                >
                  Cast Fee Calculator
                </Typography>
                <IconButton onClick={handleClose}>
                  <Close color="secondary" />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ p: 3 }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={styles.flex_Acenter}>
                        <img
                          src={images.FEE_CALCULATOR_PROJECT}
                          alt="project_details"
                        />
                        <Typography sx={{ ml: 1 }}>
                          Project Specifics
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={styles.field_label}>
                        Project Type
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{ style: styles.text_input }}
                        value={
                          Boolean(projectDetails?.projectType)
                            ? ProjectTypeArr.find(
                                (x) => x.id === projectDetails?.projectType
                              )?.name
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={styles.field_label}>
                        Project Category (Feature Films Only)
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{ style: styles.text_input }}
                        value={projectDetails?.projectCategory}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                        <Typography sx={styles.field_label}>
                          Collective Agreement (Cast)
                        </Typography>
                        <Tooltip title="The award for this project">
                          <InfoOutlined
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              color: colors.grey,
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{ style: styles.text_input }}
                        value={projectDetails?.collectiveAgreementCast}
                      />
                    </Grid>
                    {/* <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                        <Typography sx={styles.field_label}>
                          Collective Agreement (Crew)
                        </Typography>
                        <Tooltip title="The award for this project">
                          <InfoOutlined
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              color: colors.grey,
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{ style: styles.text_input }}
                        value={projectDetails?.collectiveAgreementCrew}
                      />
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                        <Typography sx={styles.field_label}>
                          Category Loading (Feature Films only)
                        </Typography>
                        <Tooltip title="Total project loading">
                          <InfoOutlined
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              color: colors.grey,
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{ style: styles.text_input }}
                        value={
                          Boolean(projectDetails) &&
                          (projectDetails as IProjectDetails).hasOwnProperty(
                            "categoryLoading"
                          )
                            ? (projectDetails as IProjectDetails)
                                .categoryLoading
                            : "0"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                        <Typography sx={styles.field_label}>
                          Total Pre-Purchased Rights
                        </Typography>
                        <Tooltip title="Rights loading">
                          <InfoOutlined
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              color: colors.grey,
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        inputProps={{ style: styles.text_input }}
                        value={
                          Boolean(projectDetails?.inAggregate)
                            ? projectDetails?.inAggregate
                            : "0"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                      <Box sx={styles.flex_Acenter}>
                        <img
                          src={images.FEE_CALCULATOR_ARTIST}
                          alt="artist_details"
                          style={{ marginLeft: -3 }}
                        />
                        <Typography sx={{ ml: 1 }}>
                          Artist Deal Specifics
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Artist Type <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      <Autocomplete
                        id="artistType"
                        value={
                          artistTypeArr.find((x) => x === values.artistType) ||
                          ""
                        }
                        onChange={(_, newValue) => {
                          setSelectedArtistType(newValue);
                          setFieldValue("artistType", newValue);
                          setFieldValue("castClass", "");
                        }}
                        onBlur={handleBlur}
                        disableClearable
                        size="small"
                        fullWidth
                        options={artistTypeArr}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: styles.text_input,
                            }}
                          />
                        )}
                      />
                      {touched?.artistType && errors?.artistType && (
                        <Typography sx={styles.error_text}>
                          {errors?.artistType}
                        </Typography>
                      )}
                    </Grid> */}

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Box sx={[styles.flex_Acenter, { mb: 0.25 }]}>
                        <Typography sx={styles.field_label}>
                          Period of Engagement{" "}
                          <span style={{ color: "#D3010E" }}>*</span>
                        </Typography>
                        <Tooltip title="How many days or weeks. Default is 1">
                          <InfoOutlined
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              color: colors.grey,
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <TextField
                        id="engagementStatusUnits"
                        value={values.engagementStatusUnits}
                        onChange={(e) =>
                          setFieldValue(
                            "engagementStatusUnits",
                            parseInt(e.target.value)
                          )
                        }
                        onBlur={handleBlur}
                        type="number"
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.text_input }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      {touched?.engagementStatusUnits &&
                        errors?.engagementStatusUnits && (
                          <Typography sx={styles.error_text}>
                            {errors?.engagementStatusUnits}
                          </Typography>
                        )}
                      <Box sx={[styles.flex_Acenter, { mb: 0.25 }]}>
                        <Typography sx={styles.field_label}>
                          {" "}
                          <span style={{ color: "#D3010E" }}>&nbsp;</span>
                        </Typography>
                        {/* <Tooltip title="Are they being engaged daily, weekly or for a fee?">
                          <InfoOutlined
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              ml: 1,
                              color: colors.grey,
                            }}
                          />
                        </Tooltip> */}
                      </Box>
                      <Autocomplete
                        id="engagementStatus"
                        getOptionLabel={(option) => option.value}
                        value={
                          EngagementStatusArr.find(
                            (x) => x.key === values.engagementStatus
                          ) || { key: "", value: "" }
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("engagementStatus", newValue.key);
                        }}
                        onBlur={handleBlur}
                        disableClearable
                        size="small"
                        fullWidth
                        options={EngagementStatusArr}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: styles.text_input,
                            }}
                          />
                        )}
                      />
                      {touched?.engagementStatus &&
                        errors?.engagementStatus && (
                          <Typography sx={styles.error_text}>
                            {errors?.engagementStatus}
                          </Typography>
                        )}
                    </Grid>

                    <Fragment>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                          <Typography sx={styles.field_label}>
                            Artist Class{" "}
                            <span style={{ color: "#D3010E" }}>*</span>
                          </Typography>
                          <Tooltip title="Class of performer">
                            <InfoOutlined
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                ml: 1,
                                color: colors.grey,
                              }}
                            />
                          </Tooltip>
                        </Box>
                        <Autocomplete
                          id="castClass"
                          value={
                            artistClassArr.find(
                              (x) => x === values.castClass
                            ) || ""
                          }
                          onChange={(_, newValue) => {
                            setFieldValue("castClass", newValue);
                          }}
                          disableClearable
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          options={artistClassArr}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                style: styles.text_input,
                              }}
                            />
                          )}
                        />
                        {touched?.castClass && errors?.castClass && (
                          <Typography sx={styles.error_text}>
                            {errors?.castClass}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography sx={styles.field_label}>
                          Age Status
                        </Typography>
                        <RadioGroup
                          value={values.ageStatus}
                          onChange={(e) =>
                            setFieldValue("ageStatus", e.target.value)
                          }
                        >
                          <RadioButtonWithLabel
                            value="Juvenile"
                            label="Juvenile"
                            labelStyle={styles.text_input}
                          />
                          <RadioButtonWithLabel
                            value="Adult"
                            label="Adult"
                            labelStyle={styles.text_input}
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Box sx={styles.flex_Acenter}>
                          <Typography sx={styles.field_label}>
                            Hours Worked
                          </Typography>
                          <Tooltip title="40 or 50 hr week OR 8 or 10 hour day">
                            <InfoOutlined
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                ml: 1,
                                color: colors.grey,
                              }}
                            />
                          </Tooltip>
                        </Box>
                        <RadioGroup
                          value={values.hrsWorked}
                          onChange={(e) =>
                            setFieldValue("hrsWorked", e.target.value)
                          }
                        >
                          <RadioButtonWithLabel
                            value="ORDINARY TIME"
                            label="Ordinary Time"
                            labelStyle={styles.text_input}
                          />
                          <RadioButtonWithLabel
                            value="OVERTIME"
                            label="Overtime"
                            labelStyle={styles.text_input}
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Box sx={styles.flex_Acenter}>
                          <Typography sx={styles.field_label}>
                            Rate Type
                          </Typography>
                          <Tooltip title="Is the optional rate being used?">
                            <InfoOutlined
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                ml: 1,
                                color: colors.grey,
                              }}
                            />
                          </Tooltip>
                        </Box>
                        <RadioGroup
                          value={values.rateType}
                          onChange={(e) =>
                            setFieldValue("rateType", e.target.value)
                          }
                        >
                          <RadioButtonWithLabel
                            value="AWARD RATE"
                            label="Award Rate"
                            labelStyle={styles.text_input}
                          />
                          <RadioButtonWithLabel
                            value="OPTIONAL RATE"
                            label="Optional Rate"
                            labelStyle={styles.text_input}
                          />
                        </RadioGroup>
                      </Grid>
                    </Fragment>

                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                      <Box sx={styles.flex_Acenter}>
                        <img
                          src={images.FEE_CALCULATOR_FEE}
                          alt="fee_details"
                        />
                        <Typography sx={{ ml: 1 }}>Fee Details</Typography>
                      </Box>
                      <Typography
                        sx={[styles.field_label, { mt: 0.5, fontSize: "14px" }]}
                      >
                        (Please click on the calculate button to fetch the
                        updated fee details)
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography sx={styles.field_label}>
                        Fee Ask <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      <TextField
                        id="feeAsk"
                        value={values.feeAsk}
                        onChange={(e) =>
                          setFieldValue("feeAsk", parseInt(e.target.value))
                        }
                        type="number"
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.text_input }}
                      />
                      {touched?.feeAsk && errors?.feeAsk && (
                        <Typography sx={styles.error_text}>
                          {errors?.feeAsk}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Box sx={{ mt: 2.5 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={!isValid || calculating}
                        >
                          Calculate
                        </Button>
                      </Box>
                    </Grid>
                    {/* <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography sx={styles.field_label}>
                    Fee Ask Description
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    inputProps={{ style: styles.text_input }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} /> */}
                    {Boolean(feeDetails) &&
                      (values.artistType === "Cast" ||
                        values.artistType === "Crew") && (
                        <Fragment>
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography sx={styles.field_label}>
                              Award Minimum
                            </Typography>
                            <TextField
                              value={
                                feeDetails?.award_minimum
                                  ? parseFloat(
                                      feeDetails?.award_minimum
                                    ).toFixed(2)
                                  : ""
                              }
                              size="small"
                              fullWidth
                              disabled
                              inputProps={{ style: styles.text_input }}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} />
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                              <Typography sx={styles.field_label}>
                                Personal Margin
                              </Typography>
                              <Tooltip title="The calculated personal margin">
                                <InfoOutlined
                                  fontSize="small"
                                  sx={{
                                    cursor: "pointer",
                                    ml: 1,
                                    color: colors.grey,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <TextField
                              value={
                                feeDetails?.personal_margin
                                  ? parseFloat(
                                      feeDetails?.personal_margin
                                    ).toFixed(2)
                                  : ""
                              }
                              size="small"
                              fullWidth
                              disabled
                              inputProps={{ style: styles.text_input }}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} />
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Box sx={[styles.flex_Acenter, { mb: 0.75 }]}>
                              <Typography sx={styles.field_label}>
                                BNF
                              </Typography>
                              <Tooltip title="Basic Negotiated Fee">
                                <InfoOutlined
                                  fontSize="small"
                                  sx={{
                                    cursor: "pointer",
                                    ml: 1,
                                    color: colors.grey,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <TextField
                              value={
                                feeDetails?.BNF
                                  ? parseFloat(feeDetails?.BNF).toFixed(2)
                                  : ""
                              }
                              size="small"
                              fullWidth
                              disabled
                              inputProps={{ style: styles.text_input }}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6} />
                        </Fragment>
                      )}
                    {Boolean(feeDetails) && (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography sx={styles.field_label}>
                          Total Fee
                        </Typography>
                        <TextField
                          value={
                            Boolean(feeDetails) &&
                            typeof feeDetails?.FEES !== "string"
                              ? parseFloat(feeDetails?.FEES).toFixed(2)
                              : ""
                          }
                          size="small"
                          fullWidth
                          disabled
                          inputProps={{ style: styles.text_input }}
                        />
                      </Grid>
                    )}
                    <Grid item lg={6} md={6} sm={6} xs={6} />
                    {(values.artistType === "Cast" ||
                      values.artistType === "Crew") &&
                      Boolean(feeDetails) && (
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <Button
                            size="small"
                            endIcon={
                              showDetails ? (
                                <KeyboardArrowUp fontSize="small" />
                              ) : (
                                <KeyboardArrowDown fontSize="small" />
                              )
                            }
                            onClick={() => setShowDetails(!showDetails)}
                          >
                            {showDetails ? "Hide Details" : "Show Details"}
                          </Button>
                        </Grid>
                      )}
                    {Boolean(feeDetails) && showDetails && (
                      <Fragment>
                        <Grid item lg={6} md={6} sm={6} xs={6} />
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mx: { lg: 3, sm: 2 }, width: "100%" }}
                          >
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Loading Percentage Total
                                </Typography>
                                <Tooltip title="The total project based loading from project type and cast loading (if applicable)">
                                  <InfoOutlined
                                    fontSize="small"
                                    sx={{
                                      cursor: "pointer",
                                      ml: 1,
                                      color: colors.grey,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails) ? `${feeDetails?.LT}` : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Loading Cost Total
                                </Typography>
                                <Tooltip title="The BNF multiplied by the loading percentage above">
                                  <InfoOutlined
                                    fontSize="small"
                                    sx={{
                                      cursor: "pointer",
                                      ml: 1,
                                      color: colors.grey,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails)
                                  ? `${parseFloat(feeDetails?.LCT).toFixed(2)}`
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Composite Fee
                                </Typography>
                                <Tooltip title="The BNF + the Loading Cost Total">
                                  <InfoOutlined
                                    fontSize="small"
                                    sx={{
                                      cursor: "pointer",
                                      ml: 1,
                                      color: colors.grey,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails)
                                  ? `${parseFloat(
                                      feeDetails?.composite_fee
                                    ).toFixed(2)}`
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Contracted Overtime
                                </Typography>
                                <Tooltip title="Overtime if a 10 hr day or 50 week is selected">
                                  <InfoOutlined
                                    fontSize="small"
                                    sx={{
                                      cursor: "pointer",
                                      ml: 1,
                                      color: colors.grey,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails)
                                  ? `${parseFloat(feeDetails?.OT).toFixed(2)}`
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Composite Fee and Overtime
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails)
                                  ? `${parseFloat(
                                      feeDetails?.composite_fee_ot
                                    ).toFixed(2)}`
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Annual Leave Amount
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails)
                                  ? `${parseFloat(feeDetails?.AL).toFixed(2)}`
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={styles.field_label}>
                                  Contracted Fee per unit
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                              <Typography sx={styles.text_input}>
                                {Boolean(feeDetails)
                                  ? `${parseFloat(
                                      feeDetails?.contracted_fee_per_unit
                                    ).toFixed(2)}`
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions sx={[styles.flex_JCsb_Acenter, { p: 2 }]}>
                <Typography>{`All the amounts are in ${projectDetails?.currency} currency.`}</Typography>
                <Box sx={styles.flex_Acenter}>
                  <Button variant="outlined" onClick={handleClose}>
                    {GlobalLabels.CANCEL}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    sx={{ ml: 2 }}
                    disabled={!Boolean(feeDetails) || calculating || !isValid}
                  >
                    Save
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
);

export default FeeCalculatorModal;
