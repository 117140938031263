import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import { FilterAltOutlined, Share } from "@mui/icons-material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

import Table from "common/Table";
import { IArr } from "models/common";
import {
  EXPORT_CAST_CREW_CONTRACT_STATUS_REPORT,
  GET_CAST_CREW_CONTRACT_STATUS_REPORT,
} from "graphql/reports";
import { useStore } from "utils/store";
import { ContractStatusMapping } from "utils/constants";
import { GET_CONTACT_DEPARTMENT } from "graphql/meta";
import { BlackActionContainedButton } from "common/Buttons";
import DatePickerInput from "common/DatePickerInput";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IContractReportRow {
  id: string;
  name: string;
  isFolder: boolean;
  additionalDetails: string;
  isUpload: boolean;
  documentStatus: string;
}

function CastCrewContractStatusReport() {
  const { setLoading } = useStore();
  const projectId = useParams();
  const boxRef = useRef(null);

  const [contracts, setContracts] = useState<IContractReportRow[]>([]);
  const [contractsRowCount, setContractsRowCount] = useState(0);
  const [departmentArr, setDepartmentArr] = useState<IArr[]>([]);
  const [selectedDeptFilter, setSelectedDeptFilter] = useState<IArr | null>(
    null
  );
  const [selectedStartDateFilter, setSelectedStartDateFilter] =
    useState<Date | null>(null);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const {} = useQuery(GET_CONTACT_DEPARTMENT, {
    variables: {},
    onCompleted: (data) => {
      const { departments } = data;
      const { status, departments: rawDepts } = departments;
      if (status) {
        setDepartmentArr(rawDepts);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading, fetchMore } = useQuery(
    GET_CAST_CREW_CONTRACT_STATUS_REPORT,
    {
      variables: {
        limit: 50,
        page: 1,
        filter: {
          project_id: projectId?.project_id,
          ...(Boolean(selectedDeptFilter) && {
            document__contact__contact_department__name:
              selectedDeptFilter?.name,
          }),
          ...(Boolean(selectedStartDateFilter) && {
            start_date: moment(selectedStartDateFilter).format("YYYY-MM-DD"),
          }),
        },
      },
      skip: !projectId?.project_id,
      onCompleted: (data) => {
        setLoading(false);
        const { getCastAndCrewReport } = data;
        const { status, count, registerItems } = getCastAndCrewReport;
        if (status) {
          setContractsRowCount(count);
          setContracts(registerItems);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [getReport, { loading: exporting }] = useLazyQuery(
    EXPORT_CAST_CREW_CONTRACT_STATUS_REPORT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { getCastAndCrewReportExport } = data;
        if (Boolean(getCastAndCrewReportExport?.report)) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:application/vnd.ms-excel;base64," +
            encodeURI(getCastAndCrewReportExport?.report);
          hiddenElement.target = "_blank";
          hiddenElement.download = `Cast_Crew_Contract.xlsx`;
          hiddenElement.click();
        } else {
          toast.error("Something went wrong, please try again.", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Role",
        sortable: false,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "contactName",
        headerName: "Cast/Crew Member Name",
        sortable: false,
        minWidth: 230,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "projectRole",
        headerName: "Cast/Crew Position",
        sortable: false,
        minWidth: 190,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "department",
        headerName: "Department",
        sortable: false,
        minWidth: 140,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "startDate",
        headerName: "Start Date",
        sortable: false,
        minWidth: 130,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {params?.value ? moment(params?.value).format("DD MMMM YYYY") : ""}
          </Typography>
        ),
      },
      {
        field: "daysToStart",
        headerName: "Days to Start",
        sortable: false,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "description",
        headerName: "Contract Description",
        sortable: false,
        minWidth: 210,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "contractStatus",
        headerName: "Contract Status",
        headerAlign: "center",
        minWidth: 190,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) =>
          Boolean(params?.value) ? (
            <Chip
              label={(ContractStatusMapping as any)[params.value]?.name}
              sx={[
                styles.chip_variant,
                {
                  color: (ContractStatusMapping as any)[params.value]?.color,
                  backgroundColor: (ContractStatusMapping as any)[params.value]
                    ?.bg,
                },
              ]}
            />
          ) : (
            <></>
          ),
      },
      {
        field: "comments",
        headerName: "Comments",
        sortable: false,
        minWidth: 130,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (contracts.length < contractsRowCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(contracts.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries =
                fetchMoreResult.getCastAndCrewReport.registerItems;
              return {
                getCastAndCrewReport: {
                  ...fetchMoreResult?.getCastAndCrewReport,
                  registerItems: [...contracts, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, contracts, contractsRowCount]);

  useEffect(() => {
    setLoading(exporting || loading);
  }, [exporting, loading, setLoading]);

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: { xs: "flex", sm: "none" },
          mb: 1,
        }}
      >
        <Box
          sx={[
            styles.flex_Acenter,
            { justifyContent: "flex-end", width: "100%" },
          ]}
        >
          <Box>
            <BlackActionContainedButton
              variant="contained"
              disableElevation
              startIcon={<Share color="secondary" />}
              onClick={() =>
                getReport({
                  variables: {
                    filter: {
                      project_id: projectId?.project_id,
                      ...(Boolean(selectedDeptFilter) && {
                        document__contact__contact_department__name:
                          selectedDeptFilter?.name,
                      }),
                      ...(Boolean(selectedStartDateFilter) && {
                        start_date: moment(selectedStartDateFilter).format(
                          "YYYY-MM-DD"
                        ),
                      }),
                    },
                  },
                })
              }
              sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            >
              Export
            </BlackActionContainedButton>
          </Box>
          <Box sx={{ ml: 2 }}>
            <IconButton
              onClick={handleFilterMenuOpen}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FilterAltOutlined
                fontSize="small"
                sx={{
                  color: openFilterMenu
                    ? "#41B199"
                    : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
            <Menu
              anchorEl={filterMenuAnchor}
              open={openFilterMenu}
              onClose={handleFilterMenuClose}
              elevation={1}
              sx={{ "& .MuiMenu-paper": { width: 300 } }}
            >
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      my: -1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        textDecoration: "underline",
                        color: "#00000070",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedDeptFilter(null);
                        setSelectedStartDateFilter(null);
                      }}
                    >
                      Clear Filters
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                      mb: 0.75,
                    }}
                  >
                    Select Department
                  </Typography>
                  <Autocomplete
                    value={
                      Boolean(selectedDeptFilter)
                        ? departmentArr.find(
                            (x) => x.id === selectedDeptFilter!.id
                          )
                        : { id: "", name: "" }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setSelectedDeptFilter(newValue);
                      } else {
                        setSelectedDeptFilter(null);
                      }
                    }}
                    size="small"
                    fullWidth
                    options={departmentArr}
                    getOptionLabel={(option) => option.name}
                    placeholder="Select Status"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderRadius: "6px",
                            fontSize: "13px",
                            color: colors.primaryBlack,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                      mb: 0.75,
                    }}
                  >
                    Select Start Date
                  </Typography>
                  <DatePickerInput
                    textValue={
                      Boolean(selectedStartDateFilter)
                        ? moment(selectedStartDateFilter).format("DD MMMM YYYY")
                        : ""
                    }
                    value={selectedStartDateFilter}
                    onChange={(value, _) => setSelectedStartDateFilter(value)}
                    onOpen={() => {}}
                    background="transparent"
                  />
                </Grid>
              </Grid>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        ref={boxRef}
        sx={{
          height: "calc(100vh - 245px)",
          overflow: "auto",
          mx: -3,
          mb: -3,
        }}
      >
        <Table
          rows={contracts}
          columns={columns}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          disableColumnMenu
          sx={{
            ...styles.table,
            "& .MuiDataGrid-columnHeaders": {
              border: "none",
              backgroundColor: "#B6BCC320",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "1px #e6e6e6 solid",
              borderRadius: 0,
            },
            "& .MuiDataGrid-row:hover": {
              borderBottom: "transparent",
            },
            borderTop: "1px #e6e6e6 solid",
            borderRadius: 0,
          }}
          hideFooter
        />
        <Box
          sx={{
            position: "absolute",
            top: { xs: 160, sm: 195, md: 178 },
            right: 30,
          }}
        >
          <Box
            sx={[styles.flex_Acenter, { display: { xs: "none", sm: "flex" } }]}
          >
            <Box>
              <BlackActionContainedButton
                variant="contained"
                disableElevation
                startIcon={<Share color="secondary" />}
                onClick={() =>
                  getReport({
                    variables: {
                      filter: {
                        project_id: projectId?.project_id,
                        ...(Boolean(selectedDeptFilter) && {
                          document__contact__contact_department__name:
                            selectedDeptFilter?.name,
                        }),
                        ...(Boolean(selectedStartDateFilter) && {
                          start_date: moment(selectedStartDateFilter).format(
                            "YYYY-MM-DD"
                          ),
                        }),
                      },
                    },
                  })
                }
                sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
              >
                Export
              </BlackActionContainedButton>
            </Box>
            <Box sx={{ ml: 2 }}>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 300 } }}
              >
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        my: -1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          textDecoration: "underline",
                          color: "#00000070",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedDeptFilter(null);
                          setSelectedStartDateFilter(null);
                        }}
                      >
                        Clear Filters
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                        mb: 0.75,
                      }}
                    >
                      Select Department
                    </Typography>
                    <Autocomplete
                      value={
                        Boolean(selectedDeptFilter)
                          ? departmentArr.find(
                              (x) => x.id === selectedDeptFilter!.id
                            )
                          : { id: "", name: "" }
                      }
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setSelectedDeptFilter(newValue);
                        } else {
                          setSelectedDeptFilter(null);
                        }
                      }}
                      size="small"
                      fullWidth
                      options={departmentArr}
                      getOptionLabel={(option) => option.name}
                      placeholder="Select Status"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              borderRadius: "6px",
                              fontSize: "13px",
                              color: colors.primaryBlack,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                        mb: 0.75,
                      }}
                    >
                      Select Start Date
                    </Typography>
                    <DatePickerInput
                      textValue={
                        Boolean(selectedStartDateFilter)
                          ? moment(selectedStartDateFilter).format(
                              "DD MMMM YYYY"
                            )
                          : ""
                      }
                      value={selectedStartDateFilter}
                      onChange={(value, _) => setSelectedStartDateFilter(value)}
                      onOpen={() => {}}
                      background="transparent"
                    />
                  </Grid>
                </Grid>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CastCrewContractStatusReport;
