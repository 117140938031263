import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close, LocationOnOutlined } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { UPDATE_ADMIN } from "graphql/admin";
import { GET_CLIENT_DETAILS } from "graphql/clients";
import { GET_ADDRESS_AUTOCOMPLETE, GET_INDUSTRIES } from "graphql/meta";
import { IArr, IGeoCoding, IModalProps } from "models/common";
import { GlobalLabels, ManageClientsLabels } from "common/AppConstants";
import { useStore } from "utils/store";

import { IAdminFields, initialValues as emptyFormValues } from "./utils";
import { ManageClientsStyles as styles } from "./styles";
import PhoneNumberInput from "common/PhoneNumberInput";

interface IEditClientModalProps extends IModalProps {
  selectedClient: string;
  setSelectedClient: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
  setSuccessModal: Dispatch<SetStateAction<boolean>>;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setErrorModal: Dispatch<SetStateAction<boolean>>;
  setErrorText: Dispatch<SetStateAction<string>>;
}

const EditClientModal = (props: IEditClientModalProps) => {
  const {
    open,
    setOpen,
    selectedClient,
    setSelectedClient,
    refetchTableData,
    setSuccessModal,
    setErrorModal,
    setSuccessText,
    setErrorText,
  } = props;

  const { setLoading } = useStore();

  const [initialValues, setInitialValues] =
    useState<IAdminFields>(emptyFormValues);
  const [industryArr, setIndustryArr] = useState<IArr[]>([]);
  const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
  const [addressSearchText, setAddressSearchText] = useState("");
  const [addressField, setAddressField] = useState<IGeoCoding | string | null>(
    null
  );

  const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
    onCompleted: (data) => {
      const { autocompleteAddress } = data;
      setAddressArr(autocompleteAddress);
      if (
        autocompleteAddress.length > 0 &&
        Boolean(addressField) &&
        typeof addressField === "string"
      ) {
        setAddressField(autocompleteAddress[0]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const modalTitle = Boolean(selectedClient)
    ? `${GlobalLabels.EDIT} Client`
    : ManageClientsLabels.ADD_CLIENT;
  const btnTitle = Boolean(selectedClient)
    ? GlobalLabels.UPDATE
    : `${GlobalLabels.CREATE} Client`;

  const handleClose = () => {
    setSelectedClient("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [getClientDetails, { loading: gettingDetails }] = useLazyQuery(
    GET_CLIENT_DETAILS,
    {
      onCompleted: (data) => {
        // setLoading(false);
        const { getCompany } = data;
        const { status, company } = getCompany;
        if (status) {
          const {
            name,
            abnCode,
            website,
            noOfEmployees,
            employeeManagingContracts,
            industry,
            street,
            suburb,
            city,
            state,
            country,
            pincode,
            client,
            address,
          } = company;

          const { mobile } = client;
          let parsedMobile = "";
          if (mobile.length === 10) {
            const tempStr = mobile.slice(1);
            const areaCode = tempStr.substring(0, 3);
            const middle = tempStr.substring(3, 6);
            const last = tempStr.substring(6, 9);
            parsedMobile = `${areaCode} ${middle} ${last}`;
          } else {
            parsedMobile = mobile;
          }

          setInitialValues({
            id: client?.id || "",
            fullName: client?.fullName || "",
            designation: client?.designation || "",
            email: client?.email || "",
            mobile: parsedMobile,
            companyName: name || "",
            abnCode: abnCode || "",
            website: website || "",
            noOfEmployees: noOfEmployees || "",
            employeeManagingContracts: employeeManagingContracts || "",
            industry: industry?.id || "",
            street: street || "",
            city: Boolean(city) ? city || "" : "",
            state: Boolean(state) ? state || "" : "",
            country: Boolean(country) ? country || "" : "",
            pincode: pincode || "",
            suburb: suburb || "",
            address: address || "",
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading: loadingI } = useQuery(GET_INDUSTRIES, {
    variables: {},
    onCompleted: (data) => {
      const { industries } = data;
      setIndustryArr(industries);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUpdateClientApi, { loading: updating }] = useMutation(
    UPDATE_ADMIN,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateUser } = data;
        const { status, message } = updateUser;
        handleClose();
        if (status) {
          setSuccessText(message);
          setSuccessModal(true);
          refetchTableData();
        } else {
          setErrorText(message);
          setErrorModal(true);
        }
      },
    }
  );

  const handleUpsertClient = (values: IAdminFields) => {
    const {
      id,
      fullName,
      designation,
      mobile,
      email,
      country,
      state,
      city,
      address,
      pincode,
      suburb,
      street,
      industry,
      companyName: name,
      abnCode,
      employeeManagingContracts,
      noOfEmployees,
      ...rest
    } = values;
    fireUpdateClientApi({
      variables: {
        payload: {
          id,
          fullName,
          mobile,
          designation,
          companyDetails: {
            name,
            industryId: industry,
            abnCode: abnCode.toString(),
            employeeManagingContracts: employeeManagingContracts.toString(),
            noOfEmployees: noOfEmployees.toString(),
            ...(typeof address !== "string" &&
              address !== null && {
                address: address?.freeformAddress,
                country: address?.country || "",
                state: address?.countrySubdivision || "",
                city: address?.municipality || "",
                suburb: address?.municipalitySubdivision || "",
                pincode: address?.postalCode || "",
                street: address?.streetName || "",
              }),
            ...rest,
          },
        },
      },
    });
  };

  useEffect(() => {
    setAddressField(initialValues.address);
  }, [initialValues.address]);

  useEffect(() => {
    if (addressSearchText) {
      getGeoCodeAddress({ variables: { filter: addressSearchText } });
    }
  }, [addressSearchText, getGeoCodeAddress]);

  useEffect(() => {
    if (Boolean(addressField) && typeof addressField === "string") {
      setAddressSearchText(addressField);
    }
  }, [addressField]);

  useEffect(() => {
    if (Boolean(selectedClient)) {
      getClientDetails({ variables: { id: selectedClient } });
    }
  }, [getClientDetails, selectedClient]);

  useEffect(() => {
    setLoading(gettingDetails || loadingI || updating);
  }, [gettingDetails, loadingI, setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().required("Please enter your full name"),
        email: Yup.string()
          .required("Please enter your email address")
          .email("Please enter a valid email address"),
        mobile: Yup.string()
          .required("Please enter your contact number")
          .matches(
            /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
            "Contact number must be of 10 digits"
          ),
        designation: Yup.string().required("Please enter your designation"),
        companyName: Yup.string().required("Please enter your company name"),
        abnCode: Yup.string()
          .required("Please enter the ABN/ACN")
          .matches(/^\d{9}(\d{2})?$/, "Please provide a 9 or 11 digit number"),
        // pincode: Yup.string().matches(
        //   /^[0-9]{4}$/,
        //   "Post Code must be of 4 digits"
        // ),
        website: Yup.string()
          .matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
            "Please enter a valid url"
          )
          .nullable(),
        noOfEmployees: Yup.string().test(
          "negative test",
          "Please enter a positive value",
          (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
        ),
        employeeManagingContracts: Yup.string().test(
          "negative test",
          "Please enter a positive value",
          (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
        ),
      })}
      onSubmit={handleUpsertClient}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
          <DialogTitle sx={styles.flex_JCsb_Acenter}>
            <Typography sx={styles.modal_header_text}>{modalTitle}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={4} sx={{ p: 3 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.COMP_NAME}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    id="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.companyName && errors?.companyName && (
                    <Typography sx={styles.error_text}>
                      {errors?.companyName}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.ABN_CODE}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    type="number"
                    id="abnCode"
                    value={values.abnCode}
                    onChange={(e) => {
                      setFieldValue("abnCode", e.target.value.toString());
                    }}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.abnCode && errors?.abnCode && (
                    <Typography sx={styles.error_text}>
                      {errors?.abnCode}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.COMP_SITE}
                  </Typography>
                  <TextField
                    id="website"
                    value={values.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.website && errors?.website && (
                    <Typography sx={styles.error_text}>
                      {errors?.website}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.NO_OF_EMP}
                  </Typography>
                  <TextField
                    type="number"
                    id="noOfEmployees"
                    value={values.noOfEmployees}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.noOfEmployees && errors?.noOfEmployees && (
                    <Typography sx={styles.error_text}>
                      {errors?.noOfEmployees}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.EMP_MANAGE_CNCT}
                  </Typography>
                  <TextField
                    type="number"
                    id="employeeManagingContracts"
                    value={values.employeeManagingContracts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.employeeManagingContracts &&
                    errors?.employeeManagingContracts && (
                      <Typography sx={styles.error_text}>
                        {errors?.employeeManagingContracts}
                      </Typography>
                    )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.INDUSTRY}
                  </Typography>
                  <Autocomplete
                    id="industry"
                    value={
                      industryArr.find((x) => x.id === values.industry) || {
                        id: "",
                        name: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      setFieldValue("industry", newValue.id);
                    }}
                    size="small"
                    disableClearable
                    fullWidth
                    options={industryArr}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: styles.text_input,
                        }}
                      />
                    )}
                  />
                  {touched?.industry && errors?.industry && (
                    <Typography sx={styles.error_text}>
                      {errors?.industry}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>Address</Typography>
                  <Autocomplete
                    id="address"
                    getOptionLabel={(option) => option.freeformAddress}
                    value={
                      Boolean(values.address)
                        ? typeof values.address !== "string"
                          ? values.address!
                          : {
                              streetNumber: "",
                              streetName: "",
                              municipalitySubdivision: "",
                              municipality: "",
                              countrySubdivision: "",
                              postalCode: "",
                              country: "",
                              freeformAddress: "",
                            }
                        : undefined
                    }
                    onChange={(event, newValue) => {
                      setFieldValue("address", newValue);
                    }}
                    inputValue={addressSearchText}
                    onInputChange={(event: any, newInputValue) => {
                      if (Boolean(event?.target)) {
                        setAddressSearchText(newInputValue);
                      }
                    }}
                    options={addressArr}
                    popupIcon={
                      <LocationOnOutlined
                        fontSize="small"
                        sx={{ color: "#00000050" }}
                      />
                    }
                    sx={[
                      styles.text_input,
                      {
                        "& .MuiAutocomplete-popupIndicator": {
                          transform: "none",
                        },
                      },
                    ]}
                    size="small"
                    disableClearable
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            ...styles.text_input,
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                    )}
                    noOptionsText={
                      <Typography sx={{ fontSize: "13px", color: "#0f0f0f80" }}>
                        {addressSearchText ? "No match found" : ""}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.FULL_NAME}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    id="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.fullName && errors?.fullName && (
                    <Typography sx={styles.error_text}>
                      {errors?.fullName}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.DESIGNATION}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    id="designation"
                    value={values.designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.designation && errors?.designation && (
                    <Typography sx={styles.error_text}>
                      {errors?.designation}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.EMAIL}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.email && errors?.email && (
                    <Typography sx={styles.error_text}>
                      {errors?.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography sx={styles.info_label_text}>
                    {ManageClientsLabels.CLIENT_FORM.MOBILE}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  {/* <TextField
                    type="number"
                    id="mobile"
                    value={values.mobile}
                    onChange={(e) => {
                      if (e.target.value.toString().length <= 10) {
                        setFieldValue("mobile", e.target.value.toString());
                      }
                    }}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  /> */}
                  <PhoneNumberInput
                    value={values.mobile}
                    onChange={(e) => {
                      setFieldValue("mobile", e.target.value.toString());
                    }}
                    onBlur={handleBlur("mobile")}
                  />
                  {touched?.mobile && errors?.mobile && (
                    <Typography sx={styles.error_text}>
                      {errors?.mobile}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions sx={styles.modal_footer_container}>
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid}
              sx={styles.disabled_btn}
              onClick={() => handleSubmit()}
            >
              {btnTitle}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditClientModal;
