import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { IUpdateContactFieldsNew } from "models/contacts";
import {
  BlackActionContainedButton,
  NewGreenPrimaryContainedButton,
  NewOutlinedBtn,
} from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { useStore } from "utils/store";

interface ISocialsFormProps {
  initialValues: IUpdateContactFieldsNew;
  refetch: any;
  updateContactApi: any;
  profilePicture: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

function SocialsForm(props: ISocialsFormProps) {
  const {
    initialValues,
    refetch,
    updateContactApi,
    setActiveSection,
    setActiveStep,
    profilePicture,
  } = props;

  const { setLoading } = useStore();

  const handleSubmitIndividualForm = (values: IUpdateContactFieldsNew) => {
    const {
      id,
      project_role,
      dob,
      gender,
      next_of_kin_contact_number,
      next_of_kin_name,
      next_of_kin_relationship,
      food_allergies,
      medical_allergies,
      instagram,
      facebook,
      twitter,
      imdb,
    } = values;

    const profileJSON = {
      project_role,
      dob,
      gender,
      food_allergies,
      medical_allergies,
      next_of_kin_contact_number,
      next_of_kin_name,
      next_of_kin_relationship,
      instagram,
      facebook,
      twitter,
      imdb,
    };

    updateContactApi({
      variables: {
        payload: {
          id,
          profile: JSON.stringify(profileJSON),
          ...(profilePicture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profilePicture) &&
          typeof profilePicture !== "string" && {
            profile_picture: profilePicture,
          }),
      },
      onCompleted: (data: any) => {
        setLoading(false);
        const { updateContactBasicDetails } = data;
        const { status, message } = updateContactBasicDetails;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        instagram: Yup.string()
          .matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
            "Please enter a valid url"
          )
          .nullable(),
        facebook: Yup.string()
          .matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
            "Please enter a valid url"
          )
          .nullable(),
        twitter: Yup.string()
          .matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
            "Please enter a valid url"
          )
          .nullable(),
        imdb: Yup.string()
          .matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
            "Please enter a valid url"
          )
          .nullable(),
      })}
      onSubmit={handleSubmitIndividualForm}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        touched,
        errors,
        isValid,
        handleSubmit,
      }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Socials Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Instagram</Typography>
              <TextField
                id="instagram"
                value={values.instagram}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.instagram && (
                <Typography sx={styles.error_text}>
                  {errors?.instagram}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Twitter</Typography>
              <TextField
                id="twitter"
                value={values.twitter}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.twitter && (
                <Typography sx={styles.error_text}>
                  {errors?.twitter}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Facebook</Typography>
              <TextField
                id="facebook"
                value={values.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.facebook && (
                <Typography sx={styles.error_text}>
                  {errors?.facebook}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                IMDb Link/ Website
              </Typography>
              <TextField
                id="imdb"
                value={values.imdb}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.imdb && (
                <Typography sx={styles.error_text}>{errors?.imdb}</Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={[styles.flex_Acenter, { justifyContent: "space-between" }]}
              >
                <BlackActionContainedButton
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#fff",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(4)}
                >
                  Back
                </BlackActionContainedButton>
                <Box sx={[styles.flex_JCfe, { alignItems: "center" }]}>
                  <NewGreenPrimaryContainedButton
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                    }}
                    disableElevation
                    disableRipple
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </NewGreenPrimaryContainedButton>
                  <NewOutlinedBtn
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                      color: "#000",
                      ml: 1,
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => setActiveStep(1)}
                  >
                    Next
                  </NewOutlinedBtn>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default SocialsForm;
