import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  CheckOutlined,
  LocationOnOutlined,
  ModeEditOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { FieldArray, Formik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IPerson, ISPVCompany } from "models/projects";
import { GET_ADDRESS_AUTOCOMPLETE } from "graphql/meta";
import { IGeoCoding } from "models/common";
import { NewPrimaryGreenTextButton } from "common/Buttons";
import { useStore } from "utils/store";
import { UPSERT_SPV_COMPANY_DETAILS } from "graphql/projects";
import { getPermissions } from "permissions/utils";

import { validationSchema } from "./utils";
import { ProjectDetailsStyles as styles } from "../styles";

interface IRightsHoldingCompanyDeetsProps {
  initialValues: ISPVCompany;
  refetch: any;
}

function RightsHoldingCompanyDeets(props: IRightsHoldingCompanyDeetsProps) {
  const { initialValues, refetch } = props;

  const projectId = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x?.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editRightsCompDeets, setEditRightsCompDeets] = useState(false);
  const [address, setAddress] = useState<IGeoCoding | string | null>(null);
  const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
  const [addressSearchText, setAddressSearchText] = useState("");

  const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
    onCompleted: (data) => {
      const { autocompleteAddress } = data;
      setAddressArr(autocompleteAddress);
      if (
        autocompleteAddress.length > 0 &&
        Boolean(address) &&
        typeof address === "string"
      ) {
        setAddress(autocompleteAddress[0]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUpsertCompanyDetailsApi, { loading }] = useMutation(
    UPSERT_SPV_COMPANY_DETAILS,
    {
      onCompleted: (data) => {
        const { upsertSPVCompanyDetails } = data;
        const { status, message } = upsertSPVCompanyDetails;
        setEditRightsCompDeets(false);
        if (status) {
          toast.success(message);
          refetch();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const handleUpsertCompanyDetails = (values: ISPVCompany) => {
    const {
      directors,
      soleDirector,
      authorizedSignatory,
      multipleDirectors,
      country,
      state,
      town,
      postCode,
      suburb,
      address,
      street,
      registrationNumber,
      ...payloadValues
    } = values;
    if (multipleDirectors) {
      const detailsJSON = {
        AUTORIZED_SIGNATORY: authorizedSignatory.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
        DIRECTORS: directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      };
      fireUpsertCompanyDetailsApi({
        variables: {
          payload: {
            ...payloadValues,
            projectId: projectId?.project_id,
            companyType: "RIGHTS_HOLD",
            registrationNumber: registrationNumber.toString(),
            details: JSON.stringify(detailsJSON),
            ...(typeof address !== "string" &&
              address !== null && {
                address: address?.freeformAddress || "",
                country: address?.country || "",
                state: address?.countrySubdivision || "",
                town: address?.municipality || "", //city
                suburb: address?.municipalitySubdivision || "",
                postCode: address?.postalCode || "",
                street: address?.streetName || "",
              }),
          },
        },
      });
    } else {
      const detailsJSON = {
        AUTORIZED_SIGNATORY: authorizedSignatory.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
        SOLE_DIRECTOR: { ...soleDirector, email: soleDirector.email.trim() },
      };
      fireUpsertCompanyDetailsApi({
        variables: {
          payload: {
            ...payloadValues,
            projectId: projectId?.project_id,
            companyType: "RIGHTS_HOLD",
            registrationNumber: registrationNumber.toString(),
            details: JSON.stringify(detailsJSON),
            ...(typeof address !== "string" &&
              address !== null && {
                address: address?.freeformAddress || "",
                country: address?.country || "",
                state: address?.countrySubdivision || "",
                town: address?.municipality || "",
                suburb: address?.municipalitySubdivision || "",
                postCode: address?.postalCode || "",
                street: address?.streetName || "",
              }),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (addressSearchText) {
      getGeoCodeAddress({ variables: { filter: addressSearchText } });
    }
  }, [addressSearchText, getGeoCodeAddress, address]);

  useEffect(() => {
    if (Boolean(address) && typeof address === "string") {
      setAddressSearchText(address);
    }
  }, [address]);

  useEffect(() => {
    if (Boolean(initialValues?.address)) {
      setAddress(initialValues?.address);
    }
  }, [initialValues?.address]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Formik
      initialValues={{ ...initialValues, address }}
      onSubmit={handleUpsertCompanyDetails}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Box sx={{ pr: 4.5, pl: 4 }}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={styles.flex_Acenter}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#000",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    Rights Holding Company Details
                  </Typography>
                </Box>
                <Box sx={{ mt: { xs: 2, md: 0 } }}>
                  {Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    editRightsCompDeets ? (
                      <IconButton size="small" onClick={() => handleSubmit()}>
                        <CheckOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setEditRightsCompDeets(true)}
                      >
                        <ModeEditOutlineOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Company Name{" "}
                {editRightsCompDeets && (
                  <span style={styles.error_text}>*</span>
                )}
              </Typography>
              <TextField
                id="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                disabled={!editRightsCompDeets}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.companyName && errors?.companyName && (
                <Typography sx={styles.error_text}>
                  {errors?.companyName}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                ACN{" "}
                {editRightsCompDeets && (
                  <span style={styles.error_text}>*</span>
                )}
              </Typography>
              <TextField
                id="registrationType"
                value={values.registrationType}
                onChange={(e) => {
                  if (!isNaN(e.target.value as unknown as number)) {
                    if (e.target.value.length <= 9) {
                      setFieldValue("registrationType", e.target.value);
                    }
                  }
                }}
                onBlur={handleBlur}
                size="small"
                fullWidth
                disabled={!editRightsCompDeets}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.registrationType && errors?.registrationType && (
                <Typography sx={styles.error_text}>
                  {errors?.registrationType}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                ABN{" "}
                {editRightsCompDeets && (
                  <span style={styles.error_text}>*</span>
                )}
              </Typography>
              <TextField
                id="registrationNumber"
                value={values.registrationNumber}
                onChange={(e) => {
                  if (!isNaN(e.target.value as unknown as number)) {
                    if (e.target.value.length <= 11) {
                      setFieldValue("registrationNumber", e.target.value);
                    }
                  }
                }}
                onBlur={handleBlur}
                size="small"
                fullWidth
                disabled={!editRightsCompDeets}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.registrationNumber && errors?.registrationNumber && (
                <Typography sx={styles.error_text}>
                  {errors?.registrationNumber}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Place of Incorporation{" "}
                {editRightsCompDeets && (
                  <span style={styles.error_text}>*</span>
                )}
              </Typography>
              <TextField
                id="placeOfIncorporation"
                value={values.placeOfIncorporation}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                disabled={!editRightsCompDeets}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.placeOfIncorporation &&
                errors?.placeOfIncorporation && (
                  <Typography sx={styles.error_text}>
                    {errors?.placeOfIncorporation}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Address</Typography>
              <Autocomplete
                id="address"
                getOptionLabel={(option) => option.freeformAddress}
                value={
                  Boolean(values.address)
                    ? typeof values.address !== "string"
                      ? values.address!
                      : {
                          streetNumber: "",
                          streetName: "",
                          municipalitySubdivision: "",
                          municipality: "",
                          countrySubdivision: "",
                          postalCode: "",
                          country: "",
                          freeformAddress: "",
                        }
                    : undefined
                }
                onChange={(event, newValue) => {
                  setFieldValue("address", newValue);
                }}
                inputValue={addressSearchText}
                onInputChange={(event: any, newInputValue) => {
                  if (Boolean(event?.target)) {
                    setAddressSearchText(newInputValue);
                  }
                }}
                options={addressArr}
                popupIcon={
                  <LocationOnOutlined
                    fontSize="small"
                    sx={{ color: "#00000050" }}
                  />
                }
                sx={[
                  styles.input_field,
                  {
                    "& .MuiAutocomplete-popupIndicator": {
                      transform: "none",
                    },
                  },
                ]}
                size="small"
                disableClearable
                disabled={!editRightsCompDeets}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        ...styles.input_field,
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
                noOptionsText={
                  <Typography sx={{ fontSize: "13px", color: "#0f0f0f80" }}>
                    {addressSearchText ? "No match found" : ""}
                  </Typography>
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Contact Name{" "}
                {editRightsCompDeets && (
                  <span style={styles.error_text}>*</span>
                )}
              </Typography>
              <TextField
                id="contactName"
                value={values.contactName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                disabled={!editRightsCompDeets}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.contactName && errors?.contactName && (
                <Typography sx={styles.error_text}>
                  {errors?.contactName}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Contact Email{" "}
                {editRightsCompDeets && (
                  <span style={styles.error_text}>*</span>
                )}
              </Typography>
              <TextField
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                disabled={!editRightsCompDeets}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.email && errors?.email && (
                <Typography sx={styles.error_text}>{errors?.email}</Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#e6e6e670",
                    borderRadius: "20px",
                  }}
                >
                  <Grid container>
                    <Grid item>
                      <Box
                        sx={{
                          ...(!values.multipleDirectors && {
                            backgroundColor: "#fff",
                          }),
                          ...(!values.multipleDirectors && {
                            border: "1px #D9D9D8 solid",
                          }),
                          borderRadius: "20px",
                          px: 3,
                          py: 1,
                          cursor: editRightsCompDeets ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (editRightsCompDeets) {
                            setFieldValue("multipleDirectors", false);
                            setFieldValue("directors", [
                              { name: "", email: "" },
                            ]);
                          }
                        }}
                      >
                        <Typography
                          sx={{
                            ...(!values.multipleDirectors && {
                              fontWeight: 600,
                            }),
                            fontSize: " 13px",
                            textAlign: "center",
                          }}
                        >
                          Sole Director
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          ...(values.multipleDirectors && {
                            backgroundColor: "#fff",
                          }),
                          ...(values.multipleDirectors && {
                            border: "1px #D9D9D8 solid",
                          }),
                          borderRadius: "20px",
                          px: 3,
                          py: 1,
                          cursor: editRightsCompDeets ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (editRightsCompDeets) {
                            setFieldValue("multipleDirectors", true);
                            setFieldValue("soleDirector", {
                              name: "",
                              email: "",
                            });
                          }
                        }}
                      >
                        <Typography
                          sx={{
                            ...(values.multipleDirectors && {
                              fontWeight: 600,
                            }),
                            fontSize: " 13px",
                            textAlign: "center",
                          }}
                        >
                          Multiple Directors
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {!values.multipleDirectors && (
              <Fragment>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography sx={styles.field_label}>
                    Name{" "}
                    {editRightsCompDeets && (
                      <span style={styles.error_text}>*</span>
                    )}
                  </Typography>
                  <TextField
                    id="soleDirector.name"
                    value={values.soleDirector.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    disabled={!editRightsCompDeets}
                    inputProps={{ style: styles.input_field }}
                  />
                  {touched?.soleDirector && errors?.soleDirector && (
                    <Typography sx={styles.error_text}>
                      {errors?.soleDirector?.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography sx={styles.field_label}>
                    Email{" "}
                    {editRightsCompDeets && (
                      <span style={styles.error_text}>*</span>
                    )}
                  </Typography>
                  <TextField
                    id="soleDirector.email"
                    value={values.soleDirector.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    disabled={!editRightsCompDeets}
                    inputProps={{ style: styles.input_field }}
                  />
                  {touched?.soleDirector && errors?.soleDirector && (
                    <Typography sx={styles.error_text}>
                      {errors?.soleDirector?.email}
                    </Typography>
                  )}
                </Grid>
              </Fragment>
            )}
            {values.multipleDirectors && (
              <FieldArray
                name="directors"
                render={(arrayHelpers: any) => (
                  <Fragment>
                    {values.directors.map((_, index: number) => (
                      <Fragment key={index}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Name{" "}
                            {editRightsCompDeets && (
                              <span style={styles.error_text}>*</span>
                            )}
                          </Typography>
                          <TextField
                            id={`directors[${index}].name`}
                            value={values.directors[index].name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            disabled={!editRightsCompDeets}
                            inputProps={{ style: styles.input_field }}
                          />
                          {touched?.directors &&
                            errors?.directors &&
                            errors?.directors[index] && (
                              <Typography sx={styles.error_text}>
                                {(errors?.directors[index] as IPerson).name}
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          sx={{ position: "relative" }}
                        >
                          <Typography sx={styles.field_label}>
                            Email{" "}
                            {editRightsCompDeets && (
                              <span style={styles.error_text}>*</span>
                            )}
                          </Typography>
                          <TextField
                            id={`directors[${index}].email`}
                            value={values.directors[index].email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            disabled={!editRightsCompDeets}
                            inputProps={{ style: styles.input_field }}
                          />
                          {touched?.directors &&
                            errors?.directors &&
                            errors?.directors[index] && (
                              <Typography sx={styles.error_text}>
                                {(errors?.directors[index] as IPerson).email}
                              </Typography>
                            )}
                          {editRightsCompDeets && (
                            <Box
                              sx={{ position: "absolute", right: -32, top: 60 }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <RemoveCircleOutlineOutlined
                                  sx={{ color: "#DF7D8C", fontSize: 18 }}
                                />
                              </IconButton>
                            </Box>
                          )}
                        </Grid>
                      </Fragment>
                    ))}
                    {Boolean(errors?.directors) &&
                      typeof errors?.directors === "string" && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ px: 2.5 }}>
                            <Typography sx={styles.error_text}>
                              {errors?.directors as string}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    {editRightsCompDeets && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ mt: -2.5 }}>
                          <NewPrimaryGreenTextButton
                            variant="text"
                            size="small"
                            startIcon={
                              <Add
                                fontSize="small"
                                sx={{ color: "#41B19995" }}
                              />
                            }
                            onClick={() =>
                              arrayHelpers.push({ name: "", email: "" })
                            }
                          >
                            Add
                          </NewPrimaryGreenTextButton>
                        </Box>
                      </Grid>
                    )}
                  </Fragment>
                )}
              />
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                Authorized Signatory
              </Typography>
            </Grid>
            <FieldArray
              name="authorizedSignatory"
              render={(arrayHelpers: any) => (
                <Fragment>
                  {values.authorizedSignatory.map((_, index: number) => (
                    <Fragment key={index}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography sx={styles.field_label}>
                          Name{" "}
                          {editRightsCompDeets && (
                            <span style={styles.error_text}>*</span>
                          )}
                        </Typography>
                        <TextField
                          id={`authorizedSignatory[${index}].name`}
                          value={values.authorizedSignatory[index].name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={!editRightsCompDeets}
                          inputProps={{ style: styles.input_field }}
                        />
                        {touched?.authorizedSignatory &&
                          errors?.authorizedSignatory &&
                          errors?.authorizedSignatory[index] && (
                            <Typography sx={styles.error_text}>
                              {
                                (errors?.authorizedSignatory[index] as IPerson)
                                  .name
                              }
                            </Typography>
                          )}
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        sx={{ position: "relative" }}
                      >
                        <Typography sx={styles.field_label}>
                          Email{" "}
                          {editRightsCompDeets && (
                            <span style={styles.error_text}>*</span>
                          )}
                        </Typography>
                        <TextField
                          id={`authorizedSignatory[${index}].email`}
                          value={values.authorizedSignatory[index].email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={!editRightsCompDeets}
                          inputProps={{ style: styles.input_field }}
                        />
                        {touched?.authorizedSignatory &&
                          errors?.authorizedSignatory &&
                          errors?.authorizedSignatory[index] && (
                            <Typography sx={styles.error_text}>
                              {
                                (errors?.authorizedSignatory[index] as IPerson)
                                  .email
                              }
                            </Typography>
                          )}
                        {editRightsCompDeets && (
                          <Box
                            sx={{ position: "absolute", right: -32, top: 60 }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <RemoveCircleOutlineOutlined
                                sx={{ color: "#DF7D8C", fontSize: 18 }}
                              />
                            </IconButton>
                          </Box>
                        )}
                      </Grid>
                    </Fragment>
                  ))}
                  {Boolean(errors?.authorizedSignatory) &&
                    typeof errors?.authorizedSignatory === "string" && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ px: 2.5 }}>
                          <Typography sx={styles.error_text}>
                            {errors?.authorizedSignatory as string}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  {editRightsCompDeets && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={{ mt: -2.5 }}>
                        <NewPrimaryGreenTextButton
                          variant="text"
                          size="small"
                          startIcon={
                            <Add fontSize="small" sx={{ color: "#41B19995" }} />
                          }
                          onClick={() =>
                            arrayHelpers.push({ name: "", email: "" })
                          }
                        >
                          Add
                        </NewPrimaryGreenTextButton>
                      </Box>
                    </Grid>
                  )}
                </Fragment>
              )}
            />
          </Grid>
        </Box>
      )}
    </Formik>
  );
}

export default RightsHoldingCompanyDeets;
