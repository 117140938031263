import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { toast } from "react-toastify";
import queryString from "query-string";

import { RouteNames } from "routes/routeNames";
import { LoginLabels } from "common/AppConstants";
import { LOGIN_USER } from "graphql/users";
import { useStore } from "utils/store";
import { StorageConstants } from "utils/storage";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { images } from "assets/images";

import { LoginStyles as styles } from "./styles";

interface ILoginFields {
  email: string;
  password: string;
}

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useStore();

  const [rememberMe, setRememberMe] = useState(false);

  const parseQueryString = location?.search
    ? queryString.parse(location.search)
    : null;

  // const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");

  const [fireLoginApi, { loading: loggingIn }] = useMutation(LOGIN_USER, {
    onCompleted: (data) => {
      setLoading(false);
      const { loginUser } = data;
      const { status, error, token, device, confirmed, me } = loginUser;
      if (status) {
        sessionStorage.setItem(StorageConstants.TOKEN, token);
        sessionStorage.setItem(StorageConstants.LOGGED_IN_USER_ID, me.id);
        if (JSON.parse(confirmed)) {
          sessionStorage.removeItem(StorageConstants.DEVICE);
          navigate(RouteNames.TWO_FA_CODE);
        } else {
          sessionStorage.setItem(StorageConstants.DEVICE, device);
          navigate(RouteNames.LOGIN_SUCCESS);
        }
      } else {
        //error toast
        setLoginError(error);
      }
    },
  });

  const onLoginFormSubmit = (values: ILoginFields) => {
    fireLoginApi({
      variables: { payload: { ...values, email: values.email.trim() } },
    });
  };

  useEffect(() => {
    setLoading(loggingIn);
  }, [loggingIn, setLoading]);

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, { delay: 10 });
      toast.onChange((toastOpt) => {
        if (toastOpt.status === "removed") {
          setLoginError("");
        }
      });
    }
  }, [loginError]);

  useEffect(() => {
    if (Boolean(parseQueryString) && parseQueryString?.redirect) {
      sessionStorage.setItem(
        "redirect_url",
        parseQueryString?.redirect as string
      );
    }
  }, [navigate, parseQueryString]);

  return (
    <Box
      sx={[
        styles.content_center,
        {
          width: { xs: "100%", sm: "75%", md: "48%" },
          py: { xs: 4, md: 0 },
          px: { xs: 4, sm: 8, md: 0 },
          backgroundColor: { xs: "#fff", md: "none" },
          borderRadius: { xs: "15px", md: 0 },
          mx: { xs: 4, md: 0 },
        },
      ]}
    >
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={onLoginFormSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter your email address"),
          password: Yup.string().required("Please enter your password"),
        })}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          handleSubmit,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={[styles.textfield, styles.content_center]}>
                  <img src={images.LOGO} alt="logo" />
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={[styles.textfield, styles.textfield_container]}>
                  <TextField
                    id="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    sx={styles.textfield}
                    inputProps={{ style: styles.input_field }}
                  />
                  {touched?.email && errors?.email && (
                    <Typography sx={styles.error_text}>
                      {errors.email}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={styles.textfield}>
                  <TextField
                    id="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    sx={styles.textfield}
                    type="password"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton
                    //         size="small"
                    //         onClick={() => setShowPassword(!showPassword)}
                    //       >
                    //         {showPassword ? (
                    //           <VisibilityOffOutlined
                    //             fontSize="small"
                    //             sx={styles.pwd_icon}
                    //           />
                    //         ) : (
                    //           <VisibilityOutlined
                    //             fontSize="small"
                    //             sx={styles.pwd_icon}
                    //           />
                    //         )}
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    inputProps={{ style: styles.input_field }}
                  />
                  {touched?.password && errors?.password && (
                    <Typography sx={styles.error_text}>
                      {errors.password}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box
                  sx={[
                    styles.textfield,
                    {
                      mt: -3,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: { xs: "column", sm: "row" },
                    },
                  ]}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={
                          <CheckBox
                            fontSize="small"
                            sx={{ color: "#a6a6a6" }}
                          />
                        }
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="small"
                            sx={{ color: "#a6a6a6" }}
                          />
                        }
                        size="small"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#a6a6a6",
                        }}
                      >
                        Remember Me
                      </Typography>
                    }
                  />
                  <Typography
                    sx={styles.forgot_pwd}
                    onClick={() => navigate(RouteNames.FORGOT_PWD)}
                  >
                    {LoginLabels.FORGOT_PWD}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <NewGreenPrimaryContainedButton
                  variant="contained"
                  size="small"
                  sx={styles.login_btn}
                  type="submit"
                  disabled={!isValid}
                  disableElevation
                  disableRipple
                >
                  Log in
                </NewGreenPrimaryContainedButton>
              </Grid>
              {/* <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <Box sx={{ width: { xs: "100%", md: "540px" } }}>
                <Divider sx={styles.divider}>{GlobalLabels.OR}</Divider>
              </Box>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <OutlinedBtn
                variant="outlined"
                sx={[styles.social_btn, { mr: "12px" }]}
                startIcon={<img src={images.GOOGLE} alt="Google" />}
              >
                {LoginLabels.GOOGLE}
              </OutlinedBtn>
              <OutlinedBtn
                variant="outlined"
                sx={[styles.social_btn, { ml: "12px" }]}
                startIcon={<img src={images.MICROSOFT} alt="Microsoft" />}
              >
                {LoginLabels.MICROSOFT}
              </OutlinedBtn>
            </Grid> */}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={[styles.textfield, { mt: -2 }]}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "12px",
                      color: "#6f6f6f",
                    }}
                  >
                    Don’t have an account?{" "}
                    <span style={{ fontWeight: 600 }}>Email us</span> at
                    info@finka.me
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginForm;
