import { gql } from "@apollo/client";

export const GET_ALL_AGENTS = gql`
  query getAllAgents(
    $filter: AgentFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getAllAgents(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      message
      count
      agents {
        id
        companyName
        spocName
        spocEmailId
        spocMobile
        website
        address
        street
        suburb
        companyCity
        companyState
        companyCountry
        pinCode
        abn
        acn
        registrationNumber
        publicLiabilityPolicy
        provider
        policyNumber
        insurancePeriod
        photo
        agentFinancialDetails {
          id
          accountName
          accountNumber
          accountBsb
          accountSwiftCode
          bankName
          branchName
          accountInstructions
          abn
          superannuationFundName
          superannuationAccountNo
          taxFileNo
        }
      }
    }
  }
`;

export const CREATE_AGENT = gql`
  mutation createAgent($payload: AgentCreateInput!, $profile_picture: Upload) {
    createAgent(payload: $payload, profile_picture: $profile_picture) {
      status
      message
    }
  }
`;

export const UPDATE_AGENT = gql`
  mutation updateAgentDetails(
    $payload: AgentUpdateInput!
    $profile_picture: Upload
  ) {
    updateAgentDetails(payload: $payload, profile_picture: $profile_picture) {
      status
      message
    }
  }
`;

export const GET_AGENT_DETAILS = gql`
  query getAgentDetails($id: String!) {
    getAgentDetails(id: $id) {
      status
      message
      agentDetails {
        id
        companyName
        spocName
        spocEmailId
        spocMobile
        website
        address
        street
        suburb
        companyCity
        companyState
        companyCountry
        pinCode
        abn
        acn
        registrationNumber
        publicLiabilityPolicy
        provider
        policyNumber
        insurancePeriod
        photo
        agentFinancialDetails {
          id
          accountName
          accountNumber
          accountBsb
          accountSwiftCode
          bankName
          branchName
          accountInstructions
          abn
          superannuationFundName
          superannuationAccountNo
          taxFileNo
        }
      }
    }
  }
`;

export const DELETE_AGENT = gql`
  mutation deleteAgent($id: String!) {
    deleteAgent(id: $id) {
      status
      message
    }
  }
`;
