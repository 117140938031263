import { gql } from "@apollo/client";

export const GET_ADMINS = gql`
  query users(
    $limit: Int
    $page: Int
    $filter: UserFilterInput
    $isSuperAdmin: Boolean
    $sorted: String
  ) {
    users(
      limit: $limit
      page: $page
      filter: $filter
      isSuperAdmin: $isSuperAdmin
      sorted: $sorted
    ) {
      count
      users {
        id
        fullName
        mobile
        email
        roles
        createdDate
        profilePicture
      }
    }
  }
`;

export const GET_ADMIN_DETAILS = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      fullName
      mobile
      email
      roles
      createdDate
      profilePicture
    }
  }
`;

export const ADD_ADMIN = gql`
  mutation createAdmin($payload: createUserInput!, $file: Upload) {
    createUser(payload: $payload, file: $file) {
      status
      message
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateUser($payload: UserUpdateInput!, $file: Upload) {
    updateUser(payload: $payload, file: $file) {
      status
      message
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteUser($ids: [String!]) {
    deleteUser(ids: $ids) {
      status
      error
    }
  }
`;
