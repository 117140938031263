import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttachFile,
  Close,
  FiberManualRecord,
  KeyboardArrowDown,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { UPSERT_INDEX_ITEM_DOCUMENT } from "graphql/indexes";
import { GET_SPV_COMPANIES_DROPDOWN } from "graphql/projects";
import {
  GET_CONTRACT_FILE,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_STATUS,
} from "graphql/contracts";
import { useStore } from "utils/store";
import { BlackActionContainedButton } from "common/Buttons";
import { ContractStatusArr, ContractStatusMapping } from "utils/constants";
import { RouteNames } from "routes/routeNames";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface ISPVCompanyArr extends IArr {
  companyType: string;
}

interface IUploadContractFields {
  // contactId: string;
  inhouseCompanyId: string;
  file: any;
}

interface IUploadModalProps extends IModalProps {
  selectedIndexItem: string;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  selectedIndexItemName: string;
  setSelectedIndexItemName: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
  reUploadDocId: string;
  setReUploadDocId: Dispatch<SetStateAction<string>>;
  nestedIdArr: string[];
}

const UploadModal = (props: IUploadModalProps) => {
  const {
    open,
    setOpen,
    selectedIndexItem,
    setSelectedIndexItem,
    selectedIndexItemName,
    setSelectedIndexItemName,
    refetchTableData,
    reUploadDocId,
    setReUploadDocId,
    nestedIdArr,
  } = props;

  const { setLoading } = useStore();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const createContactHook = useContactCreateModal();
  // const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  // const contactsEditIndex = useMemo(
  //   () =>
  //     ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
  //       x.hasOwnProperty("CONTACTS_EDIT")
  //     ),
  //   [ROLE_PERMISSIONS]
  // );

  const [initialValues, setInitialValues] = useState<IUploadContractFields>({
    // contactId: "",
    inhouseCompanyId: "",
    file: null,
  });
  // const [contactsArr, setContactsArr] = useState<IContact[]>([]);
  const [inhouseCompanyArr, setInhouseCompanyArr] = useState<ISPVCompanyArr[]>(
    []
  );
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [documentStatus, setDocumentStatus] = useState("APPROVAL_PENDING");
  const [openCnctStatusMenu, setOpenCnctStatusMenu] = useState(false);
  const [cnctStatusAnchor, setCnctStatusAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleCnctStatusClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setOpenCnctStatusMenu(true);
    setCnctStatusAnchor(e.currentTarget);
  };

  const handleCnctStatusClose = () => {
    setOpenCnctStatusMenu(false);
    setCnctStatusAnchor(null);
  };

  const handleClose = () => {
    setReUploadDocId("");
    setSelectedIndexItem("");
    setSelectedIndexItemName("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  // const { loading: gettingArr } = useQuery(GET_CONTACT_ARR, {
  //   variables: {
  //     filter: { contactProjects__projectId: params?.project_id },
  //     sorted: "full_name",
  //   },
  //   onCompleted: (data) => {
  //     // setLoading(false);
  //     const { getAllContacts } = data;
  //     const { status, contacts } = getAllContacts;
  //     if (status) {
  //       setContactsArr(contacts);
  //     }
  //   },
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "network-only",
  // });

  const { loading: gettingCompanyArr } = useQuery(GET_SPV_COMPANIES_DROPDOWN, {
    variables: { project_id: params?.project_id },
    onCompleted: (data) => {
      // setLoading(false);
      const { spvCompanies } = data;
      const { status, spvCompanies: rawSpvCompanies } = spvCompanies;
      if (status) {
        setInhouseCompanyArr(
          rawSpvCompanies.map((x: any) => ({
            id: x.id,
            name: x.companyName,
            companyType: x.companyType,
          }))
        );
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingDetails, refetch } = useQuery(GET_CONTRACT_FILE, {
    variables: { payload: { documentId: reUploadDocId } },
    skip: !Boolean(reUploadDocId),
    onCompleted: (data) => {
      // setLoading(false);
      const { getDocumentFile } = data;
      const { status, document, file } = getDocumentFile;
      if (status) {
        setFileName(document?.fileName || "");
        setDocumentStatus(document?.documentStatus || "APPROVAL_PENDING");
        setInitialValues({
          // contactId: document?.contact?.id || "",
          inhouseCompanyId: document?.inhouseCompany?.id || "",
          file: file,
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUploadContractApi, { loading }] = useMutation(
    UPSERT_INDEX_ITEM_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { linkRegisterItemDocument } = data;
        const { status, message, id } = linkRegisterItemDocument;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
          handleClose();
          if (fileName.endsWith(".pdf")) {
            sessionStorage.setItem("upload_pdf_back_url", location?.pathname);
            navigate(
              `${RouteNames.PROJECTS}/${params?.project_id}${RouteNames.CONTRACT}-upload-pdf/${id}`
            );
          }
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireUpdateContractFileApi, { loading: updating }] = useMutation(
    UPDATE_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocument } = data;
        const { status, message } = updateDocument;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
          handleClose();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireUpdateCnctStatusApi, { loading: updatingStatus }] = useMutation(
    UPDATE_DOCUMENT_STATUS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocumentStatus } = data;
        const { status, message } = updateDocumentStatus;
        if (status) {
          toast.success(message, { delay: 10 });
          refetch();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUploadContract = (values: IUploadContractFields) => {
    const { file, ...rest } = values;
    if (Boolean(file)) {
      setFileError("");
      if (Boolean(reUploadDocId)) {
        fireUpdateContractFileApi({
          variables: {
            payload: {
              fetchDocumentId: reUploadDocId,
              name: selectedIndexItemName,
              isFolder: false,
              isUpload: true,
              ...rest,
            },
            ...(typeof file !== "string" && { file: file }),
          },
        });
      } else {
        fireUploadContractApi({
          variables: {
            payload: {
              projectId: params?.project_id,
              registerId: nestedIdArr[nestedIdArr.length - 1],
              registerItemId: selectedIndexItem,
              isUpload: true,
              documentType: "CONTRACTS",
              ...rest,
            },
            file: file,
          },
        });
      }
    } else {
      setFileError("Please upload a file");
    }
  };

  useEffect(() => {
    setLoading(
      gettingCompanyArr ||
        gettingDetails ||
        loading ||
        updating ||
        updatingStatus
    );
  }, [
    gettingCompanyArr,
    gettingDetails,
    loading,
    setLoading,
    updating,
    updatingStatus,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUploadContract}
      validationSchema={Yup.object().shape({
        // contactId: Yup.string().required("Please enter the counter party name"),
        inhouseCompanyId: Yup.string().required(
          "Please select an in-house company"
        ),
      })}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth={"sm"}
          PaperProps={{ sx: { borderRadius: "24px" } }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ pt: 6, px: 6, pb: 3, position: "relative" }}>
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose}>
                    <Close sx={{ color: "#00000055" }} fontSize="small" />
                  </IconButton>
                </Box>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      First Party <span style={styles.error_text}>*</span>
                    </Typography>
                    <Autocomplete
                      disabled={Boolean(reUploadDocId)}
                      id="inhouseCompanyId"
                      value={
                        inhouseCompanyArr.find((x) =>
                          values.inhouseCompanyId.includes(x.id)
                        ) || { id: "", name: "", companyType: "" }
                      }
                      onChange={(_, newValue) =>
                        setFieldValue("inhouseCompanyId", newValue.id)
                      }
                      sx={styles.input_field}
                      size="small"
                      disableClearable
                      fullWidth
                      options={inhouseCompanyArr}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props1, opt, _, __) => (
                        <Typography
                          {...props1}
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {opt.name}{" "}
                          <span style={{ color: "#00000080", fontWeight: 400 }}>
                            &nbsp;
                            {`(${
                              opt.companyType === "PRODUCTION"
                                ? "Production Company"
                                : "Rights Holding Company"
                            })`}
                          </span>
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                    />
                    {touched?.inhouseCompanyId && errors?.inhouseCompanyId && (
                      <Typography sx={styles.error_text}>
                        {errors?.inhouseCompanyId}
                      </Typography>
                    )}
                  </Grid>
                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Second Party <span style={styles.error_text}>*</span>
                    </Typography>
                    <Autocomplete
                      disabled={Boolean(reUploadDocId)}
                      id="contactId"
                      value={
                        contactsArr.find((x) =>
                          values.contactId.includes(x.id)
                        ) || {
                          id: "",
                          fullName: "",
                          department: "",
                          emailId: "",
                          mobile: "",
                          createdDate: "",
                          profile: null,
                          profile_picture: null,
                          contactDepartment: { id: "", name: "" },
                        }
                      }
                      onChange={(_, newValue) =>
                        setFieldValue("contactId", newValue.id)
                      }
                      sx={styles.input_field}
                      size="small"
                      disableClearable
                      fullWidth
                      options={contactsArr}
                      getOptionLabel={(option) => option.fullName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                      noOptionsText={
                        Boolean(ROLE_PERMISSIONS) &&
                        contactsEditIndex !== undefined &&
                        ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]
                          ?.CONTACTS_EDIT ? (
                          <BlackTextButton
                            variant="text"
                            startIcon={<Add sx={{ color: "#000" }} />}
                            onClick={() => {
                              createContactHook.onOpen();
                              navigate(
                                `${RouteNames.PROJECTS}/${params.project_id}/project-contacts`
                              );
                            }}
                            disableElevation
                            disableRipple
                          >
                            Add New Contact
                          </BlackTextButton>
                        ) : (
                          "No Contacts"
                        )
                      }
                      renderOption={(props1, opt, _, __) => (
                        <li
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          {...props1}
                        >
                          <Typography textAlign="center">
                            {opt.fullName}
                          </Typography>
                          <Typography
                            sx={[styles.field_label, { fontSize: "13px" }]}
                          >{`(${opt.emailId})`}</Typography>
                        </li>
                      )}
                    />
                    {touched?.contactId && errors?.contactId && (
                      <Typography sx={styles.error_text}>
                        {errors?.contactId}
                      </Typography>
                    )}
                  </Grid> */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Attachment <span style={styles.error_text}>*</span>
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          value={
                            Boolean(values.file)
                              ? typeof values.file === "string"
                                ? fileName
                                : (values.file as any)["name"]
                              : "Select a file"
                          }
                          disabled
                          size="small"
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#3b3b3b80",
                            },
                          }}
                          fullWidth
                          inputProps={{
                            style: {
                              backgroundColor: "#e6e6e640",
                              borderRadius: "6px",
                              fontSize: "13px",
                              color: "#3b3b3b80",
                            },
                          }}
                        />
                        {Boolean(fileError) && (
                          <Typography sx={styles.error_text}>
                            {fileError}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box sx={[styles.flex_JCsb, { alignItems: "center" }]}>
                          <Box sx={styles.flex_Acenter}>
                            <Box>
                              <Upload
                                beforeUpload={(file: RcFile, __) => {
                                  if (file.size <= 25000000) {
                                    setFileError("");
                                    setFieldValue("file", file);
                                    setFileName((file as any)["name"]);
                                  } else {
                                    setFileError(
                                      "Please select a file of less than 25KB"
                                    );
                                  }
                                  return Promise.resolve();
                                }}
                              >
                                <IconButton size="small">
                                  <AttachFile
                                    sx={{ color: "#a6a6a6" }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Upload>
                            </Box>
                            {Boolean(values.file) &&
                              typeof values.file === "string" && (
                                <Box>
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      window.open(values.file, "_blank")
                                    }
                                  >
                                    <VisibilityOutlined
                                      sx={{ color: "#a6a6a6" }}
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Box>
                              )}
                          </Box>
                          {Boolean(reUploadDocId) && (
                            <Box
                              component="div"
                              sx={[
                                styles.flex_JCsb,
                                {
                                  alignItems: "center",
                                  backgroundColor: (
                                    ContractStatusMapping as any
                                  )[documentStatus].bg,
                                  p: 1,
                                  borderRadius: "20px",
                                  cursor: "pointer",
                                  minWidth: "120px",
                                },
                              ]}
                              onClick={handleCnctStatusClick}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: (ContractStatusMapping as any)[
                                    documentStatus
                                  ].color,
                                  fontWeight: 600,
                                  ml: 1,
                                  flexGrow: 1,
                                  textAlign: "center",
                                }}
                              >
                                {
                                  (ContractStatusMapping as any)[documentStatus]
                                    .name
                                }
                              </Typography>
                              <KeyboardArrowDown
                                fontSize="small"
                                sx={{
                                  ml: 1,
                                  color: (ContractStatusMapping as any)[
                                    documentStatus
                                  ].color,
                                }}
                              />
                            </Box>
                          )}
                          <Menu
                            anchorEl={cnctStatusAnchor}
                            open={openCnctStatusMenu}
                            onClose={handleCnctStatusClose}
                            sx={styles.menu_container}
                            elevation={0}
                          >
                            <MenuList>
                              {ContractStatusArr.map((item) => (
                                <MenuItem
                                  key={item.id}
                                  onClick={() => {
                                    setDocumentStatus(item.id);
                                    fireUpdateCnctStatusApi({
                                      variables: {
                                        payload: {
                                          documentId: reUploadDocId,
                                          documentStatus: item.id,
                                        },
                                      },
                                    });
                                    handleCnctStatusClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <FiberManualRecord
                                      fontSize="small"
                                      sx={{
                                        color: (ContractStatusMapping as any)[
                                          item.id
                                        ].color,
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: `${colors.primaryBlack}80`,
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                  </ListItemText>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={styles.flex_JCfe}>
                      <BlackActionContainedButton
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          ml: 1,
                          fontSize: "13px",
                          fontWeight: 600,
                          width: "120px",
                        }}
                        disableElevation
                        disableRipple
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </BlackActionContainedButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
};

export default UploadModal;
