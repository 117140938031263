import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowBack, Call, ChevronRight, Info } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { IChecklist } from "models/checklists";
import { GET_PROJECT_DATES } from "graphql/projects";
import { IArr } from "models/common";
import { RouteNames } from "routes/routeNames";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IHeaderProps {
  checklistDetails: IChecklist | null;
  breadCrumbArr: IArr[];
}

function Header(props: IHeaderProps) {
  const { checklistDetails, breadCrumbArr } = props;

  const navigate = useNavigate();
  const params = useParams();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [deliveryDetailsModalOpen, setDeliveryDetailsModalOpen] =
    useState(false);
  const [postProdDate, setPostProdDate] = useState("");
  const [completionDate, setCompletionDate] = useState("");

  const {} = useQuery(GET_PROJECT_DATES, {
    variables: { id: params?.project_id },
    skip:
      !params?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        deliveryViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
      ),
    onCompleted: (data) => {
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        const datesJSON = rawProject?.projectDates;
        const parsedDatesJSON = Boolean(datesJSON)
          ? JSON.parse(datesJSON)
          : null;
        if (Boolean(parsedDatesJSON)) {
          if (parsedDatesJSON?.startPostProduction) {
            setPostProdDate(parsedDatesJSON?.startPostProduction);
          }
          if (parsedDatesJSON?.completionDate) {
            setCompletionDate(parsedDatesJSON?.completionDate);
          }
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sx={{ flexGrow: 1 }}>
        <Box sx={[styles.flex_Acenter, { mt: -2.5 }]}>
          <Box>
            <IconButton
              size="small"
              onClick={() => {
                if (breadCrumbArr.length > 0) {
                  let tempArr: IArr[] | string[] = [...breadCrumbArr];
                  tempArr.pop();
                  tempArr = tempArr.map((x) => x.id);
                  navigate(
                    `${RouteNames.PROJECTS}/${
                      params?.project_id
                    }/delivery-checklist/${params?.checklist_id}${
                      tempArr.length > 0 ? "/" : ""
                    }${tempArr.join("/")}`
                  );
                } else {
                  navigate(
                    `${RouteNames.PROJECTS}/${params?.project_id}/delivery-checklist`
                  );
                }
              }}
            >
              <ArrowBack fontSize="small" sx={{ color: "#00000055" }} />
            </IconButton>
          </Box>
          <Box sx={styles.flex_Acenter}>
            <Box sx={[styles.flex_Acenter, { ml: 1 }]}>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  fontWeight: 550,
                }}
              >
                {checklistDetails?.name}
              </Typography>
              <Box
                sx={{
                  ml: 1,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => setDeliveryDetailsModalOpen(true)}
              >
                <Info fontSize="small" sx={{ color: "#00000070" }} />
              </Box>
              {breadCrumbArr.length > 0 && (
                <ChevronRight sx={{ color: "#00000055" }} />
              )}
            </Box>
            {breadCrumbArr.map((crumb, index) => (
              <Box key={crumb.id} sx={[styles.flex_Acenter, { ml: 1 }]}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: colors.primaryBlack,
                    fontWeight: 550,
                  }}
                >
                  {crumb?.name}
                </Typography>
                {index < breadCrumbArr.length - 1 && (
                  <ChevronRight sx={{ color: "#00000055" }} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      {Boolean(checklistDetails?.scheduleCopy) && (
        <Grid item>
          <Box
            component="div"
            sx={{ cursor: "pointer", mt: -2.5 }}
            onClick={() => {
              if (Boolean(checklistDetails?.scheduleCopy)) {
                var hiddenElement = document.createElement("a");
                hiddenElement.href = checklistDetails?.scheduleCopy;
                hiddenElement.target = "_blank";
                hiddenElement.download = `${checklistDetails?.name}.pdf`;
                hiddenElement.click();
              }
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
                color: `${colors.primaryBlack}70`,
              }}
            >
              Download Delivery Schedule
            </Typography>
          </Box>
        </Grid>
      )}
      {deliveryDetailsModalOpen && (
        <Dialog
          open={deliveryDetailsModalOpen}
          onClose={() => setDeliveryDetailsModalOpen(false)}
          PaperProps={{ sx: { borderRadius: "10px", width: "390px" } }}
        >
          <Grid container alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={[styles.flex_Acenter, { p: 2.5, pt: 3.25 }]}>
                <img
                  src={images.FILM_REEL_DARK}
                  alt="reel"
                  height={20}
                  style={{ marginTop: -5 }}
                />
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Project Delivery Date:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ p: 2.5, pt: 3.25 }}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {Boolean(checklistDetails?.deliveryDate)
                    ? moment(checklistDetails?.deliveryDate).format(
                        "DD MMMM YYYY"
                      )
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ backgroundColor: "#e6e6e6" }} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={[styles.flex_Acenter, { p: 2.5 }]}>
                <img
                  src={images.CALENDAR}
                  alt="reel"
                  height={20}
                  style={{ marginTop: -5 }}
                />
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Post-production Dates:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ p: 2.5 }}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {Boolean(postProdDate)
                    ? moment(postProdDate).format("DD MMMM YYYY")
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {Boolean(completionDate)
                    ? moment(completionDate).format("DD MMMM YYYY")
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ backgroundColor: "#e6e6e6" }} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={[styles.flex_Acenter, { p: 2.5 }]}>
                <Call sx={{ color: "#d6d6d6" }} />
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  For Delivery Enquires:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ p: 2.5 }}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {checklistDetails?.clientContactName || ""}
                </Typography>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {checklistDetails?.clientContactNumber
                    ? `+61 ${checklistDetails?.clientContactNumber}`
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Grid>
  );
}

export default Header;
