import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  menuItemClasses,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  Call,
  ChevronRight,
  FiberManualRecord,
  Info,
  Share,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";

import { IChecklist } from "models/checklists";
import { IArr } from "models/common";
import { RouteNames } from "routes/routeNames";
import {
  BlackActionContainedButton,
  NewGreenPrimaryContainedButton,
} from "common/Buttons";
import { EXPORT_DELIVERY_REPORT } from "graphql/reports";
import { EXPORT_CHECKLIST_ZIP } from "graphql/checklist";
import { GET_PROJECT_DATES } from "graphql/projects";
import { useStore } from "utils/store";
import { images } from "assets/images";
import { colors } from "theme/colors";

import ExportFeedbackModal from "./ExportFeedbackModal";
import { ProjectDetailsStyles as styles } from "../styles";

interface IHeaderXsProps {
  checklistDetails: IChecklist | null;
  barGraphData: any;
  breadCrumbArr: IArr[];
}

function HeaderXs(props: IHeaderXsProps) {
  const { checklistDetails, barGraphData, breadCrumbArr } = props;

  const navigate = useNavigate();
  const params = useParams();
  const { setLoading } = useStore();

  const [deliveryDetailsModalOpen, setDeliveryDetailsModalOpen] =
    useState(false);
  const [openReportMenu, setOpenReportMenu] = useState(false);
  const [reportMEnuAnchor, setReportMEnuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [exportFeedbackModal, setExportFeedbackModal] = useState(false);
  const [exportMessage, setExportMessage] = useState("");
  const [postProdDate, setPostProdDate] = useState("");
  const [completionDate, setCompletionDate] = useState("");

  const {} = useQuery(GET_PROJECT_DATES, {
    variables: { id: params?.project_id },
    skip: !params?.project_id,
    onCompleted: (data) => {
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        const datesJSON = rawProject?.projectDates;
        const parsedDatesJSON = Boolean(datesJSON)
          ? JSON.parse(datesJSON)
          : null;
        if (Boolean(parsedDatesJSON)) {
          if (parsedDatesJSON?.startPostProduction) {
            setPostProdDate(parsedDatesJSON?.startPostProduction);
          }
          if (parsedDatesJSON?.completionDate) {
            setCompletionDate(parsedDatesJSON?.completionDate);
          }
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getDeliveryReport, { loading: generatingReport }] = useLazyQuery(
    EXPORT_DELIVERY_REPORT,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [exportDeliveryZip, { loading: exporting }] = useLazyQuery(
    EXPORT_CHECKLIST_ZIP,
    {
      onCompleted: (data) => {
        const { getChecklistZip } = data;
        const { status, message } = getChecklistZip;
        if (status) {
          setExportFeedbackModal(true);
          setExportMessage(message);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleReportMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setReportMEnuAnchor(e.currentTarget);
    setOpenReportMenu(true);
  };

  const handleReportMenuClose = () => {
    setReportMEnuAnchor(null);
    setOpenReportMenu(false);
  };

  useEffect(() => {
    setLoading(exporting || generatingReport);
  }, [exporting, generatingReport, setLoading]);

  return (
    <Box sx={{ mt: -2.25, width: "100%" }}>
      <Box sx={{ mb: 1, display: "flex", justifyContent: "flex-end" }}>
        {Boolean(checklistDetails?.scheduleCopy) && (
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (Boolean(checklistDetails?.scheduleCopy)) {
                var hiddenElement = document.createElement("a");
                hiddenElement.href = checklistDetails?.scheduleCopy;
                hiddenElement.target = "_blank";
                hiddenElement.download = `${checklistDetails?.name}.pdf`;
                hiddenElement.click();
              }
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
                color: `${colors.primaryBlack}70`,
              }}
            >
              Download Delivery Schedule
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={styles.flex_Acenter}>
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              if (breadCrumbArr.length > 0) {
                let tempArr: IArr[] | string[] = [...breadCrumbArr];
                tempArr.pop();
                tempArr = tempArr.map((x) => x.id);
                navigate(
                  `${RouteNames.PROJECTS}/${
                    params?.project_id
                  }/delivery-checklist/${params?.checklist_id}${
                    tempArr.length > 0 ? "/" : ""
                  }${tempArr.join("/")}`
                );
              } else {
                navigate(
                  `${RouteNames.PROJECTS}/${params?.project_id}/delivery-checklist`
                );
              }
            }}
          >
            <ArrowBack fontSize="small" sx={{ color: "#00000055" }} />
          </IconButton>
        </Box>
        <Box sx={styles.flex_Acenter}>
          <Box sx={[styles.flex_Acenter, { ml: 1 }]}>
            <Typography
              sx={{
                fontSize: "13px",
                color: colors.primaryBlack,
                fontWeight: 550,
              }}
            >
              {checklistDetails?.name}
            </Typography>
            <Box
              sx={{
                ml: 1,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => setDeliveryDetailsModalOpen(true)}
            >
              <Info fontSize="small" sx={{ color: "#00000070" }} />
            </Box>
            {breadCrumbArr.length > 0 && (
              <ChevronRight sx={{ color: "#00000055" }} />
            )}
          </Box>
          {breadCrumbArr.map((crumb, index) => (
            <Box key={crumb.id} sx={[styles.flex_Acenter, { ml: 1 }]}>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  fontWeight: 550,
                }}
              >
                {crumb?.name}
              </Typography>
              {index < breadCrumbArr.length - 1 && (
                <ChevronRight sx={{ color: "#00000055" }} />
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Box
          sx={{
            py: 0.5,
            height: 60,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={[styles.flex_Acenter, { width: "100%" }]}>
            <Typography
              sx={{
                color: colors.primaryBlack,
                fontSize: "11px",
                fontWeight: 600,
                flexGrow: 1,
              }}
            >
              Project Delivery Status:{" "}
              <span style={{ color: `${colors.primaryBlack}80` }}>
                {`${
                  Boolean(barGraphData) && barGraphData?.total > 0
                    ? Math.round(
                        (barGraphData?.completed / barGraphData?.total) * 100
                      )
                    : "0"
                }% Completed`}
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                borderRadius: "20px",
                width: "100%",
                display: "flex",
                mb: 0.25,
                backgroundColor: "#e6e6e6",
                ...(Boolean(barGraphData) &&
                  barGraphData?.total === 0 && { p: 0.4 }),
              }}
            >
              {/* PENDING */}
              <Box
                sx={{
                  width:
                    Boolean(barGraphData) && barGraphData?.total > 0
                      ? `${
                          (barGraphData?.pending / barGraphData?.total) * 100
                        }%`
                      : "",
                  backgroundColor: "#FFA027",
                  p:
                    Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending / barGraphData?.total > 0
                      ? 0.4
                      : 0,
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending === barGraphData?.total && {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }),
                }}
              />
              {/* COMPLETED */}
              <Box
                sx={{
                  width:
                    Boolean(barGraphData) && barGraphData?.total > 0
                      ? `${
                          (barGraphData?.completed / barGraphData?.total) * 100
                        }%`
                      : "",
                  backgroundColor: "#073577",
                  p:
                    Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.completed / barGraphData?.total > 0
                      ? 0.4
                      : 0,
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending / barGraphData?.total === 0 && {
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }),
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending + barGraphData?.completed ===
                      barGraphData?.total && {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }),
                }}
              />
              {/* REJECTED */}
              <Box
                sx={{
                  width:
                    Boolean(barGraphData) && barGraphData?.total > 0
                      ? `${
                          (barGraphData?.rejected / barGraphData?.total) * 100
                        }%`
                      : "",
                  backgroundColor: "#E3323B",
                  p:
                    Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.rejected / barGraphData?.total > 0
                      ? 0.4
                      : 0,
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending / barGraphData?.total +
                      barGraphData?.completed / barGraphData?.total ===
                      0 && {
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }),
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending +
                      barGraphData?.completed +
                      barGraphData?.rejected ===
                      barGraphData?.total && {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }),
                }}
              />
              {/* ACCEPTED */}
              <Box
                sx={{
                  width:
                    Boolean(barGraphData) && barGraphData?.total > 0
                      ? `${
                          (barGraphData?.accepted / barGraphData?.total) * 100
                        }%`
                      : "",
                  backgroundColor: "#409091",
                  p:
                    Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.accepted / barGraphData?.total > 0
                      ? 0.4
                      : 0,
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending / barGraphData?.total +
                      barGraphData?.completed / barGraphData?.total +
                      barGraphData?.rejected / barGraphData?.total ===
                      0 && {
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }),
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending +
                      barGraphData?.completed +
                      barGraphData?.rejected +
                      barGraphData?.accepted ===
                      barGraphData?.total && {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }),
                }}
              />
              {/* NOT REQUIRED */}
              <Box
                sx={{
                  width:
                    Boolean(barGraphData) && barGraphData?.total > 0
                      ? `${
                          (barGraphData?.not_required / barGraphData?.total) *
                          100
                        }%`
                      : "",
                  backgroundColor: "#910C00",
                  p:
                    Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.not_required / barGraphData?.total > 0
                      ? 0.4
                      : 0,
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending / barGraphData?.total +
                      barGraphData?.completed / barGraphData?.total +
                      barGraphData?.rejected / barGraphData?.total +
                      barGraphData?.accepted / barGraphData?.total ===
                      0 && {
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }),
                  ...(Boolean(barGraphData) &&
                    barGraphData?.total > 0 &&
                    barGraphData?.pending +
                      barGraphData?.completed +
                      barGraphData?.rejected +
                      barGraphData?.accepted +
                      barGraphData?.not_required ===
                      barGraphData?.total && {
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }),
                }}
              />
            </Box>
            <Box sx={[styles.flex_JCsb_Acenter, { width: "100%" }]}>
              <Box sx={styles.flex_Acenter}>
                <FiberManualRecord sx={{ fontSize: 15, color: "#FFA027" }} />
                <Typography sx={{ fontSize: "10px" }}>Pending</Typography>
              </Box>
              <Box sx={styles.flex_Acenter}>
                <FiberManualRecord sx={{ fontSize: 15, color: "#073577" }} />
                <Typography sx={{ fontSize: "10px" }}>Completed</Typography>
              </Box>
              <Box sx={styles.flex_Acenter}>
                <FiberManualRecord sx={{ fontSize: 15, color: "#E3323B" }} />
                <Typography sx={{ fontSize: "10px" }}>Rejected</Typography>
              </Box>
              <Box sx={styles.flex_Acenter}>
                <FiberManualRecord sx={{ fontSize: 15, color: "#409091" }} />
                <Typography sx={{ fontSize: "10px" }}>Accepted</Typography>
              </Box>
              <Box sx={styles.flex_Acenter}>
                <FiberManualRecord sx={{ fontSize: 15, color: "#910C00" }} />
                <Typography sx={{ fontSize: "10px" }}>Not Required</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <Box>
          <BlackActionContainedButton
            disableElevation
            disableRipple
            sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            startIcon={<Share fontSize="small" color="secondary" />}
            onClick={() => {
              exportDeliveryZip({
                variables: {
                  checklistId: params?.checklist_id,
                  projectId: params?.project_id,
                },
              });
            }}
          >
            Export
          </BlackActionContainedButton>
        </Box>
        <Box sx={{ ml: 1 }}>
          <NewGreenPrimaryContainedButton
            sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            disableElevation
            disableRipple
            startIcon={
              <img
                src={images.DOWNLOAD_ICON}
                alt="download"
                style={{ height: 15 }}
              />
            }
            onClick={handleReportMenuOpen}
          >
            Generate Report
          </NewGreenPrimaryContainedButton>
          <Menu
            open={openReportMenu}
            onClose={handleReportMenuClose}
            anchorEl={reportMEnuAnchor}
            elevation={0}
            sx={{
              "& .MuiMenu-paper": {
                mt: 1,
                border: "1px #00000030 solid",
                borderRadius: "15px",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                getDeliveryReport({
                  variables: {
                    project_id: params?.project_id,
                    checklist_id: params?.checklist_id,
                    report_type: "internal",
                  },
                  onCompleted: (data) => {
                    setLoading(false);
                    const { generateDeliveryReport } = data;
                    if (generateDeliveryReport) {
                      const { report } = generateDeliveryReport;
                      if (Boolean(report)) {
                        var hiddenElement = document.createElement("a");
                        hiddenElement.href =
                          "data:application/pdf;base64," + encodeURI(report);
                        hiddenElement.target = "_blank";
                        hiddenElement.download = `Internal Delivery Report.pdf`;
                        hiddenElement.click();
                      } else {
                        toast.error("Something went wrong", { delay: 10 });
                      }
                    }
                  },
                });
                handleReportMenuClose();
              }}
              sx={{
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: "#41B19931",
                  "&:hover": { backgroundColor: "#41B19995" },
                },
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
            >
              <Typography
                sx={{ fontSize: "14px", color: colors.secondaryBlack }}
              >
                Internal Delivery Report
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                getDeliveryReport({
                  variables: {
                    project_id: params?.project_id,
                    checklist_id: params?.checklist_id,
                    report_type: "external",
                  },
                  onCompleted: (data) => {
                    setLoading(false);
                    const { generateDeliveryReport } = data;
                    if (generateDeliveryReport) {
                      const { report } = generateDeliveryReport;
                      if (Boolean(report)) {
                        var hiddenElement = document.createElement("a");
                        hiddenElement.href =
                          "data:application/pdf;base64," + encodeURI(report);
                        hiddenElement.target = "_blank";
                        hiddenElement.download = `External Delivery Report.pdf`;
                        hiddenElement.click();
                      } else {
                        toast.error("Something went wrong", { delay: 10 });
                      }
                    }
                  },
                });
                handleReportMenuClose();
              }}
              sx={{
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: "#41B19931",
                  "&:hover": { backgroundColor: "#41B19995" },
                },
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
            >
              <Typography
                sx={{ fontSize: "14px", color: colors.secondaryBlack }}
              >
                External Delivery Report
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {exportFeedbackModal && (
        <ExportFeedbackModal
          open={exportFeedbackModal}
          setOpen={setExportFeedbackModal}
          message={exportMessage}
          setMessage={setExportMessage}
        />
      )}
      {deliveryDetailsModalOpen && (
        <Dialog
          open={deliveryDetailsModalOpen}
          onClose={() => setDeliveryDetailsModalOpen(false)}
          PaperProps={{ sx: { borderRadius: "10px", width: "390px" } }}
        >
          <Grid container alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={7}>
              <Box sx={[styles.flex_Acenter, { p: 2.5, pt: 3.25 }]}>
                <img
                  src={images.FILM_REEL_DARK}
                  alt="reel"
                  height={20}
                  style={{ marginTop: -5 }}
                />
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Project Delivery Date:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={5}>
              <Box sx={{ p: 2.5, pt: 3.25 }}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {Boolean(checklistDetails?.deliveryDate)
                    ? moment(checklistDetails?.deliveryDate).format(
                        "DD MMMM YYYY"
                      )
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ backgroundColor: "#e6e6e6" }} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={7}>
              <Box sx={[styles.flex_Acenter, { p: 2.5 }]}>
                <img
                  src={images.CALENDAR}
                  alt="reel"
                  height={20}
                  style={{ marginTop: -5 }}
                />
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Post-production Dates:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={5}>
              <Box sx={{ p: 2.5 }}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {Boolean(postProdDate)
                    ? moment(postProdDate).format("DD MMMM YYYY")
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {Boolean(completionDate)
                    ? moment(completionDate).format("DD MMMM YYYY")
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ backgroundColor: "#e6e6e6" }} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={7}>
              <Box sx={[styles.flex_Acenter, { p: 2.5 }]}>
                <Call sx={{ color: "#d6d6d6" }} />
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  For Delivery Enquires:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={5}>
              <Box sx={{ p: 2.5 }}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {checklistDetails?.clientContactName || ""}
                </Typography>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                  }}
                >
                  {checklistDetails?.clientContactNumber
                    ? `+61 ${checklistDetails?.clientContactNumber}`
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Box>
  );
}

export default HeaderXs;
