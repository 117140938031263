import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";

import { IUpdateContactFieldsNew } from "models/contacts";
import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface ISuperannuationFormProps {
  initialValues: IUpdateContactFieldsNew;
  refetch: any;
  updateContactApi: any;
  profilePicture: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function SuperannuationForm(props: ISuperannuationFormProps) {
  const { initialValues, setActiveSection } = props;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Superannuation Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Do you have a Superannuation Account?
              </Typography>
              <RadioGroup
                value={
                  values.personalBankAccountDetails.haveSuperannuationAccount
                }
              >
                <FormControlLabel
                  value="Yes, I know my superannuation fund details"
                  control={
                    <Radio
                      disabled
                      size="small"
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: "#6f6f6f" }} />
                      }
                      icon={<RadioButtonUnchecked sx={{ color: "#6f6f6f" }} />}
                    />
                  }
                  label={
                    <Typography
                      sx={[
                        styles.field_label,
                        { fontWeight: 400, color: "#00000080" },
                      ]}
                    >
                      Yes, I know my superannuation fund details
                    </Typography>
                  }
                  sx={{ mt: -0.5 }}
                />
                <FormControlLabel
                  value="I have a self managed super fund"
                  control={
                    <Radio
                      disabled
                      size="small"
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: "#6f6f6f" }} />
                      }
                      icon={<RadioButtonUnchecked sx={{ color: "#6f6f6f" }} />}
                    />
                  }
                  label={
                    <Typography
                      sx={[
                        styles.field_label,
                        { fontWeight: 400, color: "#00000080" },
                      ]}
                    >
                      I have a self managed super fund
                    </Typography>
                  }
                  sx={{ mt: -1 }}
                />
                <FormControlLabel
                  value="No, use my employer's default super fund"
                  control={
                    <Radio
                      disabled
                      size="small"
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: "#6f6f6f" }} />
                      }
                      icon={<RadioButtonUnchecked sx={{ color: "#6f6f6f" }} />}
                    />
                  }
                  label={
                    <Typography
                      sx={[
                        styles.field_label,
                        { fontWeight: 400, color: "#00000080" },
                      ]}
                    >
                      No, use my employer's default super fund
                    </Typography>
                  }
                  sx={{ mt: -1 }}
                />
              </RadioGroup>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Enter your super fund product name or USI
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.superannuationFundName}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Member Number
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.memberNumber}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <OutlinedBtn
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#000",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(2)}
                >
                  Back
                </OutlinedBtn>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(4)}
                >
                  Next
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default SuperannuationForm;
