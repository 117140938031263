import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import queryString from "query-string";

import { NewGreenPrimaryContainedButton, OutlinedBtn } from "common/Buttons";
import { StorageConstants } from "utils/storage";
import { RouteNames } from "routes/routeNames";
import { images } from "assets/images";

import { TwoFAStyles as styles } from "./styles";

const TwoFAForm = () => {
  const navigate = useNavigate();

  const device = useMemo(
    () => sessionStorage.getItem(StorageConstants.DEVICE),
    []
  );

  const qrCodeUrl: any = useMemo(
    () =>
      Boolean(device) ? queryString.parseUrl(device as string) : undefined,
    [device]
  );
  const code = useMemo(
    () => (qrCodeUrl !== undefined ? qrCodeUrl.query?.secret : ""),
    [qrCodeUrl]
  );

  return (
    <Box
      sx={[
        styles.content_center,
        {
          width: { xs: "100%", sm: "75%", md: "48%" },
          py: { xs: 4, md: 0 },
          overflowY: { xs: "auto", sm: "hidden" },
          px: { xs: 4, sm: 8, md: 0 },
          backgroundColor: { xs: "none", sm: "#fff", md: "none" },
          borderRadius: { xs: 0, sm: "15px", md: 0 },
          mx: { xs: 0, sm: 4, md: 0 },
        },
      ]}
    >
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Box sx={[styles.width, styles.content_center]}>
            <img src={images.LOGO} alt="logo" />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Box sx={[styles.width, styles.content_center]}>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 600,
                color: "#00000080",
                textAlign: "center",
              }}
            >
              Enable Two-Factor Authentication (2FA)
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Box sx={[styles.width, styles.content_center]}>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#00000080",
                textAlign: "center",
              }}
            >
              Enhance your account security by setting up 2FA using your
              preferred app, like{" "}
              <span style={{ fontWeight: 600 }}>Google Authenticator</span> or{" "}
              <span style={{ fontWeight: 600 }}>Microsoft Authenticator</span>.
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Box sx={[styles.width, styles.content_center]}>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#00000080",
                textAlign: "center",
              }}
            >
              <span style={{ fontWeight: 600 }}>Scan QR Code:</span> Use your
              authentication app to scan the QR code below.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={[styles.content_center, { mt: -2 }]}
        >
          <Box sx={[styles.width, styles.content_center]}>
            <QRCodeSVG value={device as string} size={249} />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Box
            sx={[
              styles.width,
              styles.content_center,
              { flexDirection: "column" },
            ]}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#00000080",
                textAlign: "center",
              }}
            >
              <span style={{ fontWeight: 600 }}>Manual Setup:</span> If scanning
              isn't possible, enter the following code:
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#00000080",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              {code}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Box
            sx={[
              styles.width,
              {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
            ]}
          >
            <OutlinedBtn
              size="small"
              disableElevation
              disableRipple
              sx={{
                width: 120,
                borderRadius: "20px",
                color: "#ffffff",
                border: "none",
                backgroundColor: "#00000040",
                ":hover": { backgroundColor: "#00000060" },
              }}
              onClick={() => navigate("/")}
            >
              Back
            </OutlinedBtn>
            <NewGreenPrimaryContainedButton
              size="small"
              variant="contained"
              disableElevation
              disableRipple
              sx={{ width: 120, borderRadius: "20px" }}
              onClick={() => navigate(RouteNames.TWO_FA_CODE)}
            >
              Next
            </NewGreenPrimaryContainedButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TwoFAForm;
