import { gql } from "@apollo/client";

export const GET_CHECKLIST_TEMPLATE_ARR = gql`
  query checklistTemplates {
    checklistTemplates {
      status
      templates {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CHECKLISTS = gql`
  query getAllChecklist(
    $filter: ChecklistFilterInput
    $project_id: String
    $limit: Int
    $page: Int
  ) {
    getAllChecklist(
      filter: $filter
      project_id: $project_id
      limit: $limit
      page: $page
    ) {
      status
      message
      count
      checklist {
        id
        name
        description
        photo
      }
    }
  }
`;

export const GET_CHECKLIST_DETAILS = gql`
  query getChecklist($id: String!, $project_id: String!) {
    getChecklist(id: $id, project_id: $project_id) {
      status
      message
      checklist {
        id
        name
        description
        photo
        deliveryDate
        scheduleVersion
        clientContactName
        clientContactNumber
        scheduleCopy
        scheduleName
        status
        createdDate
        modifiedDate
        deliveryManager {
          id
          fullName
        }
        template {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_CHECKLIST = gql`
  mutation createChecklistUpload(
    $payload: CreateChecklistInput!
    $file: Upload
    $schedule: Upload
  ) {
    createChecklist(payload: $payload, file: $file, schedule: $schedule) {
      status
      message
    }
  }
`;

export const UPDATE_CHECKLIST = gql`
  mutation updateChecklist(
    $payload: UpdateChecklistInput!
    $file: Upload
    $schedule: Upload
  ) {
    updateChecklist(payload: $payload, file: $file, schedule: $schedule) {
      status
      message
    }
  }
`;

export const DELETE_CHECKLIST = gql`
  mutation deleteChecklist($id: String!, $project_id: String!) {
    deleteChecklist(id: $id, project_id: $project_id) {
      status
      message
    }
  }
`;

export const GET_CHECKLIST_NAME = gql`
  query getChecklist($id: String!, $project_id: String!) {
    getChecklist(id: $id, project_id: $project_id) {
      status
      message
      checklist {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CHECKLIST_ITEMS = gql`
  query getAllChecklistItem(
    $filter: ChecklistItemFilterInput
    $project_id: String
    $checklist_id: String
    $limit: Int
    $page: Int
  ) {
    getAllChecklistItems(
      filter: $filter
      project_id: $project_id
      checklist_id: $checklist_id
      limit: $limit
      page: $page
    ) {
      status
      message
      count
      checklistItem {
        id
        name
        description
        levelNo
        note
        itemStatus
        status
        createdDate
        modifiedDate
        completed
        pending
        rejected
        isFolder
        documents {
          id
          type
          name
          isUpload
          itemType
          documentId
        }
        weekDue
        assignedTo {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_CHECKLIST_ITEM_DETAILS = gql`
  query getChecklistItem(
    $id: String!
    $project_id: String!
    $checklist_id: String
  ) {
    getChecklistItem(
      id: $id
      project_id: $project_id
      checklist_id: $checklist_id
    ) {
      status
      message
      checklistItem {
        id
        name
        description
        levelNo
        note
        itemStatus
        status
        createdDate
        modifiedDate
        completed
        pending
        isFolder
        documents {
          id
          type
          name
          isUpload
          itemType
          documentId
        }
      }
    }
  }
`;

export const GET_CHECKLIST_ITEM_WEEK_ASSIGNED_TO = gql`
  query getChecklistItem(
    $id: String!
    $project_id: String!
    $checklist_id: String
  ) {
    getChecklistItem(
      id: $id
      project_id: $project_id
      checklist_id: $checklist_id
    ) {
      status
      message
      checklistItem {
        id
        weekDue
        assignedTo {
          id
        }
      }
    }
  }
`;

export const CREATE_CHECKLIST_ITEM = gql`
  mutation createChecklistItem($payload: CreateChecklistItemInput!) {
    createChecklistItem(payload: $payload) {
      status
      message
    }
  }
`;

export const UPDATE_CHECKLIST_ITEM = gql`
  mutation updateChecklistItem($payload: UpdateChecklistItemInput!) {
    updateChecklistItem(payload: $payload) {
      status
      message
    }
  }
`;

export const DELETE_CHECKLIST_ITEM = gql`
  mutation deleteChecklistItem(
    $id: String!
    $project_id: String!
    $checklist_id: String
  ) {
    deleteChecklistItem(
      id: $id
      project_id: $project_id
      checklist_id: $checklist_id
    ) {
      status
      message
    }
  }
`;

export const GET_CHECKLIST_ITEM_NAME = gql`
  query getChecklistItem(
    $id: String!
    $project_id: String!
    $checklist_id: String
  ) {
    getChecklistItem(
      id: $id
      project_id: $project_id
      checklist_id: $checklist_id
    ) {
      status
      message
      checklistItem {
        id
        name
      }
    }
  }
`;

export const UPDATE_CHECKLIST_ITEM_STATUS = gql`
  mutation updateChecklistItemStatus(
    $payload: UpdateChecklistItemStatusInput!
  ) {
    updateChecklistItemStatus(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_ALL_CHECKLIST_ARR = gql`
  query getAllChecklist(
    $filter: ChecklistFilterInput
    $project_id: String
    $limit: Int
    $page: Int
  ) {
    getAllChecklist(
      filter: $filter
      project_id: $project_id
      limit: $limit
      page: $page
    ) {
      status
      message
      checklist {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CHECKLIST_ITEMS_ARR = gql`
  query getAllChecklistItem(
    $filter: ChecklistItemFilterInput
    $project_id: String
    $checklist_id: String
    $limit: Int
    $page: Int
  ) {
    getAllChecklistItems(
      filter: $filter
      project_id: $project_id
      checklist_id: $checklist_id
      limit: $limit
      page: $page
    ) {
      status
      message
      checklistItem {
        id
        name
      }
    }
  }
`;

export const GET_CHECKLIST_REPORT = gql`
  query getAllChecklistReport(
    $filter: ChecklistReportFilterInput
    $project_id: String
    $limit: Int
    $page: Int
  ) {
    getAllChecklistReport(
      filter: $filter
      project_id: $project_id
      limit: $limit
      page: $page
    ) {
      status
      message
      count
      checklist {
        id
        checklistName
        checklistItemName
        checklistItemStatus
        documentName
        documentType
      }
    }
  }
`;
export const EXPORT_CHECKLIST_REPORT = gql`
  query getAllChecklistReportExport(
    $filter: ChecklistReportFilterInput
    $project_id: String
    $limit: Int
    $page: Int
  ) {
    getAllChecklistReportExport(
      filter: $filter
      project_id: $project_id
      limit: $limit
      page: $page
    ) {
      report
    }
  }
`;

export const LINK_DR_TO_CHECKLIST_ITEM = gql`
  mutation linkRegisterToChecklist($payload: LinkChecklistItemRegisterInput!) {
    linkRegisterToChecklist(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_CHECKLIST_GRAPHS = gql`
  query getChecklistItemStatusGraph(
    $id: String!
    $project_id: String!
    $checklist_id: String
  ) {
    getChecklistItemStatusGraph(
      id: $id
      project_id: $project_id
      checklist_id: $checklist_id
    ) {
      status
      message
      graph
    }
  }
`;

export const GET_DUE_WEEK_ARR = gql`
  query getProjectWeeks($id: String!) {
    getProjectWeeks(id: $id) {
      status
      message
      dates {
        id
        date
      }
    }
  }
`;

export const EXPORT_CHECKLIST_ZIP = gql`
  query getChecklistZip($checklistId: String!, $projectId: String!) {
    getChecklistZip(checklistId: $checklistId, projectId: $projectId) {
      status
      message
    }
  }
`;
