import { IAgentFields } from "models/agents";

export const initialValues: IAgentFields = {
  id: "",
  companyName: "",
  spocName: "",
  spocMobile: "",
  spocEmailId: "",
  website: "",
  address: null,
  street: "",
  suburb: "",
  companyCity: "",
  companyState: "",
  companyCountry: "",
  pinCode: "",
  abn: "",
  acn: "",
  registrationNumber: "",
  publicLiabilityPolicy: "",
  provider: "",
  policyNumber: "",
  insurancePeriod: "",
  financialDetails: {
    accountName: "",
    accountNumber: "",
    accountBsb: "",
    accountSwiftCode: "",
    bankName: "",
    branchName: "",
    accountInstructions: "",
    abn: "",
    superannuationFundName: "",
    superannuationAccountNo: "",
    taxFileNo: "",
    memberNumber: "",
    haveSuperannuationAccount: "",
  },
  profile_picture: null,
};

export const setAgentDetails = (agencyDetails: any, setValues: any) => {
  setValues({
    id: agencyDetails?.id || "",
    companyName: agencyDetails?.companyName || "",
    spocName: agencyDetails?.spocName || "",
    spocMobile: agencyDetails?.spocMobile || "",
    spocEmailId: agencyDetails?.spocEmailId || "",
    website: agencyDetails?.website || "",
    address: Boolean(agencyDetails?.address) ? agencyDetails?.address : null,
    companyCountry: agencyDetails?.companyCountry || "",
    companyState: agencyDetails?.companyState || "",
    companyCity: agencyDetails?.companyCity || "",
    street: agencyDetails?.street || "",
    suburb: agencyDetails?.suburb || "",
    pinCode: agencyDetails?.pinCode || "",
    abn: agencyDetails?.abn || "",
    acn: agencyDetails?.acn || "",
    registrationNumber: agencyDetails?.registrationNumber || "",
    publicLiabilityPolicy: agencyDetails?.publicLiabilityPolicy || "",
    provider: agencyDetails?.provider || "",
    policyNumber: agencyDetails?.policyNumber || "",
    insurancePeriod: agencyDetails?.insurancePeriod || "",
    financialDetails: {
      accountName: agencyDetails?.agentFinancialDetails?.accountName || "",
      accountNumber: agencyDetails?.agentFinancialDetails?.accountNumber || "",
      accountBsb: agencyDetails?.agentFinancialDetails?.accountBsb || "",
      accountSwiftCode:
        agencyDetails?.agentFinancialDetails?.accountSwiftCode || "",
      bankName: agencyDetails?.agentFinancialDetails?.bankName || "",
      branchName: agencyDetails?.agentFinancialDetails?.branchName || "",
      accountInstructions:
        agencyDetails?.agentFinancialDetails?.accountInstructions || "",
      abn: agencyDetails?.agentFinancialDetails?.abn || "",
      superannuationFundName:
        agencyDetails?.agentFinancialDetails?.superannuationFundName || "",
      superannuationAccountNo:
        agencyDetails?.agentFinancialDetails?.superannuationAccountNo || "",
      taxFileNo: agencyDetails?.agentFinancialDetails?.taxFileNo || "",
    },
    profile_picture: agencyDetails?.photo || null,
  });
};
