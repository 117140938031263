import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocationOnOutlined,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { BlackActionContainedButton } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { IGeoCoding } from "models/common";
import { IAgentFields } from "models/agents";
import { GET_ADDRESS_AUTOCOMPLETE } from "graphql/meta";
import { useStore } from "utils/store";
import { UPDATE_AGENT } from "graphql/agents";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";

interface ICompanyDetailsFormProps {
  setActiveSection: Dispatch<SetStateAction<number>>;
  profilePicture: any;
  setProfilePicture: any;
  initialValues: IAgentFields;
  refetch: any;
}

function CompanyDetailsForm(props: ICompanyDetailsFormProps) {
  const {
    setActiveSection,
    profilePicture,
    setProfilePicture,
    initialValues,
    refetch,
  } = props;

  const { setLoading } = useStore();

  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
  const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
  const [addressSearchText, setAddressSearchText] = useState("");
  const [addressField, setAddressField] = useState<IGeoCoding | string | null>(
    null
  );

  const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
    onCompleted: (data) => {
      const { autocompleteAddress } = data;
      setAddressArr(autocompleteAddress);
      if (
        autocompleteAddress.length > 0 &&
        Boolean(addressField) &&
        typeof addressField === "string"
      ) {
        setAddressField(autocompleteAddress[0]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUpdateAgentApi, { loading: updating }] = useMutation(
    UPDATE_AGENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateAgentDetails } = data;
        const { status, message } = updateAgentDetails;
        if (status) {
          setActiveSection(1);
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUpdateAgent = (values: IAgentFields) => {
    const {
      id,
      address,
      financialDetails,
      companyCity,
      companyCountry,
      companyState,
      pinCode,
      suburb,
      street,
      abn,
      acn,
      profile_picture,
      ...rest
    } = values;

    fireUpdateAgentApi({
      variables: {
        payload: {
          id,
          ...(typeof address !== "string" &&
            address !== null && {
              address: address?.freeformAddress,
              companyCountry: address?.country || "",
              companyState: address?.countrySubdivision || "",
              companyCity: address?.municipality || "",
              suburb: address?.municipalitySubdivision || "",
              pinCode: address?.postalCode || "",
              street: address?.streetName || "",
            }),
          ...(profilePicture === null && { removeProfilePhoto: true }),
          ...rest,
        },
        ...(Boolean(profilePicture) &&
          typeof profilePicture !== "string" && {
            profile_picture: profilePicture,
          }),
      },
    });
  };

  useEffect(() => {
    setAddressField(initialValues.address);
  }, [initialValues.address]);

  useEffect(() => {
    if (addressSearchText) {
      getGeoCodeAddress({ variables: { filter: addressSearchText } });
    }
  }, [addressSearchText, getGeoCodeAddress]);

  useEffect(() => {
    if (Boolean(addressField) && typeof addressField === "string") {
      setAddressSearchText(addressField);
    }
  }, [addressField]);

  useEffect(() => {
    setLoading(updating);
  }, [setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        companyName: Yup.string().required("Please enter the agency name"),
        spocName: Yup.string().required("Please enter the agent name"),
        spocEmailId: Yup.string()
          .required("Please enter the agent email")
          .email("Please enter a valid email"),
        spocMobile: Yup.string()
          .required("Please enter the agent mobile")
          .matches(
            /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
            "Contact number must be of 10 digits"
          ),
        website: Yup.string()
          .matches(
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
            "Please enter a valid url"
          )
          .nullable(),
      })}
      onSubmit={handleUpdateAgent}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        isValid,
      }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "flex", sm: "none" } }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {profilePicture ? (
                  <Avatar
                    sx={{ height: 55, width: 55, cursor: "pointer" }}
                    src={
                      typeof profilePicture === "string"
                        ? profilePicture
                        : URL.createObjectURL(profilePicture)
                    }
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: 55,
                      width: 55,
                      cursor: "pointer",
                    }}
                  />
                )}
                <Box sx={{ position: "absolute", bottom: -10 }}>
                  <IconButton
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px #e6e6e6 solid",
                      height: 20,
                      width: 20,
                    }}
                    onClick={() => setOpenImageCropUploader(true)}
                  >
                    <ModeEditOutlineOutlined sx={{ fontSize: 12 }} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Company Name <span style={styles.error_text}>*</span>
              </Typography>
              <TextField
                id="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.companyName && (
                <Typography sx={styles.error_text}>
                  {errors?.companyName}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Agent Full Name <span style={styles.error_text}>*</span>
              </Typography>
              <TextField
                id="spocName"
                value={values.spocName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.spocName && (
                <Typography sx={styles.error_text}>
                  {errors?.spocName}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Email <span style={styles.error_text}>*</span>
              </Typography>
              <TextField
                id="spocEmailId"
                value={values.spocEmailId}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.spocEmailId && (
                <Typography sx={styles.error_text}>
                  {errors?.spocEmailId}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Mobile <span style={styles.error_text}>*</span>
              </Typography>
              <PhoneNumberInput
                value={values.spocMobile}
                onChange={(e) => {
                  setFieldValue("spocMobile", e.target.value);
                }}
                onBlur={handleBlur("spocMobile")}
              />
              {touched?.spocMobile && (
                <Typography sx={styles.error_text}>
                  {errors?.spocMobile}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Address</Typography>
              <Autocomplete
                id="address"
                getOptionLabel={(option) => option.freeformAddress}
                value={
                  Boolean(values.address)
                    ? typeof values.address !== "string"
                      ? values.address!
                      : {
                          streetNumber: "",
                          streetName: "",
                          municipalitySubdivision: "",
                          municipality: "",
                          countrySubdivision: "",
                          postalCode: "",
                          country: "",
                          freeformAddress: "",
                        }
                    : undefined
                }
                onChange={(event, newValue) => {
                  setFieldValue("address", newValue);
                }}
                inputValue={addressSearchText}
                onInputChange={(event: any, newInputValue) => {
                  if (Boolean(event?.target)) {
                    setAddressSearchText(newInputValue);
                  }
                }}
                options={addressArr}
                popupIcon={
                  <LocationOnOutlined
                    fontSize="small"
                    sx={{ color: "#00000050" }}
                  />
                }
                sx={[
                  styles.input_field,
                  {
                    "& .MuiAutocomplete-popupIndicator": { transform: "none" },
                  },
                ]}
                size="small"
                disableClearable
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        ...styles.input_field,
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
                noOptionsText={
                  <Typography sx={{ fontSize: "13px", color: "#0f0f0f80" }}>
                    {addressSearchText ? "No match found" : ""}
                  </Typography>
                }
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Website</Typography>
              <TextField
                id="website"
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.website && (
                <Typography sx={styles.error_text}>
                  {errors?.website}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Save and Next
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
          {openImageCropUploader && (
            <ProfilePhotoCircularCropper
              open={openImageCropUploader}
              setOpen={setOpenImageCropUploader}
              width={55}
              imgSrc={profilePicture}
              setImgSrc={(file: any) => setProfilePicture(file)}
            />
          )}
        </Box>
      )}
    </Formik>
  );
}

export default CompanyDetailsForm;
