import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Cancel,
  CheckCircle,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { images } from "assets/images";
import { SET_PASSWORD } from "graphql/users";
import { RouteNames } from "routes/routeNames";
import { useStore } from "utils/store";
import { NewGreenPrimaryContainedButton } from "common/Buttons";

import { SetPasswordStyles as styles } from "./styles";

interface ISetPwdFields {
  password: string;
  confirmPassword: string;
}

function SetPasswordForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useStore();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [fireSetPwdApi, { loading }] = useMutation(SET_PASSWORD, {
    onCompleted: (data) => {
      setLoading(false);
      const { setPassword } = data;
      const { status, error } = setPassword;
      if (status) {
        navigate(RouteNames.SET_PWD_SUCCESS);
      } else {
        toast.error(error, { delay: 10 });
      }
    },
  });

  const handleSetPwd = (values: ISetPwdFields) => {
    const { password } = values;
    const parseQueryString = queryString.parse(location.search);
    const { id, token } = parseQueryString;

    fireSetPwdApi({
      variables: {
        payload: {
          token,
          password,
          id,
        },
      },
    });
  };

  useEffect(() => {
    if (!Boolean(location.search)) {
      navigate("/");
    }
  }, [location.search, navigate]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Box
      sx={[
        styles.content_center,
        {
          width: { xs: "100%", sm: "75%", md: "48%" },
          py: { xs: 4, md: 0 },
          px: { xs: 4, sm: 8, md: 0 },
          backgroundColor: { xs: "#fff", md: "none" },
          borderRadius: { xs: "15px", md: 0 },
          mx: { xs: 4, md: 0 },
        },
      ]}
    >
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("Please enter your new password")
            .matches(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+-]).{8,20}$/,
              "Please check the password criteria"
            ),
          confirmPassword: Yup.string()
            .required("Please confirm your new password")
            .oneOf([Yup.ref("password")], "Passwords must match"),
        })}
        onSubmit={handleSetPwd}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          isValid,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={[styles.textfield, styles.content_center]}>
                  <img src={images.LOGO} alt="logo" />
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Typography sx={styles.title_text}>
                  Create New Password
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={styles.textfield}>
                  <Typography sx={styles.textfield_label}>
                    New Password
                  </Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    sx={styles.textfield}
                    inputProps={{ style: styles.input_field }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ ml: 0 }}>
                          <Box
                            sx={{
                              backgroundColor: "#e6e6e640",
                              borderTopLeftRadius: "6px",
                              borderBottomLeftRadius: "6px",
                              py: "3px",
                              pr: 2,
                              mr: -1.75,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined fontSize="small" />
                              ) : (
                                <VisibilityOutlined fontSize="small" />
                              )}
                            </IconButton>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {touched?.password && errors?.password && (
                    <Typography sx={styles.error_text}>
                      {errors.password}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box sx={styles.textfield}>
                  <Typography sx={styles.textfield_label}>
                    Confirm Password
                  </Typography>
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    sx={styles.textfield}
                    inputProps={{ style: styles.input_field }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ ml: 0 }}>
                          <Box
                            sx={{
                              backgroundColor: "#e6e6e640",
                              borderTopLeftRadius: "6px",
                              borderBottomLeftRadius: "6px",
                              py: "3px",
                              pr: 2,
                              mr: -1.75,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffOutlined fontSize="small" />
                              ) : (
                                <VisibilityOutlined fontSize="small" />
                              )}
                            </IconButton>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {touched?.confirmPassword && errors?.confirmPassword && (
                    <Typography sx={styles.error_text}>
                      {errors.confirmPassword}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box
                  sx={[
                    { display: "flex", alignItems: "center", mt: -2 },
                    styles.textfield,
                  ]}
                >
                  {Boolean(values.password) ? (
                    values.password.match(/^.{8,}/) ? (
                      <CheckCircle fontSize="small" sx={{ color: "#409091" }} />
                    ) : (
                      <Cancel fontSize="small" sx={{ color: "#DF7D8C" }} />
                    )
                  ) : (
                    <CheckCircle fontSize="small" sx={{ color: "#d6d6d6" }} />
                  )}
                  <Typography
                    sx={{ ml: 1, fontSize: "13px", color: "#a6a6a6" }}
                  >
                    Atleast 8 characters
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box
                  sx={[
                    { display: "flex", alignItems: "center", mt: -3 },
                    styles.textfield,
                  ]}
                >
                  {Boolean(values.password) ? (
                    values.password.match(/^(?=.*[0-9])/) ? (
                      <CheckCircle fontSize="small" sx={{ color: "#409091" }} />
                    ) : (
                      <Cancel fontSize="small" sx={{ color: "#DF7D8C" }} />
                    )
                  ) : (
                    <CheckCircle fontSize="small" sx={{ color: "#d6d6d6" }} />
                  )}
                  <Typography
                    sx={{ ml: 1, fontSize: "13px", color: "#a6a6a6" }}
                  >
                    Atleast 1 digit
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box
                  sx={[
                    { display: "flex", alignItems: "center", mt: -3 },
                    styles.textfield,
                  ]}
                >
                  {Boolean(values.password) ? (
                    values.password.match(/^(?=.*?[A-Z][a-z])/) ? (
                      <CheckCircle fontSize="small" sx={{ color: "#409091" }} />
                    ) : (
                      <Cancel fontSize="small" sx={{ color: "#DF7D8C" }} />
                    )
                  ) : (
                    <CheckCircle fontSize="small" sx={{ color: "#d6d6d6" }} />
                  )}
                  <Typography
                    sx={{ ml: 1, fontSize: "13px", color: "#a6a6a6" }}
                  >
                    Both upper and lower case characters
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={styles.content_center}
              >
                <Box
                  sx={[
                    { display: "flex", alignItems: "center", mt: -3 },
                    styles.textfield,
                  ]}
                >
                  {Boolean(values.password) ? (
                    values.password.match(/^(?=.*?[!@#$%^&*()_+-])/) ? (
                      <CheckCircle fontSize="small" sx={{ color: "#409091" }} />
                    ) : (
                      <Cancel fontSize="small" sx={{ color: "#DF7D8C" }} />
                    )
                  ) : (
                    <CheckCircle fontSize="small" sx={{ color: "#d6d6d6" }} />
                  )}
                  <Typography
                    sx={{ ml: 1, fontSize: "13px", color: "#a6a6a6" }}
                  >
                    Atleast 1 special character
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={[styles.content_center, { flexDirection: "column" }]}
              >
                <NewGreenPrimaryContainedButton
                  variant="contained"
                  size="small"
                  sx={styles.setPwd_btn}
                  type="submit"
                  disabled={!isValid}
                  disableElevation
                  disableRipple
                >
                  Reset Password
                </NewGreenPrimaryContainedButton>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    textAlign="center"
                    sx={{ fontSize: "12px", color: "#6f6f6f" }}
                  >
                    Need help? <span style={{ fontWeight: 600 }}>Email us</span>{" "}
                    at info@finka.me
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default SetPasswordForm;
