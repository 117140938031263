import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import queryString from "query-string";
import { useMutation, useQuery } from "@apollo/client";

import { images } from "assets/images";
import { StorageConstants } from "utils/storage";
import { useStore } from "utils/store";
import { IUpdateContactFieldsNew } from "models/contacts";
import {
  GET_CONTACT_BASIC_DETAILS,
  UPDATE_CONTACT_BASIC_DETAILS,
} from "graphql/contacts";

import StepperComponent from "./StepperComponent";
import { setContactDetails, initialValues as startValues } from "./utils";

export function ContactDetails() {
  const { setLoading } = useStore();
  const location = useLocation();
  const parseQueryString = queryString.parse(location.search);
  const { id, token, company_id } = parseQueryString;

  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] =
    useState<IUpdateContactFieldsNew>(startValues);

  const { loading, refetch } = useQuery(GET_CONTACT_BASIC_DETAILS, {
    variables: { id: id },
    skip: !id || !Boolean(sessionStorage.getItem(StorageConstants.TOKEN)),
    onCompleted: (data) => {
      const { getContactBasicDetails } = data;
      const { status, contactDetails } = getContactBasicDetails;
      if (status) {
        setContactDetails(contactDetails, setInitialValues);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateContactApi, { loading: updating }] = useMutation(
    UPDATE_CONTACT_BASIC_DETAILS,
    {}
  );

  useEffect(() => {
    setLoading(loading || updating);
  }, [loading, setLoading, updating]);

  useEffect(() => {
    if (Boolean(token)) {
      sessionStorage.setItem(StorageConstants.TOKEN, token as string);
    }
  }, [token]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        p: { xs: 2, sm: 3 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ mb: 2 }}>
          <img src={images.LOGO} alt="logo" height="40px" />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
            }}
          >
            <StepperComponent
              selectedContact={id as string}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              initialValues={initialValues}
              updateContactApi={updateContactApi}
              refetch={refetch}
              company_id={company_id as string}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
