import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  menuItemClasses,
  Typography,
} from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { useStore } from "utils/store";

import { ISPVCompany } from "models/projects";
import { GET_SPV_COMPANY_DETAILS } from "graphql/projects";
import { useProjectDetailsMenu } from "hooks/useProjectDetailsMenu";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { getPermissions } from "permissions/utils";

import ProductionCompanyDeets from "./ProductionCompanyDeets";
import RightsHoldingCompanyDeets from "./RightsHoldingCompanyDeets";
import TopNavBar from "../TopNavBar";
import ProjectDetailsTabs, { ProjectDetailsMenuItems } from "..";

export function CompanyDeets() {
  const projectId = useParams();
  const { setLoading } = useStore();
  const projectDetailsMenu = useProjectDetailsMenu();
  const projectDetailsTab = useProjectDetailsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [productionCompanyDetails, setProductionCompanyDetails] =
    useState<ISPVCompany>({
      companyName: "",
      registrationType: "",
      registrationNumber: "",
      address: null,
      street: "",
      suburb: "",
      placeOfIncorporation: "",
      town: "",
      state: "",
      postCode: "",
      country: "",
      contactName: "",
      email: "",
      multipleDirectors: false,
      soleDirector: { name: "", email: "" },
      directors: [{ name: "", email: "" }],
      authorizedSignatory: [{ name: "", email: "" }],
    });
  const [rightsHoldingCompanyDetails, setRightsHoldingCompanyDetails] =
    useState<ISPVCompany>({
      companyName: "",
      registrationType: "",
      registrationNumber: "",
      street: "",
      suburb: "",
      placeOfIncorporation: "",
      town: "",
      address: null,
      state: "",
      postCode: "",
      country: "",
      contactName: "",
      email: "",
      multipleDirectors: false,
      soleDirector: { name: "", email: "" },
      directors: [{ name: "", email: "" }],
      authorizedSignatory: [{ name: "", email: "" }],
    });
  const [selectedCompany, setSelectedCompany] = useState(
    "Production Company Details"
  );
  const [openCompanyTypeMenu, setOpenCompanyTypeMenu] = useState(false);
  const [companyTypeMenuAnchor, setCompanyTypeMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleReportTypeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setCompanyTypeMenuAnchor(e.currentTarget);
    setOpenCompanyTypeMenu(true);
  };

  const handleReportTypeMenuClose = () => {
    setCompanyTypeMenuAnchor(null);
    setOpenCompanyTypeMenu(false);
  };

  const { loading, refetch: refetchProductionCompanyDetails } = useQuery(
    GET_SPV_COMPANY_DETAILS,
    {
      variables: {
        project_id: projectId?.project_id,
        company_type: "PRODUCTION",
      },
      skip:
        !projectId?.project_id ||
        !(
          Boolean(ROLE_PERMISSIONS) &&
          projectViewIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]?.PROJECT_VIEW
        ),
      onCompleted: (data) => {
        setLoading(false);
        const { spvCompanyDetails } = data;
        const { status, spvCompanyDetails: rawSpvCompanyDetails } =
          spvCompanyDetails;
        if (status) {
          if (Boolean(rawSpvCompanyDetails)) {
            const { id, __typename, details, country, state, town, ...rest } =
              rawSpvCompanyDetails;
            const detailsJSON = Boolean(details)
              ? JSON.parse(details)
              : {
                  SOLE_DIRECTOR: { name: "", email: "" },
                  DIRECTORS: [{ name: "", email: "" }],
                  AUTORIZED_SIGNATORY: [{ name: "", email: "" }],
                };
            setProductionCompanyDetails({
              ...rest,
              country: country || "",
              state: state || "",
              town: town || "",
              multipleDirectors: Boolean(detailsJSON)
                ? Boolean(detailsJSON?.DIRECTORS)
                : false,
              soleDirector:
                Boolean(detailsJSON) && Boolean(detailsJSON?.SOLE_DIRECTOR)
                  ? detailsJSON.SOLE_DIRECTOR
                  : { name: "", email: "" },
              directors:
                Boolean(detailsJSON) && Boolean(detailsJSON?.DIRECTORS)
                  ? detailsJSON.DIRECTORS.length > 0
                    ? detailsJSON.DIRECTORS
                    : [{ name: "", email: "" }]
                  : [{ name: "", email: "" }],
              authorizedSignatory:
                Boolean(detailsJSON) &&
                Boolean(detailsJSON?.AUTORIZED_SIGNATORY)
                  ? detailsJSON.AUTORIZED_SIGNATORY.length > 0
                    ? detailsJSON.AUTORIZED_SIGNATORY
                    : [{ name: "", email: "" }]
                  : [{ name: "", email: "" }],
            });
          }
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const {
    loading: loadingRightsHolding,
    refetch: refetchRightsHoldingCompanyDetails,
  } = useQuery(GET_SPV_COMPANY_DETAILS, {
    variables: {
      project_id: projectId?.project_id,
      company_type: "RIGHTS_HOLD",
    },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        projectViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]?.PROJECT_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { spvCompanyDetails } = data;
      const { status, spvCompanyDetails: rawSpvCompanyDetails } =
        spvCompanyDetails;
      if (status) {
        if (Boolean(rawSpvCompanyDetails)) {
          const { id, __typename, details, country, state, town, ...rest } =
            rawSpvCompanyDetails;
          const detailsJSON = Boolean(details)
            ? JSON.parse(details)
            : {
                SOLE_DIRECTOR: { name: "", email: "" },
                DIRECTORS: [{ name: "", email: "" }],
                AUTORIZED_SIGNATORY: [{ name: "", email: "" }],
              };
          setRightsHoldingCompanyDetails({
            ...rest,
            country: country || "",
            state: state || "",
            town: town || "",
            multipleDirectors: Boolean(detailsJSON)
              ? Boolean(detailsJSON?.DIRECTORS)
              : false,
            soleDirector:
              Boolean(detailsJSON) && Boolean(detailsJSON?.SOLE_DIRECTOR)
                ? detailsJSON.SOLE_DIRECTOR
                : { name: "", email: "" },
            directors:
              Boolean(detailsJSON) && Boolean(detailsJSON?.DIRECTORS)
                ? detailsJSON.DIRECTORS.length > 0
                  ? detailsJSON.DIRECTORS
                  : [{ name: "", email: "" }]
                : [{ name: "", email: "" }],
            authorizedSignatory:
              Boolean(detailsJSON) && Boolean(detailsJSON?.AUTORIZED_SIGNATORY)
                ? detailsJSON.AUTORIZED_SIGNATORY.length > 0
                  ? detailsJSON.AUTORIZED_SIGNATORY
                  : [{ name: "", email: "" }]
                : [{ name: "", email: "" }],
          });
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading || loadingRightsHolding);
  }, [loading, loadingRightsHolding, setLoading]);

  useEffect(() => {
    projectDetailsMenu.onMenuItemClick(ProjectDetailsMenuItems[2]);
    projectDetailsTab.setSelectedTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <TopNavBar>
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "inline-block" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F3EDE0",
                  borderRadius: "8px",
                  pl: 2,
                  pr: 1,
                  py: 1.25,
                  width: "290px",
                  border: "1px #e6e6e6 solid",
                  cursor: "pointer",
                }}
                onClick={handleReportTypeClick}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    {selectedCompany}
                  </Typography>
                </Box>
                <KeyboardArrowDownOutlined
                  fontSize="small"
                  sx={{ color: "#000" }}
                />
              </Box>
              <Menu
                anchorEl={companyTypeMenuAnchor}
                open={openCompanyTypeMenu}
                onClose={handleReportTypeMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    width: "290px",
                    border: "1px #00000030 solid",
                    borderRadius: "8px",
                  },
                }}
                elevation={0}
              >
                <MenuItem
                  selected={selectedCompany === "Production Company Details"}
                  sx={{
                    borderRadius: "6px",
                    [`&.${menuItemClasses.selected}`]: {
                      backgroundColor: "#41B19931",
                      "&:hover": { backgroundColor: "#41B19995" },
                    },
                    "&:hover": { backgroundColor: "#B6BCC330" },
                  }}
                  onClick={() => {
                    setSelectedCompany("Production Company Details");
                    handleReportTypeMenuClose();
                  }}
                >
                  <Typography sx={{ fontSize: "13px", color: "#00000080" }}>
                    Production Company Details
                  </Typography>
                </MenuItem>
                <MenuItem
                  selected={
                    selectedCompany === "Rights Holding Company Details"
                  }
                  sx={{
                    borderRadius: "6px",
                    [`&.${menuItemClasses.selected}`]: {
                      backgroundColor: "#41B19931",
                      "&:hover": { backgroundColor: "#41B19995" },
                    },
                    "&:hover": { backgroundColor: "#B6BCC330" },
                  }}
                  onClick={() => {
                    setSelectedCompany("Rights Holding Company Details");
                    handleReportTypeMenuClose();
                  }}
                >
                  <Typography sx={{ fontSize: "13px", color: "#00000080" }}>
                    Rights Holding Company Details
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {selectedCompany === "Production Company Details" ? (
              <ProductionCompanyDeets
                initialValues={productionCompanyDetails}
                refetch={refetchProductionCompanyDetails}
              />
            ) : (
              <RightsHoldingCompanyDeets
                initialValues={rightsHoldingCompanyDetails}
                refetch={refetchRightsHoldingCompanyDetails}
              />
            )}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            sx={{
              borderRight: "1px #e6e6e6 solid",
              display: { xs: "none", md: "block" },
            }}
          >
            <ProductionCompanyDeets
              initialValues={productionCompanyDetails}
              refetch={refetchProductionCompanyDetails}
            />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <RightsHoldingCompanyDeets
              initialValues={rightsHoldingCompanyDetails}
              refetch={refetchRightsHoldingCompanyDetails}
            />
          </Grid>
        </Grid>
      </TopNavBar>
    </ProjectDetailsTabs>
  );
}
