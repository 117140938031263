import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { IAgentFields } from "models/agents";
import { useStore } from "utils/store";
import { UPDATE_AGENT } from "graphql/agents";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IBankDetailsProps {
  handleClose: () => void;
  setActiveSection: Dispatch<SetStateAction<number>>;
  profilePicture: any;
  initialValues: IAgentFields;
  refetch: any;
}

function BankDetailsForm(props: IBankDetailsProps) {
  const {
    setActiveSection,
    profilePicture,
    initialValues,
    refetch,
    handleClose,
  } = props;

  const { setLoading } = useStore();

  const [fireUpdateAgentApi, { loading: updating }] = useMutation(
    UPDATE_AGENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateAgentDetails } = data;
        const { status, message } = updateAgentDetails;
        if (status) {
          handleClose();
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUpdateAgent = (values: IAgentFields) => {
    const { id, companyName, financialDetails } = values;

    const {
      haveSuperannuationAccount: agencyHave,
      ...restAgencyFinancialDetails
    } = financialDetails;

    fireUpdateAgentApi({
      variables: {
        payload: {
          id,
          companyName,
          financialDetails: { ...restAgencyFinancialDetails },
          ...(profilePicture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profilePicture) &&
          typeof profilePicture !== "string" && {
            profile_picture: profilePicture,
          }),
      },
    });
  };

  useEffect(() => {
    setLoading(updating);
  }, [setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={handleUpdateAgent}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, handleSubmit, isValid }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Bank Name</Typography>
              <TextField
                id="financialDetails.bankName"
                value={values.financialDetails.bankName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Branch</Typography>
              <TextField
                id="financialDetails.branchName"
                value={values.financialDetails.branchName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Account Name</Typography>
              <TextField
                id="financialDetails.accountName"
                value={values.financialDetails.accountName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography sx={styles.field_label}>BSB</Typography>
              <TextField
                id="financialDetails.accountBsb"
                value={values.financialDetails.accountBsb}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Account Number</Typography>
              <TextField
                id="financialDetails.accountNumber"
                value={values.financialDetails.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>SWIFT Code</Typography>
              <TextField
                id="financialDetails.accountSwiftCode"
                value={values.financialDetails.accountSwiftCode}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <OutlinedBtn
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#000",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(1)}
                >
                  Back
                </OutlinedBtn>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Save
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default BankDetailsForm;
