import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import { IUpdateContactFieldsNew } from "models/contacts";

import IndividualLeftSteps from "./IndividualLeftSteps";
import PersonalDetailsForm from "./PersonalDetailsForm";
import RegistrationForm from "./RegistrationForm";
import BankDetailsForm from "./BankDetailsForm";
import SuperannuationForm from "./SuperannuationForm";
import EmergencyForm from "./EmergencyForm";
import SocialsForm from "./SocialsForm";

interface IIndividualFormProps {
  selectedContact: string;
  setActiveStep: Dispatch<SetStateAction<number>>;
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  initialValues: IUpdateContactFieldsNew;
  updateContactApi: any;
  refetch: any;
}

function IndividualForm(props: IIndividualFormProps) {
  const {
    setActiveStep,
    activeSection,
    setActiveSection,
    initialValues,
    updateContactApi,
    refetch,
  } = props;

  const [profilePicture, setProfilePicture] = useState<any>(null);

  useEffect(() => {
    setProfilePicture(initialValues.profile_picture);
  }, [initialValues.profile_picture]);

  return (
    <Box sx={{ height: "100%" }}>
      <Grid container>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={3}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Box sx={{ pt: 3 }}>
            <IndividualLeftSteps
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
            />
          </Box>
        </Grid>
        <Grid item lg={9} md={9} sm={9} xs={12}>
          <Box
            sx={{
              borderLeft: { xs: "none", sm: "1.5px #e6e6e6 solid" },
              ...(activeSection === 0
                ? { minHeight: "731.02px" }
                : { height: { xs: "546.02px", sm: "731.02px" } }),
            }}
          >
            {activeSection === 0 && (
              <PersonalDetailsForm
                setActiveSection={setActiveSection}
                initialValues={initialValues}
                refetch={refetch}
                profilePicture={profilePicture}
                setProfilePicture={setProfilePicture}
                updateContactApi={updateContactApi}
              />
            )}
            {activeSection === 1 && (
              <RegistrationForm
                setActiveSection={setActiveSection}
                initialValues={initialValues}
                refetch={refetch}
                profilePicture={profilePicture}
                updateContactApi={updateContactApi}
              />
            )}
            {activeSection === 2 && (
              <BankDetailsForm
                setActiveSection={setActiveSection}
                initialValues={initialValues}
                refetch={refetch}
                profilePicture={profilePicture}
                updateContactApi={updateContactApi}
              />
            )}
            {activeSection === 3 && (
              <SuperannuationForm
                setActiveSection={setActiveSection}
                initialValues={initialValues}
                refetch={refetch}
                profilePicture={profilePicture}
                updateContactApi={updateContactApi}
              />
            )}
            {activeSection === 4 && (
              <EmergencyForm
                setActiveSection={setActiveSection}
                initialValues={initialValues}
                refetch={refetch}
                profilePicture={profilePicture}
                updateContactApi={updateContactApi}
              />
            )}
            {activeSection === 5 && (
              <SocialsForm
                setActiveSection={setActiveSection}
                setActiveStep={setActiveStep}
                initialValues={initialValues}
                refetch={refetch}
                profilePicture={profilePicture}
                updateContactApi={updateContactApi}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default IndividualForm;
