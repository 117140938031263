import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import {
  BlackActionContainedButton,
  NewGreenPrimaryContainedButton,
  NewOutlinedBtn,
} from "common/Buttons";
import { IUpdateContactFieldsNew } from "models/contacts";
import { useStore } from "utils/store";
import { CREATE_AGENT } from "graphql/agents";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { getPermissions } from "permissions/utils";

interface IBankDetailsProps {
  initialValues: IUpdateContactFieldsNew;
  updateContactApi: any;
  refetch: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  newAgent: boolean;
  setNewAgent: Dispatch<SetStateAction<boolean>>;
}

function BankDetailsForm(props: IBankDetailsProps) {
  const {
    initialValues,
    updateContactApi,
    refetch,
    setActiveSection,
    setActiveStep,
    newAgent,
    setNewAgent,
  } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const agentsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [fireCreateAgentApi, { loading: creating }] = useMutation(
    CREATE_AGENT,
    {
      onCompleted: (data) => {
        const { createAgent } = data;
        const { status, message } = createAgent;
        if (status) {
          setNewAgent(false);
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSubmitAgencyForm = (values: IUpdateContactFieldsNew) => {
    const { id, agencyDetails } = values;
    const { companyName, financialDetails, address, ...restDetails } =
      agencyDetails;

    const {
      haveSuperannuationAccount: agencyHave,
      ...restAgencyFinancialDetails
    } = financialDetails;

    updateContactApi({
      variables: {
        payload: {
          id,
          agencyDetails: {
            companyName,
            financialDetails: { ...restAgencyFinancialDetails },
          },
        },
      },
      onCompleted: (data: any) => {
        setLoading(false);
        const { updateContactBasicDetails } = data;
        const { status, message } = updateContactBasicDetails;
        if (status) {
          if (
            Boolean(ROLE_PERMISSIONS) &&
            agentsEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]?.AGENTS_EDIT &&
            newAgent
          ) {
            fireCreateAgentApi({
              variables: {
                payload: {
                  companyName,
                  ...(typeof address !== "string" &&
                    address !== null && {
                      address: address?.freeformAddress,
                      companyCountry: address?.country || "",
                      companyState: address?.countrySubdivision || "",
                      companyCity: address?.municipality || "",
                      suburb: address?.municipalitySubdivision || "",
                      pinCode: address?.postalCode || "",
                    }),
                  ...(typeof address === "string" && {
                    address: address,
                  }),
                  financialDetails: { ...restAgencyFinancialDetails },
                  ...restDetails,
                },
              },
            });
          }
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });
  };

  useEffect(() => {
    setLoading(creating);
  }, [creating, setLoading]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={handleSubmitAgencyForm}
      enableReinitialize
    >
      {({ values, handleBlur, handleChange, handleSubmit }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Bank Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Bank Name</Typography>
              <TextField
                id="agencyDetails.financialDetails.bankName"
                value={values.agencyDetails.financialDetails.bankName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Branch</Typography>
              <TextField
                id="agencyDetails.financialDetails.branchName"
                value={values.agencyDetails.financialDetails.branchName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Account Name</Typography>
              <TextField
                id="agencyDetails.financialDetails.accountName"
                value={values.agencyDetails.financialDetails.accountName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>BSB</Typography>
              <TextField
                id="agencyDetails.financialDetails.accountBsb"
                value={values.agencyDetails.financialDetails.accountBsb}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>Account Number</Typography>
              <TextField
                id="agencyDetails.financialDetails.accountNumber"
                value={values.agencyDetails.financialDetails.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>SWIFT Code</Typography>
              <TextField
                id="agencyDetails.financialDetails.accountSwiftCode"
                value={values.agencyDetails.financialDetails.accountSwiftCode}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={[styles.flex_Acenter, { justifyContent: "space-between" }]}
              >
                <BlackActionContainedButton
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#fff",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(1)}
                >
                  Back
                </BlackActionContainedButton>
                <Box sx={[styles.flex_JCfe, { alignItems: "center" }]}>
                  <NewGreenPrimaryContainedButton
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </NewGreenPrimaryContainedButton>
                  <NewOutlinedBtn
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                      color: "#000",
                      ml: 1,
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => setActiveStep(3)}
                  >
                    Next
                  </NewOutlinedBtn>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default BankDetailsForm;
