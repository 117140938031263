import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "@mui/material";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Search,
} from "@mui/icons-material";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { useQuery } from "@apollo/client";

import { RouteNames } from "routes/routeNames";
import { images } from "assets/images";
import {
  ClientDrawerItemsAccessPermission,
  setClientDrawerSelection,
} from "utils/drawerUtils";
import { useProjectCreateModal } from "hooks/useCreateProjectModal";
import { GET_PROJECT_NAME_BREADCRUMB } from "graphql/projects";
import { GET_PROFILE_PICTURE } from "graphql/users";
import { StorageConstants } from "utils/storage";
import {
  getClientSideRedirectionRoute,
  getPermissions,
} from "permissions/utils";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import LogoutModal from "./LogoutModal";
import GlobalSearchModal from "./GlobalSearch/GlobalSearchModal";
// import NotificationsMenu from "./NotificationsMenu";

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface IProps {
  children: ReactNode;
}

export default function MiniDrawer(props: IProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { selectedDrawerItem, setSelectedDrawerItem, setLoading } = useStore();
  const projectCreateModal = useProjectCreateModal();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );
  const agentsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const agentsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [open, setOpen] = useState(
    Boolean(sessionStorage.getItem("drawerOpen"))
      ? sessionStorage.getItem("drawerOpen") === "true"
        ? true
        : false
      : true
  );
  const [openXsSm, setOpenXsSm] = useState(false);
  const [drawerItemsArr, setDrawerItemsArr] = useState<any>([]);
  // const [openAllProjects, setOpenAllProjects] = useState(true);
  // const [openRecent, setOpenRecent] = useState(true);
  const [openAvatarMenu, setOpenAvatarMenu] = useState(false);
  const [avatarAnchor, setAvatarAnchor] = useState<HTMLElement | null>(null);
  // const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  // const [notificationAnchor, setNotificationAnchor] =
  //   useState<HTMLElement | null>(null);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [projectNameBreadcrumb, setProjectNameBreadcrumb] = useState("");
  const [projectImage, setProjectImage] = useState<any>(null);
  const [projectStatus, setProjectStatus] = useState(4);
  const [profilePicture, setProfilePicture] = useState<any>(null);

  const { loading } = useQuery(GET_PROJECT_NAME_BREADCRUMB, {
    variables: {
      id: params?.project_id,
    },
    skip: !location.pathname.includes(`${RouteNames.PROJECTS}/`),
    onCompleted: (data) => {
      setLoading(false);
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        setProjectNameBreadcrumb(rawProject.name);
        setProjectImage(rawProject?.profilePicture);
        setProjectStatus(rawProject?.projectStatus);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: profilePhotoLoading } = useQuery(GET_PROFILE_PICTURE, {
    variables: {
      id: sessionStorage.getItem(StorageConstants.LOGGED_IN_USER_ID),
    },
    onCompleted: (data) => {
      setLoading(false);
      const { user } = data;
      const { profilePicture } = user;
      if (Boolean(profilePicture)) {
        setProfilePicture(profilePicture);
      } else {
        setProfilePicture(null);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleDrawerOpen = () => {
    sessionStorage.setItem("drawerOpen", "true");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    sessionStorage.setItem("drawerOpen", "false");
    setOpen(false);
  };

  const handleDrawerXsSmOpen = () => {
    setOpenXsSm(true);
  };

  const handleDrawerXsSmClose = () => {
    sessionStorage.setItem("drawerOpen", "false");
    setOpenXsSm(false);
  };

  const handleAvatarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAvatarAnchor(e.currentTarget);
    setOpenAvatarMenu(true);
  };

  const handleAvatarMenuClose = () => {
    setAvatarAnchor(null);
    setOpenAvatarMenu(false);
  };

  // const handleNotificationsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   setNotificationAnchor(e.currentTarget);
  //   setOpenNotificationsMenu(true);
  // };

  // const handleNotificationsMenuClose = () => {
  //   setNotificationAnchor(null);
  //   setOpenNotificationsMenu(false);
  // };

  // const handleAllProjectsClick = () => {
  //   setOpenAllProjects(!openAllProjects);
  // };

  // const handleRecentClick = () => {
  //   setOpenRecent(!openRecent);
  // };

  useEffect(() => {
    setDrawerItemsArr(ClientDrawerItemsAccessPermission(ROLE_PERMISSIONS));
  }, [ROLE_PERMISSIONS]);

  useEffect(() => {
    setClientDrawerSelection(location, setSelectedDrawerItem);
  }, [location, setSelectedDrawerItem]);

  useEffect(() => {
    setLoading(loading || profilePhotoLoading);
  }, [loading, profilePhotoLoading, setLoading]);

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <CssBaseline />
        <AppBar elevation={0} color="secondary" position="fixed" open={open}>
          <Toolbar sx={{ height: "90px" }}>
            {/* <img
            src={images.LOGO_SMALL}
            alt="logo"
            width={30}
            style={{
              marginRight: open ? 5 : 10,
              ...(open && { display: "none" }),
              marginTop: 15,
            }}
          /> */}
            {!open && (
              <IconButton
                size="large"
                sx={{
                  ml: -1.5,
                  mt: -1.5,
                  ":hover": { backgroundColor: "#41B19931" },
                }}
                onClick={() =>
                  open ? handleDrawerClose() : handleDrawerOpen()
                }
              >
                <img
                  src={images.MENU}
                  alt="menu"
                  style={{ width: 20, objectFit: "cover" }}
                />
              </IconButton>
            )}
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginRight: 3.5,
                marginLeft: 1,
                ...(open && { display: "none" }),
              }}
            />
            <Grid container spacing={2} alignItems="center">
              <Grid item sx={{ flexGrow: 1 }}>
                {location.pathname.includes(`${RouteNames.PROJECTS}/`) && (
                  <Box sx={{ display: "flex" }}>
                    {projectImage ? (
                      <Box
                        sx={{
                          height: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={projectImage}
                          alt="proj_image"
                          style={{
                            height: "80px",
                            width: "54px",
                            borderRadius: "8px",
                            objectFit: "cover",
                            aspectRatio: 0.675 / 1,
                            ...(projectStatus === 2 && {
                              filter: "grayscale(100%)",
                            }),
                          }}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            height: "80px",
                            width: "54px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: `#BFBDBC22`,
                            aspectRatio: 0.675 / 1,
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            src={images.FILM_REEL_DARK}
                            alt="reel"
                            style={{ height: 20 }}
                          />
                        </Box>
                      </Box>
                    )}
                    <Typography
                      fontSize="20px"
                      fontWeight={600}
                      color={`${colors.primaryBlack}80`}
                      sx={{ ml: 2, mt: 1 }}
                    >
                      {projectNameBreadcrumb}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item sx={{ mt: -4.5 }}>
                <Box
                  sx={{
                    cursor: "pointer",
                    width: { lg: 320, md: 270, sm: 220, xs: 170 },
                    border: `1px ${colors.primaryBlack}30 solid`,
                    borderRadius: "24px",
                    py: 0.75,
                    px: 1,
                    ":hover": {
                      borderColor: colors.primaryBlack,
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setOpenSearchModal(true)}
                >
                  <Search
                    sx={{ color: `${colors.primaryBlack}30`, fontSize: 22 }}
                  />
                  <Typography
                    sx={{
                      color: `${colors.primaryBlack}30`,
                      fontSize: "14px",
                      ml: 0.75,
                    }}
                  >
                    Search
                  </Typography>
                </Box>
              </Grid>
              {Boolean(ROLE_PERMISSIONS) &&
                projectEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                  ?.PROJECT_EDIT && (
                  <Grid item sx={{ mt: -4.5 }}>
                    <Tooltip title="Create New Project">
                      <IconButton
                        onClick={() => {
                          projectCreateModal.onOpen();
                          navigate(RouteNames.PROJECTS);
                        }}
                        sx={{
                          ":hover": { backgroundColor: "#41B19931" },
                          height: 45,
                          width: 45,
                        }}
                      >
                        <Avatar sx={{ backgroundColor: "transparent" }}>
                          <img
                            src={images.CREATE_PROJECT}
                            style={{ width: 23, objectFit: "cover" }}
                            alt="create project"
                          />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              <Grid item sx={{ mt: -4.5 }}>
                {Boolean(profilePicture) ? (
                  <Avatar
                    src={profilePicture}
                    component="div"
                    sx={{ cursor: "pointer" }}
                    onClick={handleAvatarClick}
                  />
                ) : (
                  <Avatar
                    component="div"
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#BFBDBC22",
                      color: "#e0e0e0",
                    }}
                    onClick={handleAvatarClick}
                  />
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={avatarAnchor}
                  open={openAvatarMenu}
                  onClose={handleAvatarMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        handleAvatarMenuClose();
                        navigate(`${RouteNames.SETTINGS}/profile`);
                      }}
                    >
                      <ListItemIcon>
                        <img src={images.PROFILE} alt="profile" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: colors.secondaryBlack,
                          fontSize: "13px",
                          fontWeight: 500,
                        }}
                      >
                        Profile
                      </ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleAvatarMenuClose();
                        setOpenLogoutModal(true);
                      }}
                    >
                      <ListItemIcon>
                        <img src={images.LOGOUT} alt="profile" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: colors.secondaryBlack,
                          fontSize: "13px",
                          fontWeight: 500,
                        }}
                      >
                        Logout
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Grid>
              {/* <Grid item lg={7} md={6} sm={12} xs={12}>
              {location.pathname.includes(`${RouteNames.PROJECTS}/`) && (
                // <Box display="flex" alignItems="center">
                //   <Typography fontSize="20px" color={colors.primaryBlack}>
                //     {getClientDrawerHeaderTitle(selectedDrawerItem)}
                //   </Typography>
                //   <Typography
                //     fontSize="20px"
                //     color={colors.primaryBlack}
                //     sx={{ mx: 1 }}
                //   >{`>`}</Typography>
                <Typography
                  fontSize="20px"
                  fontWeight={600}
                  color={colors.primaryBlack}
                >
                  {projectNameBreadcrumb}
                </Typography>
                // </Box>
              )}
            </Grid>
            <Grid
              item
              lg={5}
              md={6}
              sm={12}
              xs={12}
              justifyContent="flex-end"
              alignItems="center"
              display="flex"
            >
              <TextField
                size="small"
                placeholder="Search here"
                sx={{ "& fieldset": { borderRadius: "8px" } }}
                inputProps={{ style: { fontSize: "14px" } }}
                onClick={() => setOpenSearchModal(true)}
              />
              <Button
                variant="contained"
                sx={{ ml: 2, display: { xs: "none", sm: "block" } }}
                disableElevation
                onClick={() => {
                  projectCreateModal.onOpen();
                  navigate(RouteNames.PROJECTS);
                }}
              >
                Create Project
              </Button>
              <IconButton
                sx={{ ml: 1 }}
                aria-controls={
                  openNotificationsMenu ? "basic-menu1" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openNotificationsMenu ? "true" : undefined}
                onClick={handleNotificationsClick}
              >
                <Badge
                  variant="dot"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": { backgroundColor: colors.error },
                  }}
                >
                  <img src={images.NOTIFICATION} alt="notifications" />
                </Badge>
              </IconButton>
              <NotificationsMenu
                anchor={notificationAnchor}
                openMenu={openNotificationsMenu}
                handleClose={handleNotificationsMenuClose}
              />
              <IconButton
                sx={{ ml: 1 }}
                aria-controls={openAvatarMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAvatarMenu ? "true" : undefined}
                onClick={handleAvatarClick}
              >
                {Boolean(profilePicture) ? (
                  <Avatar src={profilePicture} />
                ) : (
                  <Avatar />
                )}
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={avatarAnchor}
                open={openAvatarMenu}
                onClose={handleAvatarMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      handleAvatarMenuClose();
                      navigate(RouteNames.SETTINGS);
                    }}
                  >
                    <ListItemIcon>
                      <img src={images.PROFILE} alt="profile" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: colors.secondaryBlack,
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      Profile
                    </ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleAvatarMenuClose();
                      setOpenLogoutModal(true);
                    }}
                  >
                    <ListItemIcon>
                      <img src={images.LOGOUT} alt="profile" />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: colors.secondaryBlack,
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      Logout
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{ cursor: "pointer", px: 2.5 }}
            onClick={() =>
              navigate(getClientSideRedirectionRoute(ROLE_PERMISSIONS))
            }
          >
            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
              <IconButton
                size="large"
                sx={{
                  ":hover": { backgroundColor: "#41B19931" },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (open) {
                    handleDrawerClose();
                  } else {
                    handleDrawerOpen();
                  }
                }}
              >
                <img
                  src={images.MENU}
                  alt="menu"
                  style={{ width: 20, objectFit: "cover" }}
                />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 2.5,
                }}
              >
                <img src={images.LOGO} alt="logo" />
              </Box>
            </Box>
          </DrawerHeader>
          <List
            sx={{
              height: "calc(100% - 100px)",
              overflowY: "auto",
              overflowX: "hidden",
              ...(!open && { mt: 0.75 }),
            }}
          >
            {drawerItemsArr.map((item: any) => (
              <ListItem key={item.id} disablePadding>
                {open && (
                  <ListItemButton
                    disableRipple
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3.5,
                    }}
                    selected={selectedDrawerItem === item.id}
                    onClick={() => {
                      setSelectedDrawerItem(item.id);
                      navigate(item.path);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 4 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          item.id === selectedDrawerItem
                            ? item.selectedIcon
                            : item.icon
                        }
                        alt="icon"
                        style={{ width: 20, objectFit: "cover" }}
                      />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color:
                            item.id === selectedDrawerItem
                              ? colors.primaryBlack
                              : `${colors.primaryBlack}80`,
                          // fontWeight: item.id === selectedDrawerItem ? 600 : 500,
                        }}
                      >
                        {item.label}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                )}
                {!open && (
                  <Box sx={{ minHeight: 48, px: 1.25 }}>
                    <Tooltip title={item.label}>
                      <IconButton
                        size="large"
                        sx={{
                          ":hover": { backgroundColor: "#41B19995" },
                          ...(item.id === selectedDrawerItem && {
                            backgroundColor: "#41B19931",
                          }),
                        }}
                        onClick={() => {
                          setSelectedDrawerItem(item.id);
                          navigate(item.path);
                        }}
                      >
                        <img
                          src={
                            item.id === selectedDrawerItem
                              ? item.selectedIcon
                              : item.icon
                          }
                          alt="icon"
                          style={{ width: 20, objectFit: "cover" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </ListItem>
            ))}
            {Boolean(ROLE_PERMISSIONS) &&
              agentsEditIndex !== undefined &&
              agentsViewIndex !== undefined &&
              (ROLE_PERMISSIONS[0]?.permissions[agentsViewIndex]?.AGENTS_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]
                  ?.AGENTS_EDIT) && (
                <Fragment>
                  <Divider sx={{ my: 1 }} />
                  <ListItem disablePadding>
                    {open ? (
                      <ListItemButton
                        disableRipple
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3.5,
                        }}
                        selected={selectedDrawerItem === 2}
                        onClick={() => {
                          setSelectedDrawerItem(2);
                          navigate(RouteNames.AGENTS);
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 4 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              selectedDrawerItem === 2
                                ? images.AGENTS_SELECTED
                                : images.AGENTS
                            }
                            alt="icon"
                            style={{ width: 20, objectFit: "cover" }}
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color:
                                selectedDrawerItem === 2
                                  ? colors.primaryBlack
                                  : `${colors.primaryBlack}80`,
                              // fontWeight: selectedDrawerItem === 2 ? 600 : 500,
                            }}
                          >
                            Agents
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    ) : (
                      <Box sx={{ minHeight: 48, px: 1.25 }}>
                        <Tooltip title="Agents">
                          <IconButton
                            size="large"
                            sx={{
                              ":hover": { backgroundColor: "#41B19995" },
                              ...(selectedDrawerItem === 2 && {
                                backgroundColor: "#41B19931",
                              }),
                            }}
                            onClick={() => {
                              setSelectedDrawerItem(2);
                              navigate(RouteNames.AGENTS);
                            }}
                          >
                            <img
                              src={
                                selectedDrawerItem === 2
                                  ? images.AGENTS_SELECTED
                                  : images.AGENTS
                              }
                              alt="icon"
                              style={{ width: 20, objectFit: "cover" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </ListItem>
                </Fragment>
              )}
            {/* <List>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                ...(!open && { display: "none" }),
              }}
            >
              <TextField
                size="small"
                placeholder="Search"
                sx={{ "& fieldset": { borderRadius: "20px" } }}
                inputProps={{ style: { fontSize: "13px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined fontSize="small" color="inherit" />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItemButton
              onClick={handleAllProjectsClick}
              sx={{
                ...(!open && { display: "none" }),
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <ListItemText
                primary="All Projects"
                sx={{ color: "#98999B", fontSize: "16px", fontWeight: 500 }}
              />
              {openAllProjects ? (
                <ExpandLess sx={{ color: "#98999B" }} />
              ) : (
                <ExpandMore sx={{ color: "#98999B" }} />
              )}
            </ListItemButton>
            <Collapse
              in={open ? openAllProjects : true}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {allProjects.map((item) => (
                  <ListItem
                    key={item.id}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() =>
                      navigate(
                        `${RouteNames.PROJECTS}/${item.id}/basic-details`
                      )
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "30px",
                            height: "30px",
                          }}
                          src={
                            Boolean(item?.profilePicture)
                              ? item.profilePicture
                              : images.REEL
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: colors.secondaryBlack,
                          fontSize: "13px",
                          fontWeight: 500,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider sx={{ ...(open && { display: "none" }) }} />
            <ListItemButton
              onClick={handleRecentClick}
              sx={{ ...(!open && { display: "none" }) }}
            >
              <ListItemText
                primary="Recent"
                sx={{ color: "#98999B", fontSize: "16px", fontWeight: 500 }}
              />
              {openRecent ? (
                <ExpandLess sx={{ color: "#98999B" }} />
              ) : (
                <ExpandMore sx={{ color: "#98999B" }} />
              )}
            </ListItemButton>
            <Collapse
              in={open ? openRecent : true}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {ClientRecentDrawerItems.map((item) => (
                  <ListItem
                    key={item.id}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img src={item.icon} alt="icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: colors.secondaryBlack,
                          fontSize: "13px",
                          fontWeight: 500,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List> */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            p: 3,
            width: "100%",
            overflowX: "auto",
          }}
        >
          <DrawerHeader />
          {props.children}
          <LogoutModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
          <GlobalSearchModal
            open={openSearchModal}
            setOpen={setOpenSearchModal}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {/* xs,sm HEADER */}
        <MuiAppBar elevation={0} sx={{ backgroundColor: "#fff" }}>
          <Box sx={{ display: "flex", p: 1, height: "90px" }}>
            <Box sx={{ mr: 1 }}>
              <IconButton
                size="large"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                onClick={() => handleDrawerXsSmOpen()}
              >
                <KeyboardDoubleArrowRight sx={{ color: "#000" }} />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              {location.pathname.includes(`${RouteNames.PROJECTS}/`) && (
                <Box sx={{ display: "flex" }}>
                  {projectImage ? (
                    <Box
                      sx={{
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={projectImage}
                        alt="proj_image"
                        style={{
                          height: "80px",
                          width: "54px",
                          borderRadius: "8px",
                          objectFit: "cover",
                          aspectRatio: 0.675 / 1,
                          ...(projectStatus === 2 && {
                            filter: "grayscale(100%)",
                          }),
                        }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          height: "80px",
                          width: "54px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: `#BFBDBC22`,
                          aspectRatio: 0.675 / 1,
                          borderRadius: "8px",
                        }}
                      >
                        <img
                          src={images.FILM_REEL_DARK}
                          alt="reel"
                          style={{ height: 20 }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Typography
                    fontSize="20px"
                    fontWeight={600}
                    color={`${colors.primaryBlack}80`}
                    sx={{ ml: 2, mt: 1 }}
                  >
                    {projectNameBreadcrumb}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ ml: 1, display: "flex" }}>
              <Box>
                <IconButton
                  size="large"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                  onClick={() => setOpenSearchModal(true)}
                >
                  <Search sx={{ color: "#000000" }} />
                </IconButton>
              </Box>
              {Boolean(ROLE_PERMISSIONS) &&
                projectEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                  ?.PROJECT_EDIT && (
                  <Box>
                    <Tooltip title="Create New Project">
                      <IconButton
                        onClick={() => {
                          projectCreateModal.onOpen();
                          navigate(RouteNames.PROJECTS);
                        }}
                        sx={{
                          ":hover": { backgroundColor: "#41B19931" },
                          height: 47,
                          width: 47,
                        }}
                      >
                        <Avatar sx={{ backgroundColor: "transparent" }}>
                          <img
                            src={images.CREATE_PROJECT}
                            style={{ width: 23, objectFit: "cover" }}
                            alt="create project"
                          />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              <Box sx={{ ml: 1 }}>
                {Boolean(profilePicture) ? (
                  <Avatar
                    src={profilePicture}
                    component="div"
                    sx={{
                      cursor: "pointer",
                      border: "1px #e6e6e6 solid",
                      height: 47,
                      width: 47,
                    }}
                    onClick={handleAvatarClick}
                  />
                ) : (
                  <Avatar
                    component="div"
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#BFBDBC22",
                      color: "#e0e0e0",
                      border: "1px #e6e6e6 solid",
                      height: 47,
                      width: 47,
                    }}
                    onClick={handleAvatarClick}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </MuiAppBar>
        {/* xs,sm DRAWER */}
        <MuiDrawer open={openXsSm}>
          <Box sx={{ width: drawerWidth }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: 2,
                px: 1,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(getClientSideRedirectionRoute(ROLE_PERMISSIONS))
                }
              >
                <img src={images.LOGO} alt="logo" />
              </Box>
              <Box sx={{ position: "absolute", right: 10 }}>
                <IconButton
                  size="large"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                  onClick={() => handleDrawerXsSmClose()}
                >
                  <KeyboardDoubleArrowLeft sx={{ color: "#000" }} />
                </IconButton>
              </Box>
            </Box>
            <List>
              {drawerItemsArr.map((item: any) => (
                <ListItem key={item.id} disablePadding>
                  <ListItemButton
                    disableRipple
                    sx={{
                      minHeight: 48,
                      justifyContent: openXsSm ? "initial" : "center",
                      px: 3.5,
                    }}
                    selected={selectedDrawerItem === item.id}
                    onClick={() => {
                      setSelectedDrawerItem(item.id);
                      navigate(item.path);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: openXsSm ? 4 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          item.id === selectedDrawerItem
                            ? item.selectedIcon
                            : item.icon
                        }
                        alt="icon"
                        style={{ width: 20, objectFit: "cover" }}
                      />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: openXsSm ? 1 : 0 }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color:
                            item.id === selectedDrawerItem
                              ? colors.primaryBlack
                              : `${colors.primaryBlack}80`,
                          // fontWeight: item.id === selectedDrawerItem ? 600 : 500,
                        }}
                      >
                        {item.label}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              {Boolean(ROLE_PERMISSIONS) &&
                agentsEditIndex !== undefined &&
                agentsViewIndex !== undefined &&
                (ROLE_PERMISSIONS[0]?.permissions[agentsViewIndex]
                  ?.AGENTS_VIEW ||
                  ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]
                    ?.AGENTS_EDIT) && (
                  <Fragment>
                    <Divider sx={{ my: 1 }} />
                    <ListItem disablePadding>
                      <ListItemButton
                        disableRipple
                        sx={{
                          minHeight: 48,
                          justifyContent: openXsSm ? "initial" : "center",
                          px: 3.5,
                        }}
                        selected={selectedDrawerItem === 2}
                        onClick={() => {
                          setSelectedDrawerItem(2);
                          navigate(RouteNames.AGENTS);
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: openXsSm ? 4 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={
                              selectedDrawerItem === 2
                                ? images.AGENTS_SELECTED
                                : images.AGENTS
                            }
                            alt="icon"
                            style={{ width: 20, objectFit: "cover" }}
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: openXsSm ? 1 : 0 }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color:
                                selectedDrawerItem === 2
                                  ? colors.primaryBlack
                                  : `${colors.primaryBlack}80`,
                              // fontWeight: selectedDrawerItem === 2 ? 600 : 500,
                            }}
                          >
                            Agents
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Fragment>
                )}
            </List>
          </Box>
        </MuiDrawer>
        <Box component="main" sx={{ p: 2 }}>
          <DrawerHeader sx={{ height: "90px" }} />
          {props.children}
        </Box>
      </Box>
    </>
  );
}
