import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQuery } from "@apollo/client";

import { GlobalLabels, ManageClientsLabels } from "common/AppConstants";
import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { IClient } from "models/clients";
import { GET_CLIENT_DETAILS } from "graphql/clients";

import { ManageClientsStyles as styles } from "./styles";

interface IInformationModalProps extends IModalProps {
  selectedClient: string;
  setSelectedClient: Dispatch<SetStateAction<string>>;
}

const InformationModal = (props: IInformationModalProps) => {
  const { open, setOpen, selectedClient, setSelectedClient } = props;

  const { setLoading } = useStore();

  const [clientDetails, setClientDetails] = useState<IClient | null>(null);

  const { loading } = useQuery(GET_CLIENT_DETAILS, {
    variables: { id: selectedClient },
    skip: !selectedClient,
    onCompleted: (data) => {
      const { getCompany } = data;
      const { status, company } = getCompany;
      if (status) {
        const { city, state, country, industry, ...rest } = company;
        setClientDetails({
          ...rest,
          city: city || "",
          state: state || "",
          country: country || "",
          industry: industry?.name || "",
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setSelectedClient("");
    setOpen(false);
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={styles.flex_JCsb_Acenter}>
        <Typography sx={styles.modal_header_text}>
          {GlobalLabels.INFO}
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Grid container spacing={4} sx={{ p: 3 }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.COMP_NAME}
          </Typography>
          <Typography sx={styles.info_text}>{clientDetails?.name}</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.ABN_CODE}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.abnCode}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.COMP_SITE}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.website}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.NO_OF_EMP}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.noOfEmployees}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.EMP_MANAGE_CNCT}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.employeeManagingContracts}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.INDUSTRY}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.industry}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>Address</Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.address}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {GlobalLabels.COUNTRY}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.country}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {GlobalLabels.STATE}
          </Typography>
          <Typography sx={styles.info_text}>{clientDetails?.state}</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {GlobalLabels.CITY}
          </Typography>
          <Typography sx={styles.info_text}>{clientDetails?.city}</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {GlobalLabels.SUBURB}
          </Typography>
          <Typography sx={styles.info_text}>{clientDetails?.suburb}</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {GlobalLabels.PINCODE}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.pincode}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.FULL_NAME}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.client?.fullName}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.DESIGNATION}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.client?.designation}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.EMAIL}
          </Typography>
          <Typography
            sx={[
              styles.info_text,
              { width: "100%", whiteSpace: "normal", wordWrap: "break-word" },
            ]}
          >
            {clientDetails?.client?.email}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography sx={styles.info_label_text}>
            {ManageClientsLabels.CLIENT_FORM.MOBILE}
          </Typography>
          <Typography sx={styles.info_text}>
            {clientDetails?.client?.mobile}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default InformationModal;
