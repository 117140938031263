import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { RouteNames } from "routes/routeNames";
import { GlobalLabels } from "common/AppConstants";
import { getJwtTokenDetails } from "utils/storage";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import {
  getClientSideRedirectionRoute,
  getPermissions,
} from "permissions/utils";
import { images } from "assets/images";

import { TwoFASuccessStyles as styles } from "./styles";

const SuccessAcknowledgement = () => {
  const navigate = useNavigate();

  const { roles } = getJwtTokenDetails();

  const redirectUrl = useMemo(() => sessionStorage.getItem("redirect_url"), []);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);

  return (
    <Box
      sx={[
        styles.content_center,
        {
          width: { xs: "100%", sm: "75%", md: "50%" },
          py: { xs: 4, sm: 4, md: 0 },
          px: { xs: 4, sm: 8, md: 0 },
          backgroundColor: { xs: "none", sm: "#fff", md: "none" },
          borderRadius: { xs: 0, sm: "15px", md: 0 },
          mx: { xs: 0, sm: 4, md: 0 },
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <img src={images.LOGO_BALLS} alt="logo" width="100" />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mt: { xs: 0, sm: 1, md: 2, lg: 3 } }}
        >
          <Typography sx={[styles.success_text, { textAlign: "justify" }]}>
            Welcome Back!
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mt: { xs: 0, sm: 1, md: 2, lg: 3 } }}
        >
          <Typography sx={[styles.tc_text, { textAlign: "justify" }]}>
            By proceeding with your log in to the finka platform, you
            acknowledge and agree that neither Finka Solutions Pty. Ltd or the
            finka platform (collectively "finka") provide legal services to you,
            nor is finka a substitute for legal advice.
            <br />
            <br />
            Only a lawyer can advise you or your organization in relation to the
            laws that apply to your particular situation. finka makes no
            guarantee or warranty as to the accuracy or applicability of the
            information contained on its website, its platform, its template
            agreements or any of its documents.
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={[styles.content_center, { mt: { xs: 0, sm: 1, md: 2, lg: 3 } }]}
        >
          <Box sx={{ width: "100%" }}>
            <NewGreenPrimaryContainedButton
              disableElevation
              variant="contained"
              size="small"
              sx={styles.login_btn}
              onClick={() => {
                Boolean(redirectUrl)
                  ? navigate(redirectUrl!)
                  : roles[0] === "super_admin"
                  ? navigate(RouteNames.ADMIN_DASHBOARD)
                  : navigate(getClientSideRedirectionRoute(ROLE_PERMISSIONS));
                // sessionStorage.removeItem("redirect_url");
              }}
            >
              {GlobalLabels.CONTINUE}
            </NewGreenPrimaryContainedButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessAcknowledgement;
