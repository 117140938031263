import React from "react";
import { Box, Typography } from "@mui/material";

import { GlobalLabels } from "common/AppConstants";
import { images } from "assets/images";
import { colors } from "theme/colors";

const styles = {
  container: {
    width: "calc(100vw - 20px)",
    height: "calc(100vh - 70px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  success_text: {
    fontSize: "25px",
    fontWeight: 600,
    color: colors.primaryBlack,
    textAlign: "center",
    mt: 4,
  },
  sub_text: { color: "#5B5C60", textAlign: "center", fontSize: "18px", mt: 2 },
};

export function ContactDetailsSuccess() {
  return (
    <Box>
      <img
        src={images.LOGO}
        alt="logo"
        height="40px"
        style={{ marginTop: "20px", marginLeft: "20px" }}
      />
      <Box sx={styles.container}>
        <img src={images.GREEN_TICK} alt="success" />
        <Typography sx={styles.success_text}>{GlobalLabels.SUCCESS}</Typography>
        <Typography sx={styles.sub_text}>
          Thank you for providing your details
        </Typography>
      </Box>
    </Box>
  );
}
