import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import queryString from "query-string";

import { RouteNames } from "routes/routeNames";
import { images } from "assets/images";
import { GlobalLabels, ResetPwdLabels } from "common/AppConstants";
import { SET_PASSWORD } from "graphql/users";
import { useStore } from "utils/store";

import { ResetPwdStyles as styles } from "./styles";

interface IResetPwdFields {
  password: string;
  confirmPassword: string;
}

const ResetPwdForm = () => {
  const { setLoading } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [fireSetPwdApi, { loading }] = useMutation(SET_PASSWORD, {
    onCompleted: (data) => {
      setLoading(false);
      const { setPassword } = data;
      const { status, error } = setPassword;
      if (status) {
        navigate(RouteNames.RESET_PASSWORD_SUCCESS);
      } else {
        toast.error(error, { delay: 10 });
      }
    },
  });

  const handleSetPwd = (values: IResetPwdFields) => {
    const parseQueryString = queryString.parse(location.search);
    const { token, id } = parseQueryString;
    fireSetPwdApi({
      variables: {
        payload: {
          token: token,
          id: id,
          password: values.password,
        },
      },
    });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Please enter your new password"),
        confirmPassword: Yup.string()
          .required("Please confirm your new password")
          .oneOf([Yup.ref("password")], "Passwords must match"),
      })}
      onSubmit={handleSetPwd}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{ px: { xs: 2, sm: "auto" }, py: { xs: 4, sm: "auto" } }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <img src={images.LOGO} alt="logo" />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={[styles.content_center, styles.form_container]}
            >
              <Typography sx={styles.reset_pwd_text}>
                {ResetPwdLabels.RESET_PWD}
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <Box>
                <Typography sx={styles.textfield_label}>
                  {ResetPwdLabels.NEW_PWD}
                </Typography>
                <TextField
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  sx={styles.textfield}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined
                              fontSize="small"
                              sx={styles.pwd_icon}
                            />
                          ) : (
                            <VisibilityOutlined
                              fontSize="small"
                              sx={styles.pwd_icon}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {touched?.password && errors?.password && (
                  <Typography sx={styles.error_text}>
                    {errors.password}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <Box>
                <Typography sx={styles.textfield_label}>
                  {ResetPwdLabels.CONFIRM_PWD}
                </Typography>
                <TextField
                  id="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  sx={styles.textfield}
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffOutlined
                              fontSize="small"
                              sx={styles.pwd_icon}
                            />
                          ) : (
                            <VisibilityOutlined
                              fontSize="small"
                              sx={styles.pwd_icon}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {touched?.confirmPassword && errors?.confirmPassword && (
                  <Typography sx={styles.error_text}>
                    {errors.confirmPassword}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={styles.continue_btn}
                  disabled={!isValid}
                >
                  {GlobalLabels.CONTINUE}
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.content_center}
            >
              <Box>
                <Button
                  sx={styles.back_btn}
                  onClick={() => navigate(RouteNames.FORGOT_PWD)}
                >
                  {GlobalLabels.GO_BACK}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPwdForm;
