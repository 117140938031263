import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { Person } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { IPaginationModel } from "models/common";
import Drawer from "common/AdminDrawer";
import Table from "common/Table";
import SuccessModal from "common/SuccessModal";
import ErrorModal from "common/ErrorModal";
import NoDataView from "common/NoDataView";
import NoResultsFoundView from "common/NoResultsFoundView";
import { GET_ADMINS } from "graphql/admin";
import { IUser } from "models/user";
import { useStore } from "utils/store";

import Filters from "./Filters";
import AddEditAdminModal from "./AddEditAdminModal";
import DeleteAdminModal from "./DeleteAdminModal";
import { ManageAdminsStyles as styles } from "./styles";

// TODO: sort by fullname - not done in backend

export const ManageAdmins = () => {
  const { setLoading } = useStore();

  const [admins, setAdmins] = useState<IUser[]>([]);
  const [adminCount, setAdminCount] = useState(0);
  const [addEditAdminModal, setAddEditAdminModal] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [selectedAdminName, setSelectedAdminName] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  const { loading, refetch } = useQuery(GET_ADMINS, {
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      filter: { fullName__icontains: searchText },
      isSuperAdmin: true,
    },
    onCompleted: (data) => {
      setLoading(false);
      const { users } = data;
      const { count, users: rawUsers } = users;
      if (count > 0) {
        setAdmins(rawUsers);
        setAdminCount(count);
      } else {
        setAdmins([]);
        setAdminCount(0);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Full Name",
        sortable: false,
        minWidth: 220,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Box sx={styles.flex_Acenter}>
              {Boolean(params?.row?.profilePicture) ? (
                <Avatar
                  src={params?.row?.profilePicture}
                  sx={{ mr: 1, width: 34, height: 34 }}
                />
              ) : (
                <Avatar sx={{ mr: 1, width: 34, height: 34 }}>
                  <Person />
                </Avatar>
              )}
              <Typography sx={styles.cell_text}>{params?.value}</Typography>
            </Box>
          );
        },
      },
      {
        field: "mobile",
        headerName: "Contact Number",
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return <Typography sx={styles.cell_text}>{params?.value}</Typography>;
        },
      },
      {
        field: "email",
        headerName: "Email Id",
        sortable: false,
        minWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Typography
              sx={[
                styles.cell_text,
                { width: "100%", wordBreak: "break-word" },
              ]}
            >
              {params?.value}
            </Typography>
          );
        },
      },
      {
        field: "createdDate",
        headerName: "Joined Date",
        headerAlign: "center",
        sortable: false,
        minWidth: 145,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Typography sx={styles.cell_text}>
              {Boolean(params?.value)
                ? moment(params?.value).format("DD MMMM YYYY")
                : ""}
            </Typography>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        sortable: false,
        minWidth: 110,
        flex: 1,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            showInMenu
            label="Edit"
            onClick={() => {
              setSelectedAdmin(params.row.id);
              setAddEditAdminModal(true);
            }}
            sx={styles.actions_text}
          />,
          <GridActionsCellItem
            showInMenu
            label="Delete"
            onClick={() => {
              setSelectedAdmin(params.row.id);
              setSelectedAdminName(params.row.fullName);
              setDeleteModal(true);
            }}
            sx={styles.actions_text}
          />,
        ],
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={styles.filter_container}>
            <Filters
              setAddAdminModal={setAddEditAdminModal}
              setSearchText={setSearchText}
            />
          </Box>
        </Grid>
        {!loading && !searchText && adminCount === 0 && (
          <NoDataView title="No Admins Found" subtitle="Please add an admin" />
        )}
        {!loading && searchText && adminCount === 0 && <NoResultsFoundView />}
        {!loading && adminCount > 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              rows={admins}
              columns={columns}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnMenu
              rowCount={adminCount}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              sx={styles.table}
            />
          </Grid>
        )}
      </Grid>
      {addEditAdminModal && (
        <AddEditAdminModal
          open={addEditAdminModal}
          setOpen={setAddEditAdminModal}
          selectedAdmin={selectedAdmin}
          setSelectedAdmin={setSelectedAdmin}
          setSuccessModal={setSuccessModal}
          setErrorModal={setErrorModal}
          setSuccessText={setSuccessText}
          setErrorText={setErrorText}
          refetchTableData={refetch}
        />
      )}
      {deleteModal && (
        <DeleteAdminModal
          open={deleteModal}
          setOpen={setDeleteModal}
          selectedAdmin={selectedAdmin}
          selectedAdminName={selectedAdminName}
          refetchTableData={refetch}
          setSelectedAdmin={setSelectedAdmin}
          setSelectedAdminName={setSelectedAdminName}
        />
      )}
      {successModal && (
        <SuccessModal
          open={successModal}
          setOpen={setSuccessModal}
          successText={successText}
          onClose={() => setSuccessText("")}
        />
      )}
      {errorModal && (
        <ErrorModal
          open={errorModal}
          setOpen={setErrorModal}
          errorText={errorText}
          tryAgainBtnClick={() => setErrorText("")}
        />
      )}
    </Drawer>
  );
};
