import { gql } from "@apollo/client";

export const GET_ALL_PROJECTS = gql`
  query projects(
    $filter: ProjectFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    projects(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      message
      count
      projects {
        id
        name
        projectNumber
        description
        episodes
        episodeDuration
        projectType
        projectCategory
        categoryDescription
        categoryLoading
        categoryLoadingInputs
        collectiveAgreementCast
        collectiveAgreementCrew
        projectStartDate
        projectEndDate
        shootDays
        filmingDate
        filmingLocation
        projectMaterials
        projectStatus
        director
        producer
        writer
        status
        createdBy {
          id
          email
        }
        company {
          id
          name
        }
        profilePicture
        projectUsers {
          id
          fullName
          profilePicture
        }
      }
    }
  }
`;

export const GET_ALL_PROJECTS_DROPDOWN = gql`
  query projects {
    projects {
      status
      message
      count
      projects {
        id
        name
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $payload: ProjectCreateInput!
    $file: Upload
    $script: Upload
  ) {
    createProject(payload: $payload, file: $file, script: $script) {
      status
      message
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        name
        projectNumber
        description
        episodes
        episodeDuration
        projectType
        projectCategory
        categoryDescription
        categoryLoading
        categoryLoadingInputs
        collectiveAgreementCast
        collectiveAgreementCrew
        projectStartDate
        projectEndDate
        shootDays
        filmingDate
        filmingLocation
        projectMaterials
        projectStatus
        director
        producer
        writer
        createdBy {
          id
          email
        }
        company {
          id
          name
        }
        profilePicture
        projectDates
        currency
        dateFormat
        script
        scriptName
      }
    }
  }
`;

export const GET_PROJECT_NAME_BREADCRUMB = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        name
        profilePicture
        projectStatus
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $payload: ProjectUpdateInput!
    $file: Upload
    $script: Upload
  ) {
    updateProject(payload: $payload, file: $file, script: $script) {
      status
      message
    }
  }
`;

export const DELETE_PROJECTs = gql`
  mutation deleteProject($ids: [String!]) {
    deleteProject(ids: $ids) {
      status
      message
    }
  }
`;

export const GET_KEY_PERSONNEL_DETAILS = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        director
        producer
        writer
        keyPersonals
      }
    }
  }
`;

export const GET_PROJECT_DATES_DETAILS = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        name
        projectStartDate
        projectEndDate
        profilePicture
      }
    }
  }
`;

export const GET_FILMING_DATES_LOCATION = gql`
  query projectDates(
    $id: String!
    $filter: ProjectDatesFilterInput
    $limit: Int
    $page: Int
  ) {
    projectsDates(id: $id, filter: $filter, limit: $limit, page: $page) {
      status
      message
      projectDates {
        id
        location
        startDate
        endDate
      }
    }
  }
`;

export const UPSERT_FILMING_DATES_LOCATION = gql`
  mutation upsertProjectDates($payload: ProjectDatesInput!) {
    upsertProjectDates(payload: $payload) {
      status
      message
    }
  }
`;

export const DELETE_FILMING_DATE_LOCATION = gql`
  mutation deleteProjectDates($ids: [String!]) {
    deleteProjectDates(ids: $ids) {
      status
      message
    }
  }
`;

export const GET_SPV_COMPANY_DETAILS = gql`
  query getSPVCompanyDetails($project_id: String!, $company_type: String!) {
    spvCompanyDetails(project_id: $project_id, company_type: $company_type) {
      status
      message
      spvCompanyDetails {
        id
        companyName
        registrationType
        registrationNumber
        suburb
        street
        placeOfIncorporation
        town
        state
        postCode
        country
        contactName
        email
        details
        address
      }
    }
  }
`;

export const UPSERT_SPV_COMPANY_DETAILS = gql`
  mutation upsertSPVCompanyDetails($payload: SPVCompanyDetailsUpsertInput!) {
    upsertSPVCompanyDetails(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_RIGHTS_BUY = gql`
  query projectRights(
    $id: String!
    $filter: ProjectRightsFilterInput
    $limit: Int
    $page: Int
  ) {
    projectRights(id: $id, filter: $filter, limit: $limit, page: $page) {
      count
      status
      message
      inAggregate
      projectRights {
        id
        award
        rightsUsage
        loading
        aggregateInclusion
        notes
        details
      }
      project {
        collectiveAgreementCast
      }
    }
  }
`;

export const UPSERT_RIGHTS_BUY = gql`
  mutation upsertProjectRights($payload: [ProjectRightInput!]) {
    upsertProjectRights(payloads: $payload) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_RIGHTS = gql`
  mutation deleteProjectRights($ids: [String!]) {
    deleteProjectRights(ids: $ids) {
      status
      message
    }
  }
`;

export const GET_PROJECT_SETTINGS_DETAILS = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        currency
        dateFormat
      }
    }
  }
`;

export const GET_PROJECT_PHOTO = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        profilePicture
      }
    }
  }
`;

export const GET_DRAWER_PROJECTS = gql`
  query projects(
    $filter: ProjectFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    projects(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      message
      count
      projects {
        id
        name
        profilePicture
      }
    }
  }
`;

export const GET_DASHBOARD_PROJECTS = gql`
  query projects(
    $filter: ProjectFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    projects(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      message
      count
      projects {
        id
        name
        profilePicture
        projectStatus
        projectStartDate
        projectEndDate
        projectType
      }
    }
  }
`;

export const GET_SPV_COMPANIES_DROPDOWN = gql`
  query spvCompanies($project_id: String!) {
    spvCompanies(project_id: $project_id) {
      status
      message
      spvCompanies {
        id
        companyName
        companyType
      }
    }
  }
`;

export const GET_PROJECT_DATES = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      message
      project {
        id
        projectDates
      }
    }
  }
`;
