import { gql } from "@apollo/client";

export const GET_CONTRACT_REPORT = gql`
  query allContracts(
    $filter: ContractReportFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getAllContracts(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      status
      message
      count
      documents {
        id
        name
        isFolder
        additionalDetails
        isUpload
        documentStatus
        project
        contact {
          fullName
        }
        modifiedDate
      }
    }
  }
`;

export const EXPORT_CONTRACT_REPORT = gql`
  query exportContracts($filter: ContractReportFilterInput, $sorted: String) {
    getAllContractsReport(filter: $filter, sorted: $sorted) {
      report
    }
  }
`;

export const GET_CONTACT_REPORT = gql`
  query allContacts(
    $filter: ContactFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getAllContacts(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      status
      message
      count
      contacts {
        id
        fullName
        emailId
        mobile
        assignedProject {
          name
        }
        profile_picture
        isRestricted
        profile
        contactDepartment {
          id
          name
        }
      }
    }
  }
`;

export const EXPORT_CONTACT_REPORT = gql`
  query exportContacts($filter: ContactFilterInput, $sorted: String) {
    getAllContactsReport(filter: $filter, sorted: $sorted) {
      report
    }
  }
`;

export const GET_CLEARANCE_REPORT = gql`
  query getClearanceReport(
    $filter: ClearanceReportFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getClearanceReport(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      status
      message
      count
      registerItems {
        id
        name
        clearanceScriptPage
        clearanceScriptScene
        clearanceCategory
        clearanceSubCategory
        clearanceComments
        clearanceTimecode
        clearanceBasisOfUse
        clearanceCreditObligations
        clearanceCreditObligationsDescription
        clearanceAdPromoRestrictions
        clearanceTechSpec
        clearanceIssue
        clearanceScreengrabs {
          id
          file
          filename
        }
        status
      }
    }
  }
`;

export const EXPORT_CLEARANCE_REPORT = gql`
  query generateClearanceReport(
    $filter: ClearanceReportFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
    $report_type: String!
  ) {
    generateClearanceReport(
      filter: $filter
      limit: $limit
      pag: $page
      sorted: $sorted
      report_type: $report_type
    ) {
      report
    }
  }
`;

export const EXPORT_DELIVERY_REPORT = gql`
  query generateDeliveryReport(
    $project_id: String!
    $checklist_id: String
    $report_type: String!
  ) {
    generateDeliveryReport(
      project_id: $project_id
      checklist_id: $checklist_id
      report_type: $report_type
    ) {
      report
    }
  }
`;

export const GET_CAST_CREW_CONTRACT_STATUS_REPORT = gql`
  query getCastAndCrewReport(
    $filter: CastAndCrewReportFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getCastAndCrewReport(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      status
      message
      count
      registerItems {
        id
        name
        description
        startDate
        comments
        contactName
        projectRole
        department
        startDate
        daysToStart
        contractStatus
      }
    }
  }
`;

export const EXPORT_CAST_CREW_CONTRACT_STATUS_REPORT = gql`
  query getCastAndCrewReportExport(
    $filter: CastAndCrewReportFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getCastAndCrewReportExport(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      report
    }
  }
`;
