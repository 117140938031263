export const setAgencyDetails = (values: any, setFieldValue: any) => {
  const {
    id,
    // companyCity,
    // companyCountry,
    // companyState,
    agentFinancialDetails,
    photo,
    __typename,
    ...rest
  } = values;
  setFieldValue("agencyDetails", {
    ...rest,
    financialDetails: {
      accountName: agentFinancialDetails?.accountName || "",
      accountNumber: agentFinancialDetails?.accountNumber || "",
      accountBsb: agentFinancialDetails?.accountBsb || "",
      accountSwiftCode: agentFinancialDetails?.accountSwiftCode || "",
      bankName: agentFinancialDetails?.bankName || "",
      branchName: agentFinancialDetails?.branchName || "",
      accountInstructions: agentFinancialDetails?.accountInstructions || "",
      abn: agentFinancialDetails?.abn || "",
      superannuationFundName:
        agentFinancialDetails?.superannuationFundName || "",
      superannuationAccountNo:
        agentFinancialDetails?.superannuationAccountNo || "",
      taxFileNo: agentFinancialDetails?.taxFileNo || "",
    },
  });
};
