import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { DeleteOutline, ModeEditOutline, MoreVert } from "@mui/icons-material";

import { IAgent } from "models/agents";
import { colors } from "theme/colors";

interface IAgentCardProps {
  agent: IAgent;
  setSelectedAgentId: Dispatch<SetStateAction<string>>;
  setEditAgentModal: Dispatch<SetStateAction<boolean>>;
  setDeleteAgentModal: Dispatch<SetStateAction<boolean>>;
  setAgentInfoModal: Dispatch<SetStateAction<boolean>>;
  setSelectedAgentName: Dispatch<SetStateAction<string>>;
  permissions: any;
  agentsEditIndex: number;
}

function AgentCard(props: IAgentCardProps) {
  const {
    agent,
    setSelectedAgentId,
    setAgentInfoModal,
    setEditAgentModal,
    setDeleteAgentModal,
    setSelectedAgentName,
    permissions,
    agentsEditIndex,
  } = props;

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleActionsMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActionsMenuAnchor(e.currentTarget);
    setOpenActionsMenu(true);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setOpenActionsMenu(false);
  };

  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        justifyContent: { xs: "center", md: "flex-start" },
      }}
    >
      <Box
        component="div"
        sx={{
          cursor: "pointer",
          height: 210,
          width: 168,
          aspectRatio: 0.8 / 1,
          backgroundColor: "#B6BCC320",
          borderRadius: "15px",
          p: 2,
          position: "relative",
          ":hover": {
            backgroundColor: `${colors.primaryBlack}15`,
          },
        }}
        onClick={() => {
          setSelectedAgentId(agent.id);
          setAgentInfoModal(true);
        }}
      >
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {agent?.photo ? (
                <Avatar
                  sx={{ height: "60px", width: "60px" }}
                  src={agent?.photo}
                />
              ) : (
                <Avatar sx={{ height: "60px", width: "60px" }} />
              )}
            </Box>
            {Boolean(permissions) &&
              agentsEditIndex !== undefined &&
              permissions[0]?.permissions[agentsEditIndex]?.AGENTS_EDIT && (
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActionsMenuOpen(e);
                    }}
                  >
                    <MoreVert fontSize="small" sx={{ color: "#00000055" }} />
                  </IconButton>
                </Box>
              )}
            <Menu
              anchorEl={actionsMenuAnchor}
              open={openActionsMenu}
              onClose={handleActionsMenuClose}
              elevation={1}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <MenuList disablePadding>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedAgentId(agent.id);
                    setEditAgentModal(true);
                    handleActionsMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <ModeEditOutline
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Edit
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedAgentId(agent.id);
                    setSelectedAgentName(agent.spocName);
                    setDeleteAgentModal(true);
                    handleActionsMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutline
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Delete
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 1.5 }}>
            <Typography
              noWrap
              textAlign="center"
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: colors.primaryBlack,
              }}
            >
              {agent.spocName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Chip
              label={agent.companyName}
              sx={{
                fontSize: "12px",
                backgroundColor: "#333333",
                color: colors.secondary,
                fontWeight: 600,
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1.5 }}>
          <Typography
            noWrap
            textAlign="center"
            sx={{
              fontSize: "13px",
              color: colors.primaryBlack,
            }}
          >
            {agent.spocMobile}
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
}

export default AgentCard;
