import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useStore } from "utils/store";
import { toast } from "react-toastify";

import { IUpdateContactFieldsNew } from "models/contacts";
import {
  BlackActionContainedButton,
  NewGreenPrimaryContainedButton,
  NewOutlinedBtn,
} from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface ISuperannuationFormProps {
  initialValues: IUpdateContactFieldsNew;
  refetch: any;
  updateContactApi: any;
  profilePicture: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function SuperannuationForm(props: ISuperannuationFormProps) {
  const {
    initialValues,
    refetch,
    updateContactApi,
    setActiveSection,
    profilePicture,
  } = props;

  const { setLoading } = useStore();

  const handleSubmitIndividualForm = (values: IUpdateContactFieldsNew) => {
    const { id, personalBankAccountDetails } = values;

    const { haveSuperannuationAccount, ...restFinancialDetails } =
      personalBankAccountDetails;
    const financialDetailsJSON = { haveSuperannuationAccount };

    updateContactApi({
      variables: {
        payload: {
          id,
          personalBankAccountDetails: {
            ...restFinancialDetails,
            metaData: JSON.stringify(financialDetailsJSON),
          },
          ...(profilePicture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profilePicture) &&
          typeof profilePicture !== "string" && {
            profile_picture: profilePicture,
          }),
      },
      onCompleted: (data: any) => {
        setLoading(false);
        const { updateContactBasicDetails } = data;
        const { status, message } = updateContactBasicDetails;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={handleSubmitIndividualForm}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue, handleBlur, handleSubmit }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Superannuation Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Do you have a Superannuation Account?
              </Typography>
              <RadioGroup
                value={
                  values.personalBankAccountDetails.haveSuperannuationAccount
                }
                onChange={(e) =>
                  setFieldValue(
                    "personalBankAccountDetails.haveSuperannuationAccount",
                    e.target.value
                  )
                }
              >
                <FormControlLabel
                  value="Yes, I know my superannuation fund details"
                  control={
                    <Radio
                      size="small"
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: "#6f6f6f" }} />
                      }
                    />
                  }
                  label={
                    <Typography
                      sx={[
                        styles.field_label,
                        { fontWeight: 400, color: "#00000080" },
                      ]}
                    >
                      Yes, I know my superannuation fund details
                    </Typography>
                  }
                  sx={{ mt: -0.5 }}
                />
                <FormControlLabel
                  value="I have a self managed super fund"
                  control={
                    <Radio
                      size="small"
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: "#6f6f6f" }} />
                      }
                    />
                  }
                  label={
                    <Typography
                      sx={[
                        styles.field_label,
                        { fontWeight: 400, color: "#00000080" },
                      ]}
                    >
                      I have a self managed super fund
                    </Typography>
                  }
                  sx={{ mt: -1 }}
                />
                <FormControlLabel
                  value="No, use my employer's default super fund"
                  control={
                    <Radio
                      size="small"
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: "#6f6f6f" }} />
                      }
                    />
                  }
                  label={
                    <Typography
                      sx={[
                        styles.field_label,
                        { fontWeight: 400, color: "#00000080" },
                      ]}
                    >
                      No, use my employer's default super fund
                    </Typography>
                  }
                  sx={{ mt: -1 }}
                />
              </RadioGroup>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Enter your super fund product name or USI
              </Typography>
              <TextField
                id="personalBankAccountDetails.superannuationFundName"
                value={values.personalBankAccountDetails.superannuationFundName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Member Number</Typography>
              <TextField
                id="personalBankAccountDetails.memberNumber"
                value={values.personalBankAccountDetails.memberNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={[styles.flex_Acenter, { justifyContent: "space-between" }]}
              >
                <BlackActionContainedButton
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#fff",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(2)}
                >
                  Back
                </BlackActionContainedButton>
                <Box sx={[styles.flex_JCfe, { alignItems: "center" }]}>
                  <NewGreenPrimaryContainedButton
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </NewGreenPrimaryContainedButton>
                  <NewOutlinedBtn
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                      color: "#000",
                      ml: 1,
                    }}
                    disableElevation
                    disableRipple
                    onClick={() => setActiveSection(4)}
                  >
                    Next
                  </NewOutlinedBtn>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default SuperannuationForm;
