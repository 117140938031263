import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { CREATE_CONTACT } from "graphql/contacts";
import { CREATE_AGENT } from "graphql/agents";
import { GET_CONTACT_DEPARTMENT } from "graphql/meta";
import { useStore } from "utils/store";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { ICreateContactFieldsNew } from "models/contacts";
import { IArr } from "models/common";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import StepperComponent from "./StepperComponent";
import {
  createAddContactPayload,
  initialValues,
  validationSchema,
} from "./utils";
import { ProjectDetailsStyles as styles } from "../../styles";

interface IAddContactModalProps {
  open: boolean;
  handleClose: () => void;
  refetchContacts: any;
}

const AddContactModal = (props: IAddContactModalProps) => {
  const { open, handleClose, refetchContacts } = props;

  const { setLoading } = useStore();
  const projectId = useParams();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const agentsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [enterDetailsManually, setEnterDetailsManually] = useState(false);
  const [newAgent, setNewAgent] = useState(false);
  const [departmentArr, setDepartmentArr] = useState<IArr[]>([]);
  const [agencyDetailsValue, setAgencyDetailsValue] = useState<any>(null);

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const {} = useQuery(GET_CONTACT_DEPARTMENT, {
    variables: {},
    onCompleted: (data) => {
      const { departments } = data;
      const { status, departments: rawDepts } = departments;
      if (status) {
        setDepartmentArr(rawDepts);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireCreateAgentApi, { loading: creating }] = useMutation(
    CREATE_AGENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { createAgent } = data;
        const { status, message } = createAgent;
        if (status) {
          setAgencyDetailsValue(null);
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireCreateContactApi, { loading }] = useMutation(CREATE_CONTACT, {
    onCompleted: (data) => {
      setLoading(false);
      const { createContact } = data;
      const { status, message } = createContact;
      handleClose();
      if (status) {
        if (
          Boolean(ROLE_PERMISSIONS) &&
          agentsEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]?.AGENTS_EDIT &&
          newAgent &&
          Boolean(agencyDetailsValue)
        ) {
          const {
            address: agencyAddress,
            financialDetails,
            ...restAgencyDetails
          } = agencyDetailsValue;
          const {
            haveSuperannuationAccount: agencyHave,
            ...restAgencyFinancialDetails
          } = financialDetails;
          fireCreateAgentApi({
            variables: {
              payload: {
                ...(typeof agencyAddress !== "string" &&
                  agencyAddress !== null && {
                    address: agencyAddress?.freeformAddress,
                    companyCountry: agencyAddress?.country || "",
                    companyState: agencyAddress?.countrySubdivision || "",
                    companyCity: agencyAddress?.municipality || "",
                    suburb: agencyAddress?.municipalitySubdivision || "",
                    pinCode: agencyAddress?.postalCode || "",
                    street: agencyAddress?.streetName || "",
                  }),
                financialDetails: { ...restAgencyFinancialDetails },
                ...restAgencyDetails,
              },
            },
          });
        }
        toast.success(message);
        refetchContacts();
      } else {
        toast.error(message);
      }
    },
  });

  const handleCreateContact = (values: ICreateContactFieldsNew) => {
    const {
      firstName,
      lastName,
      emailId,
      mobile,
      contactDepartmentId,
      project_role,
    } = values;

    if (enterDetailsManually) {
      const { profile_picture, agencyDetails } = values;
      const contactPayload = createAddContactPayload(values, newAgent);
      if (newAgent) {
        setAgencyDetailsValue(agencyDetails);
      }
      // if (newAgent) {
      //   const {
      //     haveSuperannuationAccount: agencyHave,
      //     ...restAgencyFinancialDetails
      //   } = financialDetails;
      //   fireCreateAgentApi({
      //     variables: {
      //       payload: {
      //         ...(typeof agencyAddress !== "string" &&
      //           agencyAddress !== null && {
      //             address: agencyAddress?.freeformAddress,
      //             companyCountry: agencyAddress?.country || "",
      //             companyState: agencyAddress?.countrySubdivision || "",
      //             companyCity: agencyAddress?.municipality || "",
      //             suburb: agencyAddress?.municipalitySubdivision || "",
      //             street: agencyAddress?.streetName || "",
      //             pinCode: agencyAddress?.postalCode || "",
      //           }),
      //         financialDetails: { ...restAgencyFinancialDetails },
      //         ...restAgencyDetails,
      //       },
      //     },
      //   });
      // }
      fireCreateContactApi({
        variables: {
          payload: { ...contactPayload },
          ...(Boolean(profile_picture) && { profile_picture: profile_picture }),
          shareForm: false,
        },
      });
    } else {
      const profileJSON = { project_role };
      fireCreateContactApi({
        variables: {
          payload: {
            firstName,
            lastName,
            emailId,
            mobile,
            ...(Boolean(contactDepartmentId) && { contactDepartmentId }),
            profile: JSON.stringify(profileJSON),
            projectDetails: [
              {
                projectId: projectId?.project_id,
                role: "",
                artistClassId: "",
                artistTypeId: "",
                characterName: "",
              },
            ],
          },
          shareForm: true,
        },
      });
    }
  };

  useEffect(() => {
    setLoading(creating || loading);
  }, [creating, loading, setLoading]);

  const isValidArrStep1 = (errors: any) => {
    const basicErrs: any = errors;
    return [
      "firstName",
      "lastName",
      "emailId",
      "mobile",
      "contactDepartmentId",
    ].filter((x: string) => basicErrs[x]);
  };

  const anythingTouched = (touched: any) => Object.keys(touched).length > 0;

  const getDigitOnlyPhone = (value: any) =>
    value.toString().replace(/\D/g, "").substring(0, 10);

  const getFormattedPhone = (value: any) => {
    if (!value) return "";

    const phone = getDigitOnlyPhone(value);
    const areaCode = phone.substring(0, 4);
    const middle = phone.substring(4, 7);
    const last = phone.substring(7, 10);

    let formattedPhone = "";
    if (phone.length > 7) {
      formattedPhone = `${areaCode} ${middle} ${last}`;
    } else if (phone.length > 4) {
      formattedPhone = `${areaCode} ${middle}`;
    } else if (phone.length > 0) {
      formattedPhone = `${areaCode}`;
    }

    return formattedPhone;
  };

  return (
    <Formik
      initialValues={initialValues(projectId?.project_id!)}
      validationSchema={validationSchema}
      onSubmit={handleCreateContact}
      enableReinitialize
    >
      {(formikBag) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              height: { xs: 600, sm: 785 },
              borderRadius: "24px",
              // overflow: "hidden"
            },
          }}
        >
          {enterDetailsManually ? (
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <StepperComponent
                formikBag={formikBag}
                handleClose={handleClose}
                setNewAgent={setNewAgent}
              />
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
                position: "relative",
              }}
            >
              <Box sx={{ position: "absolute", right: 15, top: 15 }}>
                <IconButton onClick={handleClose}>
                  <Close sx={{ color: "#00000055" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  px: { xs: 4, sm: 8, md: 10, lg: 12 },
                  pt: { xs: 2, sm: 4, md: 6, lg: 8 },
                  pb: { xs: 2, md: 4 },
                }}
              >
                <Grid
                  container
                  spacing={4}
                  sx={{ display: { xs: "block", sm: "none" } }}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 600,
                        color: colors.primaryBlack,
                      }}
                    >
                      Add Contact
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                      First Name <span style={styles.error_text}>*</span>
                    </Typography>
                    <TextField
                      id="firstName"
                      value={formikBag.values.firstName}
                      onChange={formikBag.handleChange}
                      onBlur={formikBag.handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{
                        style: { ...styles.input_field, fontSize: "14px" },
                      }}
                    />
                    {formikBag.touched.firstName && (
                      <Typography sx={styles.error_text}>
                        {formikBag.errors.firstName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                      Last Name <span style={styles.error_text}>*</span>
                    </Typography>
                    <TextField
                      id="lastName"
                      value={formikBag.values.lastName}
                      onChange={formikBag.handleChange}
                      onBlur={formikBag.handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{
                        style: { ...styles.input_field, fontSize: "14px" },
                      }}
                    />
                    {formikBag.touched.lastName && (
                      <Typography sx={styles.error_text}>
                        {formikBag.errors.lastName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                      Email <span style={styles.error_text}>*</span>
                    </Typography>
                    <TextField
                      id="emailId"
                      value={formikBag.values.emailId}
                      onChange={formikBag.handleChange}
                      onBlur={formikBag.handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{
                        style: { ...styles.input_field, fontSize: "14px" },
                      }}
                    />
                    {formikBag.touched.emailId && (
                      <Typography sx={styles.error_text}>
                        {formikBag.errors.emailId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                      Mobile <span style={styles.error_text}>*</span>
                    </Typography>
                    <TextField
                      value={getFormattedPhone(formikBag.values.mobile)}
                      onChange={(e) => {
                        if (e.target.value.length <= 12) {
                          formikBag.setFieldValue("mobile", e.target.value);
                        }
                      }}
                      onBlur={formikBag.handleBlur("mobile")}
                      size="small"
                      placeholder="0444 444 444"
                      fullWidth
                      inputProps={{
                        style: {
                          ...{ ...styles.input_field, fontSize: "14px" },
                          paddingLeft: "8px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ mr: 0 }}>
                            <Box
                              sx={[
                                styles.flex_Acenter,
                                {
                                  backgroundColor: "#e6e6e640",
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                  py: "3px",
                                  pl: 2,
                                  ml: -1.75,
                                },
                              ]}
                            >
                              <img
                                src={images.AUS_FLAG}
                                alt="flag"
                                height={30}
                              />
                              <Typography sx={{ ml: 1, fontSize: "14px" }}>
                                +61
                              </Typography>
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {formikBag.touched.mobile && (
                      <Typography sx={styles.error_text}>
                        {formikBag.errors.mobile}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                      Department <span style={styles.error_text}>*</span>
                    </Typography>
                    <Autocomplete
                      id="contactDepartmentId"
                      value={
                        departmentArr.find(
                          (x) => x.id === formikBag.values.contactDepartmentId
                        ) || {
                          id: "",
                          name: "",
                        }
                      }
                      onChange={(_, newValue) => {
                        formikBag.setFieldValue(
                          "contactDepartmentId",
                          newValue.id
                        );
                      }}
                      onBlur={formikBag.handleBlur}
                      sx={styles.input_field}
                      size="small"
                      disableClearable
                      fullWidth
                      options={departmentArr}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          onBlur={formikBag.handleBlur}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              fontSize: "14px",
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                    />
                    {formikBag.touched.contactDepartmentId && (
                      <Typography sx={styles.error_text}>
                        {formikBag.errors.contactDepartmentId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                      Project Role
                    </Typography>
                    <TextField
                      id="project_role"
                      value={formikBag.values.project_role}
                      onChange={formikBag.handleChange}
                      onBlur={formikBag.handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{
                        style: { ...styles.input_field, fontSize: "14px" },
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <NewGreenPrimaryContainedButton
                        disableElevation
                        variant="contained"
                        size="small"
                        sx={{
                          width: 260,
                          fontSize: "14px",
                          mt: 3,
                          borderRadius: "24px",
                          py: 1.25,
                          fontWeight: 600,
                        }}
                        disabled={
                          isValidArrStep1(formikBag.errors).length > 0 ||
                          !anythingTouched(formikBag.touched)
                        }
                        onClick={() => formikBag.handleSubmit()}
                      >
                        Send Onboarding Link
                      </NewGreenPrimaryContainedButton>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: colors.primaryBlack,
                          textDecoration: "underline",
                          textAlign: "center",
                          lineHeight: "20px",
                          cursor: "pointer",
                          mt: 1.5,
                          mb: 1,
                        }}
                        onClick={() => setEnterDetailsManually(true)}
                      >
                        Enter details manually
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "22px",
                          fontWeight: 600,
                          color: colors.primaryBlack,
                        }}
                      >
                        Add Contact
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={[styles.field_label, { fontSize: "14px" }]}
                      >
                        First Name <span style={styles.error_text}>*</span>
                      </Typography>
                      <TextField
                        id="firstName"
                        value={formikBag.values.firstName}
                        onChange={formikBag.handleChange}
                        onBlur={formikBag.handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: { ...styles.input_field, fontSize: "14px" },
                        }}
                      />
                      {formikBag.touched.firstName && (
                        <Typography sx={styles.error_text}>
                          {formikBag.errors.firstName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={[styles.field_label, { fontSize: "14px" }]}
                      >
                        Last Name <span style={styles.error_text}>*</span>
                      </Typography>
                      <TextField
                        id="lastName"
                        value={formikBag.values.lastName}
                        onChange={formikBag.handleChange}
                        onBlur={formikBag.handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: { ...styles.input_field, fontSize: "14px" },
                        }}
                      />
                      {formikBag.touched.lastName && (
                        <Typography sx={styles.error_text}>
                          {formikBag.errors.lastName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={[styles.field_label, { fontSize: "14px" }]}
                      >
                        Email <span style={styles.error_text}>*</span>
                      </Typography>
                      <TextField
                        id="emailId"
                        value={formikBag.values.emailId}
                        onChange={formikBag.handleChange}
                        onBlur={formikBag.handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: { ...styles.input_field, fontSize: "14px" },
                        }}
                      />
                      {formikBag.touched.emailId && (
                        <Typography sx={styles.error_text}>
                          {formikBag.errors.emailId}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={[styles.field_label, { fontSize: "14px" }]}
                      >
                        Mobile <span style={styles.error_text}>*</span>
                      </Typography>
                      <TextField
                        value={getFormattedPhone(formikBag.values.mobile)}
                        onChange={(e) => {
                          if (e.target.value.length <= 12) {
                            formikBag.setFieldValue("mobile", e.target.value);
                          }
                        }}
                        onBlur={formikBag.handleBlur("mobile")}
                        size="small"
                        placeholder="0444 444 444"
                        fullWidth
                        inputProps={{
                          style: {
                            ...{ ...styles.input_field, fontSize: "14px" },
                            paddingLeft: "8px",
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 0 }}>
                              <Box
                                sx={[
                                  styles.flex_Acenter,
                                  {
                                    backgroundColor: "#e6e6e640",
                                    borderTopLeftRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                    py: "3px",
                                    pl: 2,
                                    ml: -1.75,
                                  },
                                ]}
                              >
                                <img
                                  src={images.AUS_FLAG}
                                  alt="flag"
                                  height={30}
                                />
                                <Typography sx={{ ml: 1, fontSize: "14px" }}>
                                  +61
                                </Typography>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formikBag.touched.mobile && (
                        <Typography sx={styles.error_text}>
                          {formikBag.errors.mobile}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography
                        sx={[styles.field_label, { fontSize: "14px" }]}
                      >
                        Department <span style={styles.error_text}>*</span>
                      </Typography>
                      <Autocomplete
                        id="contactDepartmentId"
                        value={
                          departmentArr.find(
                            (x) => x.id === formikBag.values.contactDepartmentId
                          ) || {
                            id: "",
                            name: "",
                          }
                        }
                        onChange={(_, newValue) => {
                          formikBag.setFieldValue(
                            "contactDepartmentId",
                            newValue.id
                          );
                        }}
                        onBlur={formikBag.handleBlur}
                        sx={styles.input_field}
                        size="small"
                        disableClearable
                        fullWidth
                        options={departmentArr}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            onBlur={formikBag.handleBlur}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                fontSize: "14px",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                      {formikBag.touched.contactDepartmentId && (
                        <Typography sx={styles.error_text}>
                          {formikBag.errors.contactDepartmentId}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography
                        sx={[styles.field_label, { fontSize: "14px" }]}
                      >
                        Project Role
                      </Typography>
                      <TextField
                        id="project_role"
                        value={formikBag.values.project_role}
                        onChange={formikBag.handleChange}
                        onBlur={formikBag.handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: { ...styles.input_field, fontSize: "14px" },
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <NewGreenPrimaryContainedButton
                          disableElevation
                          variant="contained"
                          size="small"
                          sx={{
                            width: 260,
                            fontSize: "14px",
                            mt: 3,
                            borderRadius: "24px",
                            py: 1.25,
                            fontWeight: 600,
                          }}
                          disabled={
                            isValidArrStep1(formikBag.errors).length > 0 ||
                            !anythingTouched(formikBag.touched)
                          }
                          onClick={() => formikBag.handleSubmit()}
                        >
                          Send Onboarding Link
                        </NewGreenPrimaryContainedButton>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: colors.primaryBlack,
                            textDecoration: "underline",
                            textAlign: "center",
                            lineHeight: "20px",
                            cursor: "pointer",
                            mt: 1.5,
                            mb: 1,
                          }}
                          onClick={() => setEnterDetailsManually(true)}
                        >
                          Enter details manually
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Dialog>
      )}
    </Formik>
  );
};

export default AddContactModal;
