import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { RouteNames } from "routes/routeNames";
import { GlobalLabels } from "common/AppConstants";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { images } from "assets/images";

import { LoginSuccessStyles as styles } from "./styles";

const SuccessAcknowledgement = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={[
        styles.content_center,
        {
          width: { xs: "100%", sm: "75%", md: "50%" },
          py: { xs: 4, sm: 4, md: 0 },
          px: { xs: 4, sm: 8, md: 0 },
          backgroundColor: { xs: "none", sm: "#fff", md: "none" },
          borderRadius: { xs: 0, sm: "15px", md: 0 },
          mx: { xs: 0, sm: 4, md: 0 },
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <img src={images.LOGO_BALLS} alt="logo" width="100" />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mt: { xs: 0, sm: 1, md: 2, lg: 3 } }}
        >
          <Typography sx={[styles.success_text, { textAlign: "justify" }]}>
            Welcome!
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mt: { xs: 0, sm: 1, md: 2, lg: 3 } }}
        >
          <Typography sx={[styles.tc_text, { textAlign: "justify" }]}>
            By clicking the Continue button, you acknowledge and agree as
            follows:
            <br />
            <br />
            <ol>
              <li style={styles.tc_text}>
                <Typography sx={styles.tc_text}>
                  You have read and understood the terms and conditions that
                  apply to:
                </Typography>
                <ol type="a">
                  <li style={styles.tc_text}>
                    <Typography sx={styles.tc_text}>
                      your use of Finka (the{" "}
                      <a href="https://www.finka.me/terms-of-service">
                        Terms of Use
                      </a>
                      ); and
                    </Typography>
                  </li>
                  <li style={styles.tc_text}>
                    <Typography sx={styles.tc_text}>
                      your subscription to all services supplied by Finka
                      Solutions Pty. Ltd (the{" "}
                      <a href="https://www.finka.me/terms-of-service">
                        Subscription Terms &amp; Conditions
                      </a>
                      )(collectively the Terms of Use).
                    </Typography>
                  </li>
                </ol>
              </li>
              <li style={styles.tc_text}>
                <Typography sx={styles.tc_text}>
                  You agree to be bound by the Terms of Use. If you do not agree
                  to these Terms of Use, do not click this box or register as a
                  user or subscribe to the finka platform.
                </Typography>
              </li>
              <li style={styles.tc_text}>
                <Typography sx={styles.tc_text}>
                  You acknowledge that you have read, understood and consent to
                  the{" "}
                  <a href="https://www.finka.me/terms-of-service">
                    Privacy Policy
                  </a>{" "}
                  that applies to all users and subscribers of finka.
                </Typography>
              </li>
              <li style={styles.tc_text}>
                <Typography sx={styles.tc_text}>
                  You acknowledge and agree that neither Finka Solutions Pty.
                  Ltd or the finka platform (collectively "finka") provide legal
                  services to you, nor is finka a substitute for legal advice.
                  Only a lawyer can advise you or your organization in relation
                  to the laws that apply to your particular situation. finka
                  makes no guarantee or warranty as to the accuracy or
                  applicability of the information contained on its website, its
                  platform, its template agreements or any of its documents.
                </Typography>
              </li>
            </ol>
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={[styles.content_center, { mt: { xs: 0, sm: 1, md: 2, lg: 3 } }]}
        >
          <Box sx={{ width: "100%" }}>
            <NewGreenPrimaryContainedButton
              disableElevation
              variant="contained"
              size="small"
              sx={styles.login_btn}
              onClick={() => navigate(RouteNames.TWO_FA_SCAN)}
            >
              {GlobalLabels.CONTINUE}
            </NewGreenPrimaryContainedButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessAcknowledgement;
