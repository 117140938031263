import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query getCompanies(
    $filter: CompanyFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getCompanies(filter: $filter, page: $page, limit: $limit, sorted: $sorted) {
      status
      message
      count
      companies {
        id
        name
        abnCode
        website
        noOfEmployees
        employeeManagingContracts
        industry {
          id
          name
        }
        street
        city
        state
        country
        pincode
        client {
          id
          fullName
          mobile
          email
          designation
        }
      }
    }
  }
`;

export const GET_CLIENT_DETAILS = gql`
  query getCompany($id: String!) {
    getCompany(id: $id) {
      status
      message
      company {
        id
        name
        abnCode
        website
        noOfEmployees
        employeeManagingContracts
        industry {
          id
          name
        }
        address
        street
        suburb
        city
        state
        country
        pincode
        client {
          id
          fullName
          mobile
          email
          designation
        }
      }
    }
  }
`;
