import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocationOnOutlined,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { FormikProps } from "formik";
import { useLazyQuery } from "@apollo/client";

import { BlackActionContainedButton } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { IGeoCoding } from "models/common";
import { IAgentFields } from "models/agents";
import { GET_ADDRESS_AUTOCOMPLETE } from "graphql/meta";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";

interface ICompanyDetailsFormProps {
  formikBag: FormikProps<IAgentFields>;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function CompanyDetailsForm(props: ICompanyDetailsFormProps) {
  const { formikBag, setActiveSection } = props;
  const { values, handleChange, setFieldValue, handleBlur, touched, errors } =
    formikBag;

  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
  const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
  const [addressSearchText, setAddressSearchText] = useState("");

  const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
    onCompleted: (data) => {
      const { autocompleteAddress } = data;
      setAddressArr(autocompleteAddress);
      if (
        autocompleteAddress.length > 0 &&
        Boolean(values.address) &&
        typeof values.address === "string"
      ) {
        setFieldValue("address", autocompleteAddress[0]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (addressSearchText) {
      getGeoCodeAddress({ variables: { filter: addressSearchText } });
    }
  }, [addressSearchText, getGeoCodeAddress]);

  useEffect(() => {
    if (Boolean(values.address)) {
      setAddressSearchText((values.address as IGeoCoding).freeformAddress!);
    }
  }, [values.address]);

  const isValidArrStep1 = (errors: any) => {
    const basicErrs: any = errors;
    return [
      "companyName",
      "spocName",
      "spocEmailId",
      "spocMobile",
      "website",
    ].filter((x: string) => basicErrs[x]);
  };

  const anythingTouched = (touched: any) => Object.keys(touched).length > 0;

  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 14 },
        px: 4,
        pb: 1.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "flex", sm: "none" } }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              position: "relative",
            }}
          >
            {props.formikBag.values.profile_picture ? (
              <Avatar
                sx={{ height: 55, width: 55, cursor: "pointer" }}
                src={
                  typeof props.formikBag.values.profile_picture === "string"
                    ? props.formikBag.values.profile_picture
                    : URL.createObjectURL(
                        props.formikBag.values.profile_picture
                      )
                }
              />
            ) : (
              <Avatar
                sx={{
                  height: 55,
                  width: 55,
                  cursor: "pointer",
                }}
              />
            )}
            <Box sx={{ position: "absolute", bottom: -10 }}>
              <IconButton
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  border: "1px #e6e6e6 solid",
                  height: 20,
                  width: 20,
                }}
                onClick={() => setOpenImageCropUploader(true)}
              >
                <ModeEditOutlineOutlined sx={{ fontSize: 12 }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Company Name <span style={styles.error_text}>*</span>
          </Typography>
          <TextField
            id="companyName"
            value={values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched?.companyName && (
            <Typography sx={styles.error_text}>
              {errors?.companyName}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Agent Full Name <span style={styles.error_text}>*</span>
          </Typography>
          <TextField
            id="spocName"
            value={values.spocName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched?.spocName && (
            <Typography sx={styles.error_text}>{errors?.spocName}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Email <span style={styles.error_text}>*</span>
          </Typography>
          <TextField
            id="spocEmailId"
            value={values.spocEmailId}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched?.spocEmailId && (
            <Typography sx={styles.error_text}>
              {errors?.spocEmailId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Mobile <span style={styles.error_text}>*</span>
          </Typography>
          <PhoneNumberInput
            value={values.spocMobile}
            onChange={(e) => {
              setFieldValue("spocMobile", e.target.value);
            }}
            onBlur={handleBlur("spocMobile")}
          />
          {touched?.spocMobile && (
            <Typography sx={styles.error_text}>{errors?.spocMobile}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Address</Typography>
          <Autocomplete
            id="address"
            getOptionLabel={(option) => option.freeformAddress}
            value={
              Boolean(values.address) && typeof values.address !== "string"
                ? values.address!
                : undefined
            }
            onChange={(event, newValue) => {
              setFieldValue("address", newValue);
            }}
            inputValue={addressSearchText}
            onInputChange={(event: any, newInputValue) => {
              if (Boolean(event?.target)) {
                setAddressSearchText(newInputValue);
              }
            }}
            options={addressArr}
            popupIcon={
              <LocationOnOutlined
                fontSize="small"
                sx={{ color: "#00000050" }}
              />
            }
            sx={[
              styles.input_field,
              { "& .MuiAutocomplete-popupIndicator": { transform: "none" } },
            ]}
            size="small"
            disableClearable
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...styles.input_field,
                    backgroundColor: "transparent",
                  },
                }}
              />
            )}
            noOptionsText={
              <Typography sx={{ fontSize: "13px", color: "#0f0f0f80" }}>
                {addressSearchText ? "No match found" : ""}
              </Typography>
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Website</Typography>
          <TextField
            id="website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched?.website && (
            <Typography sx={styles.error_text}>{errors?.website}</Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
            <BlackActionContainedButton
              variant="contained"
              size="small"
              sx={{
                borderRadius: "20px",
                ml: 1,
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
              }}
              disableElevation
              disableRipple
              disabled={
                isValidArrStep1(errors).length > 0 || !anythingTouched(touched)
              }
              onClick={() => setActiveSection(1)}
            >
              Next
            </BlackActionContainedButton>
          </Box>
        </Box>
      </Box>
      {openImageCropUploader && (
        <ProfilePhotoCircularCropper
          open={openImageCropUploader}
          setOpen={setOpenImageCropUploader}
          width={55}
          imgSrc={props.formikBag.values.profile_picture}
          setImgSrc={(file: any) =>
            props.formikBag.setFieldValue("profile_picture", file)
          }
        />
      )}
    </Box>
  );
}

export default CompanyDetailsForm;
