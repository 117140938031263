import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  CheckBox,
  LocationOnOutlined,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { FormikProps } from "formik";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";

import { GET_ADDRESS_AUTOCOMPLETE, GET_CONTACT_DEPARTMENT } from "graphql/meta";
import { ICreateContactFieldsNew } from "models/contacts";
import { IArr, IGeoCoding } from "models/common";
import { BlackActionContainedButton } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { GenderArr } from "utils/constants";
import DatePicker from "common/DatePickerInput";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";

interface IPersonDetailsFormProps {
  formikBag: FormikProps<ICreateContactFieldsNew>;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function PersonalDetailsForm(props: IPersonDetailsFormProps) {
  const { formikBag, setActiveSection } = props;
  const { values, handleChange, setFieldValue, handleBlur, touched, errors } =
    formikBag;

  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
  const [departmentArr, setDepartmentArr] = useState<IArr[]>([]);
  const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
  const [addressSearchText, setAddressSearchText] = useState("");

  const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
    onCompleted: (data) => {
      const { autocompleteAddress } = data;
      setAddressArr(autocompleteAddress);
      if (
        autocompleteAddress.length > 0 &&
        Boolean(values.address) &&
        typeof values.address === "string"
      ) {
        setFieldValue("address", autocompleteAddress[0]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {} = useQuery(GET_CONTACT_DEPARTMENT, {
    variables: {},
    onCompleted: (data) => {
      const { departments } = data;
      const { status, departments: rawDepts } = departments;
      if (status) {
        setDepartmentArr(rawDepts);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (addressSearchText) {
      getGeoCodeAddress({ variables: { filter: addressSearchText } });
    }
  }, [addressSearchText, getGeoCodeAddress]);

  useEffect(() => {
    if (Boolean(values.address)) {
      setAddressSearchText((values.address as IGeoCoding).freeformAddress!);
    }
  }, [values.address]);

  const isValidArrStep1 = (errors: any) => {
    const basicErrs: any = errors;
    return [
      "firstName",
      "lastName",
      "emailId",
      "mobile",
      "contactDepartmentId",
    ].filter((x: string) => basicErrs[x]);
  };

  const anythingTouched = (touched: any) => Object.keys(touched).length > 0;

  return (
    <Box sx={{ pt: 2, px: 4, pb: 1.5 }}>
      <Grid container spacing={3}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "flex", sm: "none" } }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              position: "relative",
            }}
          >
            {props.formikBag.values.profile_picture ? (
              <Avatar
                sx={{ height: 70, width: 70, cursor: "pointer" }}
                src={
                  typeof props.formikBag.values.profile_picture === "string"
                    ? props.formikBag.values.profile_picture
                    : URL.createObjectURL(
                        props.formikBag.values.profile_picture
                      )
                }
              />
            ) : (
              <Avatar
                sx={{
                  height: 70,
                  width: 70,
                  cursor: "pointer",
                }}
              />
            )}
            <Box sx={{ position: "absolute", bottom: -10 }}>
              <IconButton
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  border: "1px #e6e6e6 solid",
                  height: 25,
                  width: 25,
                }}
                onClick={() => setOpenImageCropUploader(true)}
              >
                <ModeEditOutlineOutlined sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>
            First Name <span style={styles.error_text}>*</span>
          </Typography>
          <TextField
            id="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.firstName && (
            <Typography sx={styles.error_text}>{errors.firstName}</Typography>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>
            Last Name <span style={styles.error_text}>*</span>
          </Typography>
          <TextField
            id="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.lastName && (
            <Typography sx={styles.error_text}>{errors.lastName}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Email <span style={styles.error_text}>*</span>
          </Typography>
          <TextField
            id="emailId"
            value={values.emailId}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.emailId && (
            <Typography sx={styles.error_text}>{errors.emailId}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Mobile <span style={styles.error_text}>*</span>
          </Typography>
          <PhoneNumberInput
            value={values.mobile}
            onChange={(e) => {
              setFieldValue("mobile", e.target.value);
            }}
            onBlur={handleBlur("mobile")}
          />
          {touched.mobile && (
            <Typography sx={styles.error_text}>{errors.mobile}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Address</Typography>
          <Autocomplete
            id="address"
            getOptionLabel={(option) => option.freeformAddress}
            value={
              Boolean(values.address) && typeof values.address !== "string"
                ? values.address!
                : undefined
            }
            onChange={(event, newValue) => {
              setFieldValue("address", newValue);
            }}
            inputValue={addressSearchText}
            onInputChange={(event: any, newInputValue) => {
              if (Boolean(event?.target)) {
                setAddressSearchText(newInputValue);
              }
            }}
            options={addressArr}
            popupIcon={
              <LocationOnOutlined
                fontSize="small"
                sx={{ color: "#00000050" }}
              />
            }
            sx={[
              styles.input_field,
              { "& .MuiAutocomplete-popupIndicator": { transform: "none" } },
            ]}
            size="small"
            disableClearable
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...styles.input_field,
                    backgroundColor: "transparent",
                  },
                }}
              />
            )}
            noOptionsText={
              <Typography sx={{ fontSize: "13px", color: "#0f0f0f80" }}>
                {addressSearchText ? "No match found" : ""}
              </Typography>
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Date of Birth</Typography>
          <DatePicker
            textValue={
              Boolean(values?.dob)
                ? moment(values.dob).format("DD MMMM YYYY")
                : ""
            }
            value={Boolean(values.dob) ? new Date(values.dob) : null}
            onChange={(newValue: any) => {
              setFieldValue("dob", moment(newValue).format("YYYY-MM-DD"));
            }}
            onOpen={undefined}
            disabledFuture
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>Nationality</Typography>
          <TextField
            id="nationality"
            value={values.nationality}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>Gender</Typography>
          <Autocomplete
            id="gender"
            value={
              GenderArr.find((x) => x.id === values.gender) || {
                id: "",
                name: "",
              }
            }
            onChange={(_, newValue) => {
              setFieldValue("gender", newValue.id);
            }}
            sx={styles.input_field}
            size="small"
            disableClearable
            fullWidth
            options={GenderArr}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...styles.input_field,
                    backgroundColor: "transparent",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>
            Department <span style={styles.error_text}>*</span>
          </Typography>
          <Autocomplete
            id="contactDepartmentId"
            value={
              departmentArr.find(
                (x) => x.id === values.contactDepartmentId
              ) || {
                id: "",
                name: "",
              }
            }
            onChange={(_, newValue) => {
              setFieldValue("contactDepartmentId", newValue.id);
            }}
            onBlur={handleBlur}
            sx={styles.input_field}
            size="small"
            disableClearable
            fullWidth
            options={departmentArr}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                onBlur={handleBlur}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...styles.input_field,
                    backgroundColor: "transparent",
                  },
                }}
              />
            )}
          />
          {touched.contactDepartmentId && (
            <Typography sx={styles.error_text}>
              {errors.contactDepartmentId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>Project Role</Typography>
          <TextField
            id="project_role"
            value={values.project_role}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>The Everyone Project</Typography>
          <Typography
            sx={[styles.field_label, { fontWeight: 400, fontSize: "12px" }]}
          >
            The Production Company may need to share your name, role, and
            contact details with The Everyone Project.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.shareConsent}
                onChange={(e) =>
                  setFieldValue("shareConsent", e.target.checked)
                }
                checkedIcon={
                  <CheckBox fontSize="small" sx={{ color: "#6f6f6f" }} />
                }
                size="small"
              />
            }
            label={
              <Typography
                sx={[
                  styles.field_label,
                  {
                    fontWeight: 400,
                    fontSize: "12px",
                  },
                ]}
              >
                Yes, I consent to the Production Company sharing my details
                (unchecked box implies non-consent to share my details).
              </Typography>
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={styles.flex_JCfe}>
            <BlackActionContainedButton
              variant="contained"
              size="small"
              sx={{
                borderRadius: "20px",
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
              }}
              disableElevation
              disableRipple
              disabled={
                isValidArrStep1(errors).length > 0 || !anythingTouched(touched)
              }
              onClick={() => setActiveSection(1)}
            >
              Next
            </BlackActionContainedButton>
          </Box>
        </Grid>
      </Grid>
      {openImageCropUploader && (
        <ProfilePhotoCircularCropper
          open={openImageCropUploader}
          setOpen={setOpenImageCropUploader}
          width={70}
          imgSrc={props.formikBag.values.profile_picture}
          setImgSrc={(file: any) =>
            props.formikBag.setFieldValue("profile_picture", file)
          }
        />
      )}
    </Box>
  );
}

export default PersonalDetailsForm;
