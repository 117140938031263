import { IGeoCoding } from "models/common";

export interface IAdminFields {
  id: string;
  fullName: string;
  designation: string;
  email: string;
  mobile: string;
  companyName: string;
  abnCode: string;
  website: string;
  noOfEmployees: string;
  employeeManagingContracts: string;
  industry: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  suburb: string;
  address: string | null | IGeoCoding;
}

export const initialValues: IAdminFields = {
  id: "",
  address: null,
  fullName: "",
  designation: "",
  email: "",
  mobile: "",
  companyName: "",
  abnCode: "",
  website: "",
  noOfEmployees: "",
  employeeManagingContracts: "",
  industry: "",
  street: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  suburb: "",
};
