import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  Close,
  FiberManualRecord,
  KeyboardArrowDown,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { IContact } from "models/contacts";
import { UPSERT_INDEX_ITEM_DOCUMENT } from "graphql/indexes";
import { GET_CONTACT_ARR } from "graphql/contacts";
import { GET_TEMPLATE_ARR } from "graphql/templates";
import { GET_SPV_COMPANIES_DROPDOWN } from "graphql/projects";
import { useStore } from "utils/store";
import { useContactCreateModal } from "hooks/useCreateContactModal";
import { RouteNames } from "routes/routeNames";
import { BlackActionContainedButton, BlackTextButton } from "common/Buttons";
import { ContractStatusMapping } from "utils/constants";
import { fetchContractStatusUpdateArr } from "utils/helpers";
import { GET_CONTRACT_FILE, UPDATE_DOCUMENT_STATUS } from "graphql/contracts";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface ISPVCompanyArr extends IArr {
  companyType: string;
}

interface ICreateContractFields {
  contactId: string;
  templateId: string;
  inhouseCompanyId: string;
}

interface ICreateModalProps extends IModalProps {
  selectedIndexItem: string;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  indexTempTypes: string[];
  refetchTableData: any;
  nestedIdArr: string[];
  reUploadDocId: string;
  setReUploadDocId: Dispatch<SetStateAction<string>>;
}

const CreateModal = (props: ICreateModalProps) => {
  const {
    open,
    setOpen,
    selectedIndexItem,
    setSelectedIndexItem,
    indexTempTypes,
    refetchTableData,
    nestedIdArr,
    reUploadDocId,
    setReUploadDocId,
  } = props;

  const { setLoading } = useStore();
  const navigate = useNavigate();
  const params = useParams();
  const createContactHook = useContactCreateModal();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const contactsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CONTACTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [initialValues, setInitialValues] = useState<ICreateContractFields>({
    contactId: "",
    inhouseCompanyId: "",
    templateId: "",
  });
  const [contactsArr, setContactsArr] = useState<IContact[]>([]);
  const [templateArr, setTemplateArr] = useState<IArr[]>([]);
  const [inhouseCompanyArr, setInhouseCompanyArr] = useState<ISPVCompanyArr[]>(
    []
  );
  const [documentStatus, setDocumentStatus] = useState("APPROVAL_PENDING");
  const [openCnctStatusMenu, setOpenCnctStatusMenu] = useState(false);
  const [cnctStatusAnchor, setCnctStatusAnchor] = useState<HTMLElement | null>(
    null
  );
  const [cnctStatusUpdateArr, setCnctStatusUpdateArr] = useState<IArr[]>([]);

  const handleCnctStatusClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setCnctStatusUpdateArr(fetchContractStatusUpdateArr(documentStatus));
    setOpenCnctStatusMenu(true);
    setCnctStatusAnchor(e.currentTarget);
  };

  const handleCnctStatusClose = () => {
    setOpenCnctStatusMenu(false);
    setCnctStatusAnchor(null);
  };

  const handleClose = () => {
    setSelectedIndexItem("");
    setReUploadDocId("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const { loading: gettingArr } = useQuery(GET_CONTACT_ARR, {
    variables: { filter: { contactProjects__projectId: params?.project_id } },
    onCompleted: (data) => {
      // setLoading(false);
      const { getAllContacts } = data;
      const { status, contacts } = getAllContacts;
      if (status) {
        setContactsArr(contacts);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingTempArr } = useQuery(GET_TEMPLATE_ARR, {
    variables: {
      filter: { template_type_id__in: indexTempTypes, template_status: 10 },
    },
    onCompleted: (data) => {
      // setLoading(false);
      const { getAllContractTemplates } = data;
      const { status, contractTemplates } = getAllContractTemplates;
      if (status) {
        setTemplateArr(contractTemplates);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingCompanyArr } = useQuery(GET_SPV_COMPANIES_DROPDOWN, {
    variables: { project_id: params?.project_id },
    onCompleted: (data) => {
      // setLoading(false);
      const { spvCompanies } = data;
      const { status, spvCompanies: rawSpvCompanies } = spvCompanies;
      if (status) {
        setInhouseCompanyArr(
          rawSpvCompanies.map((x: any) => ({
            id: x.id,
            name: x.companyName,
            companyType: x.companyType,
          }))
        );
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingDetails, refetch } = useQuery(GET_CONTRACT_FILE, {
    variables: { payload: { documentId: reUploadDocId } },
    skip: !Boolean(reUploadDocId),
    onCompleted: (data) => {
      // setLoading(false);
      const { getDocumentFile } = data;
      const { status, document } = getDocumentFile;
      if (status) {
        setDocumentStatus(document?.documentStatus || "APPROVAL_PENDING");
        setInitialValues({
          contactId: document?.contact?.id || "",
          inhouseCompanyId: document?.inhouseCompany?.id || "",
          templateId: document?.template?.id || "",
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireCreateContractApi, { loading: creating }] = useMutation(
    UPSERT_INDEX_ITEM_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { linkRegisterItemDocument } = data;
        const { status, message, id } = linkRegisterItemDocument;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
          handleClose();
          navigate(
            `${RouteNames.PROJECTS}/${params?.project_id}${RouteNames.CONTRACT}/${id}`
          );
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireUpdateCnctStatusApi, { loading: updatingStatus }] = useMutation(
    UPDATE_DOCUMENT_STATUS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocumentStatus } = data;
        const { status, message } = updateDocumentStatus;
        if (status) {
          toast.success(message, { delay: 10 });
          refetch();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleCreateContract = (values: ICreateContractFields) => {
    if (Boolean(reUploadDocId)) {
      handleClose();
    } else {
      fireCreateContractApi({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: nestedIdArr[nestedIdArr.length - 1],
            registerItemId: selectedIndexItem,
            isUpload: false,
            documentType: "CONTRACTS",
            ...values,
          },
        },
      });
    }
  };

  useEffect(() => {
    setLoading(
      creating ||
        gettingArr ||
        gettingCompanyArr ||
        gettingDetails ||
        gettingTempArr ||
        updatingStatus
    );
  }, [
    creating,
    gettingArr,
    gettingCompanyArr,
    gettingDetails,
    gettingTempArr,
    setLoading,
    updatingStatus,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleCreateContract}
      validationSchema={Yup.object().shape({
        contactId: Yup.string().required("Please select the party name"),
        templateId: Yup.string().required("Please select a template"),
        inhouseCompanyId: Yup.string().required(
          "Please select an in-house company"
        ),
      })}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth={"sm"}
          PaperProps={{ sx: { borderRadius: "24px" } }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ pt: 6, px: 6, pb: 3, position: "relative" }}>
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose}>
                    <Close sx={{ color: "#00000055" }} fontSize="small" />
                  </IconButton>
                </Box>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      First Party <span style={styles.error_text}>*</span>
                    </Typography>
                    <Autocomplete
                      disabled={Boolean(reUploadDocId)}
                      id="inhouseCompanyId"
                      value={
                        inhouseCompanyArr.find((x) =>
                          values.inhouseCompanyId.includes(x.id)
                        ) || { id: "", name: "", companyType: "" }
                      }
                      onChange={(_, newValue) =>
                        setFieldValue("inhouseCompanyId", newValue.id)
                      }
                      sx={styles.input_field}
                      size="small"
                      disableClearable
                      fullWidth
                      options={inhouseCompanyArr}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props1, opt, _, __) => (
                        <Typography
                          {...props1}
                          sx={{ fontSize: "14px", fontWeight: 600 }}
                        >
                          {opt.name}{" "}
                          <span style={{ color: "#00000080", fontWeight: 400 }}>
                            &nbsp;
                            {`(${
                              opt.companyType === "PRODUCTION"
                                ? "Production Company"
                                : "Rights Holding Company"
                            })`}
                          </span>
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                    />
                    {touched?.inhouseCompanyId && errors?.inhouseCompanyId && (
                      <Typography sx={styles.error_text}>
                        {errors?.inhouseCompanyId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Second Party <span style={styles.error_text}>*</span>
                    </Typography>
                    <Autocomplete
                      disabled={Boolean(reUploadDocId)}
                      id="contactId"
                      value={
                        contactsArr.find((x) =>
                          values.contactId.includes(x.id)
                        ) || {
                          id: "",
                          fullName: "",
                          department: "",
                          emailId: "",
                          mobile: "",
                          createdDate: "",
                          profile: null,
                          profile_picture: null,
                          contactDepartment: { id: "", name: "" },
                        }
                      }
                      onChange={(_, newValue) =>
                        setFieldValue("contactId", newValue.id)
                      }
                      sx={styles.input_field}
                      size="small"
                      disableClearable
                      fullWidth
                      options={contactsArr}
                      getOptionLabel={(option) => option.fullName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                      noOptionsText={
                        Boolean(ROLE_PERMISSIONS) &&
                        contactsEditIndex !== undefined &&
                        ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]
                          ?.CONTACTS_EDIT ? (
                          <BlackTextButton
                            variant="text"
                            startIcon={<Add sx={{ color: "#000" }} />}
                            onClick={() => {
                              createContactHook.onOpen();
                              navigate(
                                `${RouteNames.PROJECTS}/${params.project_id}/project-contacts`
                              );
                            }}
                            disableElevation
                            disableRipple
                          >
                            Add New Contact
                          </BlackTextButton>
                        ) : (
                          "No Contacts"
                        )
                      }
                      renderOption={(props1, opt, _, __) => (
                        <li
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          {...props1}
                        >
                          <Typography textAlign="center">
                            {opt.fullName}
                          </Typography>
                          <Typography
                            sx={[styles.field_label, { fontSize: "13px" }]}
                          >{`(${opt.emailId})`}</Typography>
                        </li>
                      )}
                    />
                    {touched?.contactId && errors?.contactId && (
                      <Typography sx={styles.error_text}>
                        {errors?.contactId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Template <span style={styles.error_text}>*</span>
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={Boolean(reUploadDocId) ? 6 : 12}
                        md={Boolean(reUploadDocId) ? 6 : 12}
                        sm={12}
                        xs={12}
                      >
                        <Autocomplete
                          disabled={Boolean(reUploadDocId)}
                          id="templateId"
                          value={
                            templateArr.find((x) =>
                              values.templateId.includes(x.id)
                            ) || { id: "", name: "" }
                          }
                          onChange={(_, newValue) =>
                            setFieldValue("templateId", newValue.id)
                          }
                          sx={styles.input_field}
                          size="small"
                          disableClearable
                          fullWidth
                          options={templateArr}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  ...styles.input_field,
                                  backgroundColor: "transparent",
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {Boolean(reUploadDocId) && (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box
                            sx={[styles.flex_JCsb, { alignItems: "center" }]}
                          >
                            <Box>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigate(
                                    `${RouteNames.PROJECTS}/${params?.project_id}${RouteNames.CONTRACT}/${reUploadDocId}`
                                  );
                                }}
                              >
                                <VisibilityOutlined
                                  sx={{ color: "#a6a6a6" }}
                                  fontSize="small"
                                />
                              </IconButton>
                            </Box>
                            <Box
                              component="div"
                              sx={[
                                styles.flex_JCsb,
                                {
                                  alignItems: "center",
                                  backgroundColor: (
                                    ContractStatusMapping as any
                                  )[documentStatus].bg,
                                  p: 1,
                                  borderRadius: "20px",
                                  cursor: "pointer",
                                  minWidth: "120px",
                                },
                              ]}
                              onClick={handleCnctStatusClick}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: (ContractStatusMapping as any)[
                                    documentStatus
                                  ].color,
                                  fontWeight: 600,
                                  ml: 1,
                                  flexGrow: 1,
                                  textAlign: "center",
                                }}
                              >
                                {
                                  (ContractStatusMapping as any)[documentStatus]
                                    .name
                                }
                              </Typography>
                              <KeyboardArrowDown
                                fontSize="small"
                                sx={{
                                  ml: 1,
                                  color: (ContractStatusMapping as any)[
                                    documentStatus
                                  ].color,
                                }}
                              />
                            </Box>
                            <Menu
                              anchorEl={cnctStatusAnchor}
                              open={openCnctStatusMenu}
                              onClose={handleCnctStatusClose}
                              sx={styles.menu_container}
                              elevation={0}
                            >
                              <MenuList>
                                {cnctStatusUpdateArr.map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    onClick={() => {
                                      setDocumentStatus(item.id);
                                      fireUpdateCnctStatusApi({
                                        variables: {
                                          payload: {
                                            documentId: reUploadDocId,
                                            documentStatus: item.id,
                                          },
                                        },
                                      });
                                      handleCnctStatusClose();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <FiberManualRecord
                                        fontSize="small"
                                        sx={{
                                          color: (ContractStatusMapping as any)[
                                            item.id
                                          ].color,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: `${colors.primaryBlack}80`,
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                    </ListItemText>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={styles.flex_JCfe}>
                      <BlackActionContainedButton
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          ml: 1,
                          fontSize: "13px",
                          fontWeight: 600,
                          width: "120px",
                        }}
                        disableElevation
                        disableRipple
                        onClick={() => handleSubmit()}
                      >
                        {Boolean(reUploadDocId) ? "Close" : "Save"}
                      </BlackActionContainedButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateModal;
