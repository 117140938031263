import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { InfoOutlined, ModeEditOutlineOutlined } from "@mui/icons-material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";

import { IArr } from "models/common";
import { GET_ALL_INDEX_ITEMS, GET_INDEX_DETAILS } from "graphql/indexes";
import { useStore } from "utils/store";
import { capitalize } from "utils/helpers";
import { IIndexItem } from "models/indexes";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { ContractStatusMapping } from "utils/constants";
import { RouteNames } from "routes/routeNames";
import { GET_CONTRACT_FILE } from "graphql/contracts";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import AddEditIndexItemModal from "./AddEditIndexItemModal";
import CreateModal from "./CreateModal";
import UploadModal from "./UploadModal";
import UploadDocModal from "./UploadDocModal";
import AddEditFolderModal from "./AddEditFolderModal";
import SendDocumentModal from "./SendDocumentModal";
import Header from "./Header";
import { AddDocContractComponent } from "./TableComponents";
import DeleteIndexItemModal from "./DeleteModal";
import Table from "./StyledTable";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs from "..";

export function IndexItems() {
  const projectDetailsTab = useProjectDetailsTabs();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { setLoading } = useStore();
  const boxRef = useRef(null);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [indexItems, setIndexItems] = useState<IIndexItem[]>([]);
  const [indexItemCount, setIndexItemCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(0);
  const [addEditIndexItemModal, setAddEditIndexItemModal] = useState(false);
  const [addEditFolderModal, setAddEditFolderModal] = useState(false);
  const [selectedIndexItem, setSelectedIndexItem] = useState("");
  const [selectedIndexItemName, setSelectedIndexItemName] = useState("");
  const [selectedIndexItemType, setSelectedIndexItemType] = useState("");
  const [isRegisterItem, setIsRegisterItem] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadDocModal, setUploadDocModal] = useState(false);
  const [reUploadDocId, setReUploadDocId] = useState("");
  const [indexTempTypes, setIndexTempTypes] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState("name");
  const [isClearanceIndex, setIsClearanceIndex] = useState(false);
  const [nestedIdArr, setNestedIdArr] = useState<string[]>([]);
  const [breadCrumbArr, setBreadCrumbArr] = useState<IArr[]>([]);
  const [counter, setCounter] = useState(1);
  const [sendDocumentModal, setSendDocumentModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openFileLink, setOpenFileLink] = useState(false);

  useEffect(() => {
    setNestedIdArr([]);
    setBreadCrumbArr([]);
    setCounter(1);
    const fullString = location?.pathname;
    const substring = "indexes/";
    const position = fullString.indexOf(substring);
    const registerIds = fullString.substring(position + substring.length);
    setNestedIdArr(registerIds.split("/"));
  }, [location]);

  const [getNestedFolderName, { loading: gettingTitle }] = useLazyQuery(
    GET_INDEX_DETAILS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { getRegister } = data;
        const { status, register } = getRegister;
        if (status) {
          const { id, name, templateTypes, isClearance } = register;
          setIsClearanceIndex(isClearance);
          setIndexTempTypes(templateTypes.map((x: any) => x.id));
          const tempArr = [...breadCrumbArr];
          tempArr.push({ id, name });
          setBreadCrumbArr([...tempArr]);
          if (nestedIdArr.length > 1 && counter < nestedIdArr.length) {
            getNestedFolderName({
              variables: {
                payload: {
                  projectId: params?.project_id,
                  registerId: nestedIdArr[counter],
                },
              },
            });
          }
          setCounter(counter + 1);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (
      nestedIdArr.length > 0 &&
      counter === 1 &&
      Boolean(ROLE_PERMISSIONS) &&
      foldersViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
    ) {
      getNestedFolderName({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: nestedIdArr[0],
          },
        },
      });
    }
  }, [
    ROLE_PERMISSIONS,
    counter,
    foldersViewIndex,
    getNestedFolderName,
    nestedIdArr,
    params?.project_id,
  ]);

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_INDEX_ITEMS, {
    variables: {
      page: 1,
      limit: 50,
      filter: {
        project_id: params?.project_id,
        register_id:
          nestedIdArr?.length > 0
            ? nestedIdArr[nestedIdArr.length - 1]
            : params?.index_id,
        name__icontains: searchText,
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    skip:
      !params?.project_id ||
      !params?.index_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        foldersViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisterItems } = data;
      const { status, count, registerItems } = getRegisterItems;
      if (status) {
        setIndexItemCount(count);
        setIndexItems(registerItems);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getUploadedFile, { loading: gettingUploadedFile }] = useLazyQuery(
    GET_CONTRACT_FILE,
    {
      onCompleted: (data) => {
        // setLoading(false);
        const { getDocumentFile } = data;
        const { status, file, fileType } = getDocumentFile;
        if (status && Boolean(file) && openFileLink) {
          if (
            fileType.startsWith("pdf") &&
            selectedIndexItemType === "CONTRACTS"
          ) {
            sessionStorage.setItem("upload_pdf_back_url", location?.pathname);
            navigate(
              `${RouteNames.PROJECTS}/${params?.project_id}${RouteNames.CONTRACT}-upload-pdf/${reUploadDocId}`
            );
          } else {
            window.open(file, "_blank");
          }
        }
        setOpenFileLink(false);
        setReUploadDocId("");
        setSelectedIndexItemType("");
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Box sx={styles.flex_Acenter}>
            {params1?.row?.isRegister ? (
              <img src={images.FOLDER_NEW} alt="index" style={{ height: 20 }} />
            ) : params1?.row?.itemType === "BLANK" ? (
              <img src={images.NEW_DOC_NEW} style={{ width: 18 }} alt="blank" />
            ) : Boolean(params1?.row?.document) ? (
              params1?.row?.itemType === "DOCUMENT" ? (
                <img
                  src={images.DOCUMENT_PLACEHOLDER_NEW}
                  style={{ width: 19 }}
                  alt="pdf"
                />
              ) : (
                <img
                  src={images.CONTRACT_PLACEHOLDER_NEW}
                  style={{ width: 19 }}
                  alt="pdf"
                />
              )
            ) : (
              <img src={images.NEW_DOC_NEW} style={{ width: 18 }} alt="blank" />
            )}
            <Typography
              sx={[styles.cell_text, { ml: 1, wordBreak: "break-word" }]}
            >
              {params1?.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: (params1: GridRenderCellParams<any>) => {
          return (
            <Typography sx={[styles.cell_text, styles.wrap]}>
              {params1?.value}
            </Typography>
          );
        },
      },
      {
        field: "itemType",
        headerName: "Type",
        headerAlign: "center",
        minWidth: 120,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {params1?.row?.isRegister ? "" : capitalize(params1?.value)}
          </Typography>
        ),
      },
      {
        field: "document",
        headerName: "Status",
        headerAlign: "center",
        minWidth: 200,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => {
          if (params1?.row?.isRegister) {
            return null;
          }
          return Boolean(params1?.value) ? (
            <Chip
              label={
                params1?.row?.itemType === "DOCUMENT" &&
                params1?.value?.documentStatus === "APPROVAL_PENDING"
                  ? "Pending"
                  : (ContractStatusMapping as any)[
                      params1?.value?.documentStatus
                    ]?.name
              }
              sx={{
                px: 1,
                fontSize: "12px",
                color: (ContractStatusMapping as any)[
                  params1?.value?.documentStatus
                ]?.color,
                backgroundColor: (ContractStatusMapping as any)[
                  params1?.value?.documentStatus
                ]?.bg,
              }}
            />
          ) : null;
        },
      },
      {
        field: "modifiedDate",
        headerName: "Last Modified",
        headerAlign: "center",
        minWidth: 120,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, { color: "#00000070" }]}>
            {moment(params1?.value).format("DD MMMM YYYY")}
          </Typography>
        ),
      },
      ...(Boolean(ROLE_PERMISSIONS) &&
      foldersEditIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
        ? [
            {
              field: "actions",
              headerName: "",
              minWidth: 200,
              sortable: false,
              renderCell: (params1: GridRenderCellParams<any>) => (
                <Box sx={styles.flex_Acenter}>
                  <Box>
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedIndexItem(params1?.row?.id);
                          setSelectedIndexItemName(params1?.row?.name);
                          if (params1?.row?.isRegister) {
                            setAddEditFolderModal(true);
                            setIsRegisterItem(true);
                          } else {
                            setAddEditIndexItemModal(true);
                          }
                        }}
                      >
                        <ModeEditOutlineOutlined
                          sx={{ color: "#a6a6a6", fontSize: 17 }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {Boolean(params1.row?.document) && (
                    <Box>
                      <Tooltip title="Details">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedIndexItem(params1?.row?.id);
                            setSelectedIndexItemName(params1?.row?.name);
                            setReUploadDocId(params1?.row?.document?.id);
                            if (params1?.row?.itemType === "CONTRACTS") {
                              if (params1?.row?.document?.isUpload) {
                                setUploadModal(true);
                              } else {
                                setCreateModal(true);
                              }
                            } else {
                              setUploadDocModal(true);
                            }
                          }}
                        >
                          <InfoOutlined
                            sx={{ color: "#a6a6a6", fontSize: 17 }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {Boolean(params1.row?.document) && (
                    <Box>
                      <Tooltip title="Send">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setReUploadDocId(params1.row.document.id);
                            setSendDocumentModal(true);
                          }}
                        >
                          <img
                            src={images.SEND_GREY}
                            alt="send"
                            style={{ height: 15 }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {!Boolean(params1?.row?.document) &&
                    params1?.row?.itemType !== "BLANK" && (
                      <AddDocContractComponent
                        params1={params1}
                        setSelectedIndexItem={setSelectedIndexItem}
                        setUploadDocModal={setUploadDocModal}
                        setCreateModal={setCreateModal}
                        setUploadModal={setUploadModal}
                      />
                    )}
                </Box>
              ),
            },
          ]
        : []),
    ],
    [ROLE_PERMISSIONS, foldersEditIndex]
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (indexItems.length < indexItemCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(indexItems.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getRegisterItems.registerItems;
              return {
                getRegisterItems: {
                  ...fetchMoreResult?.getRegisterItems,
                  registerItems: [...indexItems, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, indexItems, indexItemCount]);

  useEffect(() => {
    setLoading(gettingTitle || gettingUploadedFile || loading);
  }, [gettingTitle, gettingUploadedFile, loading, setLoading]);

  useEffect(() => {
    projectDetailsTab.setSelectedTab(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2.5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Header
            setAddEditIndexItemModal={setAddEditIndexItemModal}
            setAddEditFolderModal={setAddEditFolderModal}
            view={view}
            setView={setView}
            setSearchText={setSearchText}
            sortBy={sortBy}
            setSortBy={setSortBy}
            nestedIdArr={nestedIdArr}
            breadCrumbArr={breadCrumbArr}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            ref={boxRef}
            sx={{
              height: "calc(100vh - 245px)",
              overflow: "auto",
              position: "relative",
              mx: -3,
              mb: -3,
            }}
          >
            {indexItems.length === 0 &&
            !(
              Boolean(ROLE_PERMISSIONS) &&
              foldersEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
            ) ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <img src={images.NEW_DOC_NEW} alt="folder" width={25} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create an item
                    here.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Table
                rows={indexItems}
                columns={columns}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                disableColumnMenu
                onRowClick={(params1: GridRowParams, _, __) => {
                  if (params1?.row?.isRegister) {
                    navigate(`${location?.pathname}/${params1?.row?.id}`);
                  }
                  if (Boolean(params1?.row?.document)) {
                    if (params1?.row?.document?.isUpload) {
                      setOpenFileLink(true);
                      setReUploadDocId(params1?.row?.document?.id);
                      setSelectedIndexItemType(params1?.row?.itemType);
                      getUploadedFile({
                        variables: {
                          payload: { documentId: params1?.row?.document?.id },
                        },
                      });
                    } else {
                      navigate(
                        `${RouteNames.PROJECTS}/${params?.project_id}${RouteNames.CONTRACT}/${params1?.row?.document?.id}`
                      );
                    }
                  }
                }}
                sx={{
                  ...styles.table,
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                    backgroundColor: "#B6BCC320",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px #e6e6e6 solid",
                    cursor: "pointer",
                    borderRadius: 0,
                  },
                  borderTop: "1px #e6e6e6 solid",
                  borderRadius: 0,
                }}
                hideFooter
              />
            )}
          </Box>
        </Grid>
        {addEditIndexItemModal && (
          <AddEditIndexItemModal
            open={addEditIndexItemModal}
            setOpen={setAddEditIndexItemModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            refetchTableData={refetch}
            isClearanceIndex={isClearanceIndex}
            nestedIdArr={nestedIdArr}
            setDeleteModal={setDeleteModal}
          />
        )}
        {addEditFolderModal && (
          <AddEditFolderModal
            open={addEditFolderModal}
            setOpen={setAddEditFolderModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            nestedIdArr={nestedIdArr}
            indexTempTypes={indexTempTypes}
            refetchTableData={refetch}
            setDeleteModal={setDeleteModal}
          />
        )}
        {uploadDocModal && (
          <UploadDocModal
            open={uploadDocModal}
            setOpen={setUploadDocModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            selectedIndexItemName={selectedIndexItemName}
            setSelectedIndexItemName={setSelectedIndexItemName}
            refetchTableData={refetch}
            reUploadDocId={reUploadDocId}
            setReUploadDocId={setReUploadDocId}
            nestedIdArr={nestedIdArr}
          />
        )}
        {createModal && (
          <CreateModal
            open={createModal}
            setOpen={setCreateModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            indexTempTypes={indexTempTypes}
            refetchTableData={refetch}
            nestedIdArr={nestedIdArr}
            reUploadDocId={reUploadDocId}
            setReUploadDocId={setReUploadDocId}
          />
        )}
        {uploadModal && (
          <UploadModal
            open={uploadModal}
            setOpen={setUploadModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            selectedIndexItemName={selectedIndexItemName}
            setSelectedIndexItemName={setSelectedIndexItemName}
            refetchTableData={refetch}
            reUploadDocId={reUploadDocId}
            setReUploadDocId={setReUploadDocId}
            nestedIdArr={nestedIdArr}
          />
        )}
        {sendDocumentModal && (
          <SendDocumentModal
            open={sendDocumentModal}
            setOpen={setSendDocumentModal}
            setSelectedIndexItem={setSelectedIndexItem}
            reUploadDocId={reUploadDocId}
            setReuploadDocId={setReUploadDocId}
          />
        )}
        {deleteModal && (
          <DeleteIndexItemModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            selectedIndexItemName={selectedIndexItemName}
            setSelectedIndexItemName={setSelectedIndexItemName}
            refetchTableData={refetch}
            nestedIdArr={nestedIdArr}
            isRegisterItem={isRegisterItem}
            setIsRegisterItem={setIsRegisterItem}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
