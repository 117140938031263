import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  menuItemClasses,
  Typography,
} from "@mui/material";
import {
  Add,
  AddCircle,
  DeleteOutline,
  FilterAltOutlined,
  FormatListBulletedOutlined,
  GridViewOutlined,
  KeyboardArrowDown,
  ModeEditOutline,
  Person,
  Search,
  Share,
} from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";

import { IContact } from "models/contacts";
import { BlackActionContainedButton, NewOutlinedBtn } from "common/Buttons";
import { useStore } from "utils/store";
import { GET_CONTACTS, GET_EVERYONE_PROJECT_REPORT } from "graphql/contacts";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { useContactCreateModal } from "hooks/useCreateContactModal";
import { useProjectPeopleMenu } from "hooks/useProjectPeopleMenu";
import { useViewContactInfoModal } from "hooks/useViewContactInfoModal";
import Table from "common/Table";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import ContactCard from "./ContactCard";
import Filters from "./Filters";
import AddContactModal from "./AddContactModal";
import EditContactModal from "./EditContactModal";
import DeleteModal from "./DeleteModal";
import ShareModal from "./ShareModal";
import InformationModal from "./InformationModal";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs, { ProjectPeopleMenuItems } from "..";
import { toast } from "react-toastify";
import ExportFeedbackModal from "./ExportFeedbackModal";

const ContactFilterArr = [
  { id: "ALL", name: "All" },
  { id: "CAST", name: "Cast" },
  { id: "CREW", name: "Crew" },
  { id: "OTHER", name: "Other" },
];

export function ProjectContacts() {
  const { setLoading } = useStore();
  const projectId = useParams();
  const projectDetailsTab = useProjectDetailsTabs();
  const projectPeopleMenu = useProjectPeopleMenu();
  const createContactHook = useContactCreateModal();
  const viewContactModal = useViewContactInfoModal();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const contactsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CONTACTS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const contactsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CONTACTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [view, setView] = useState(1);
  const [projectContacts, setProjectContacts] = useState<IContact[]>([]);
  const [contactCount, setContactCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [emailSearchText, setEmailSearchText] = useState("");
  const [contactDeptFilter, setContactDeptFilter] = useState("");
  const [addContactModal, setAddContactModal] = useState(false);
  const [editContactModal, setEditContactModal] = useState(false);
  const [contactInfoModal, setContactInfoModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedContactName, setSelectedContactName] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [openContactFilterMenu, setOpenContactFilterMenu] = useState(false);
  const [contactFilterMenuAnchor, setContactFilterMenuAnchor] =
    useState<HTMLElement | null>(null);
  const [selectedContactFilter, setSelectedContactFilter] = useState("ALL");
  const [openReportMenu, setOpenReportMenu] = useState(false);
  const [reportMenuAnchor, setReportMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [exportFeedbackModal, setExportFeedbackModal] = useState(false);
  const [exportFeedbackMessage, setExportFeedbackMessage] = useState("");

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const handleContactFilterMenuOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    setContactFilterMenuAnchor(e.currentTarget);
    setOpenContactFilterMenu(true);
  };

  const handleContactFilterMenuClose = () => {
    setContactFilterMenuAnchor(null);
    setOpenContactFilterMenu(false);
  };

  const handleReportMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setReportMenuAnchor(e.currentTarget);
    setOpenReportMenu(true);
  };

  const handleReportMenuClose = () => {
    setReportMenuAnchor(null);
    setOpenReportMenu(false);
  };

  const { loading, refetch, fetchMore } = useQuery(GET_CONTACTS, {
    variables: {
      filter: {
        contactProjects__projectId: projectId?.project_id,
        ...(Boolean(searchText) && { fullName__icontains: searchText }),
        ...(Boolean(emailSearchText) && {
          emailId__icontains: emailSearchText,
        }),
        ...(Boolean(contactDeptFilter) && {
          contactDepartmentId: contactDeptFilter,
        }),
        ...(selectedContactFilter === "CAST" && { contact_type: "Cast" }),
        ...(selectedContactFilter === "CREW" && { contact_type: "Crew" }),
        ...(selectedContactFilter === "OTHER" && { contact_type: "Other" }),
      },
      limit: 60,
      page: 1,
      sorted: "full_name",
    },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        contactsViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[contactsViewIndex]?.CONTACTS_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { getAllContacts } = data;
      const { status, contacts, count } = getAllContacts;
      if (status) {
        setContactCount(count);
        setProjectContacts(contacts);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getEveryoneProjectReport, { loading: generating }] = useLazyQuery(
    GET_EVERYONE_PROJECT_REPORT,
    {
      onCompleted: (data) => {
        const { everyoneReport } = data;
        const { status, message } = everyoneReport;
        if (status) {
          setExportFeedbackMessage(message);
          setExportFeedbackModal(true);
        } else {
          toast.error(message);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Name",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              wordBreak: "break-word",
            }}
          >
            {params?.row?.profile_picture ? (
              <Avatar src={params?.row?.profile_picture} />
            ) : (
              <Avatar />
            )}
            <Typography sx={[styles.cell_text, { ml: 1 }]}>
              {params?.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "contactDepartment",
        headerName: "Department",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, { fontWeight: 600 }]}>
            {params?.value?.name || "TBA"}
          </Typography>
        ),
      },
      {
        field: "profile",
        headerName: "Role",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          let profileJSON = Boolean(params?.value)
            ? JSON.parse(params?.value)
            : null;
          return (
            <Typography sx={styles.cell_text}>
              {Boolean(profileJSON)
                ? profileJSON?.project_role || "TBA"
                : "TBA"}
            </Typography>
          );
        },
      },
      {
        field: "emailId",
        headerName: "Email",
        sortable: false,
        minWidth: 330,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "mobile",
        headerName: "Phone",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography
            sx={styles.cell_text}
          >{`+61 ${params?.value}`}</Typography>
        ),
      },
      ...(Boolean(ROLE_PERMISSIONS) &&
      contactsEditIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]?.CONTACTS_EDIT
        ? [
            {
              field: "actions",
              type: "actions",
              headerName: "",
              sortable: false,
              getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  showInMenu
                  label="Edit"
                  icon={
                    <ModeEditOutline
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedContact(params.row.id);
                    setEditContactModal(true);
                  }}
                  sx={styles.action_text}
                />,
                <GridActionsCellItem
                  showInMenu
                  label="Delete"
                  icon={
                    <DeleteOutline
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedContact(params.row.id);
                    setSelectedContactName(params?.row?.fullName);
                    setDeleteModal(true);
                  }}
                  sx={styles.action_text}
                />,
              ],
            },
          ]
        : []),
    ],
    [ROLE_PERMISSIONS, contactsEditIndex]
  );

  // useEffect(() => {
  //   const handleScroll = async () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight
  //     ) {
  //       return;
  //     }
  //     if (projectContacts.length < contactCount) {
  //       try {
  //         setLoading(true);
  //         await fetchMore({
  //           variables: {
  //             page: Math.ceil(projectContacts.length / 60) + 1,
  //             limit: 60,
  //           },
  //           updateQuery: (previousResult, { fetchMoreResult }) => {
  //             const newEntries = fetchMoreResult.getAllContacts.contacts;
  //             return {
  //               getAllContacts: {
  //                 ...fetchMoreResult?.getAllContacts,
  //                 contacts: [...projectContacts, ...newEntries],
  //               },
  //             };
  //           },
  //         });
  //       } catch (error) {
  //         console.error("ERROR", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [contactCount, fetchMore, projectContacts, setLoading]);

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (projectContacts.length < contactCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(projectContacts.length / 60) + 1,
              limit: 60,
              sorted: "full_name",
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getAllContacts.contacts;
              return {
                getAllContacts: {
                  ...fetchMoreResult?.getAllContacts,
                  contacts: [...projectContacts, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, setLoading, projectContacts, contactCount]);

  useEffect(() => {
    setLoading(generating || loading);
  }, [generating, loading, setLoading]);

  useEffect(() => {
    projectPeopleMenu.onMenuItemClick(ProjectPeopleMenuItems[0]);
    projectDetailsTab.setSelectedTab(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2.25 }}>
          <Box sx={styles.view_container}>
            <Box>
              {Boolean(ROLE_PERMISSIONS) &&
              contactsEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]
                ?.CONTACTS_EDIT ? (
                <NewOutlinedBtn
                  disableElevation
                  startIcon={<Add sx={{ color: "#000" }} />}
                  sx={{
                    color: "#000",
                    py: 1,
                    px: 2,
                    fontSize: "14px",
                    borderRadius: "8px",
                    display: { xs: "none", sm: "flex" },
                  }}
                  onClick={() => setAddContactModal(true)}
                >
                  New Contact
                </NewOutlinedBtn>
              ) : (
                <Box
                  sx={{
                    height: 42,
                    width: 142,
                    display: { xs: "none", sm: "flex" },
                  }}
                />
              )}
              {Boolean(ROLE_PERMISSIONS) &&
              contactsEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]
                ?.CONTACTS_EDIT ? (
                <NewOutlinedBtn
                  disableElevation
                  startIcon={
                    <Add
                      sx={{ color: "#000", height: "18px" }}
                      fontSize="small"
                    />
                  }
                  sx={{
                    color: "#000",
                    py: 1,
                    px: { xs: 1, sm: 2 },
                    fontSize: "12px",
                    borderRadius: "8px",
                    display: { xs: "flex", sm: "none" },
                  }}
                  onClick={() => setAddContactModal(true)}
                >
                  New
                </NewOutlinedBtn>
              ) : (
                <Box
                  sx={{
                    height: 42,
                    width: 142,
                    display: { xs: "flex", sm: "none" },
                  }}
                />
              )}
            </Box>
            <Box
              sx={[
                styles.flex_Acenter,
                { display: { xs: "none", sm: "flex" } },
              ]}
            >
              <Box
                sx={{
                  backgroundColor:
                    selectedContactFilter === "ALL" ? "#000000" : "#B6BCC330",
                  py: 0.7,
                  width: 70,
                  borderRadius: "6px",
                  cursor: "pointer",
                  ...(selectedContactFilter !== "ALL" && {
                    ":hover": {
                      backgroundColor: `${colors.primaryBlack}15`,
                    },
                  }),
                }}
                onClick={() => setSelectedContactFilter("ALL")}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    color:
                      selectedContactFilter === "ALL" ? "#ffffff" : "#000000",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  All
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor:
                    selectedContactFilter === "CAST" ? "#000000" : "#B6BCC330",
                  py: 0.7,
                  width: 70,
                  borderRadius: "6px",
                  cursor: "pointer",
                  ml: 1,
                  ...(selectedContactFilter !== "CAST" && {
                    ":hover": {
                      backgroundColor: `${colors.primaryBlack}15`,
                    },
                  }),
                }}
                onClick={() => setSelectedContactFilter("CAST")}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    color:
                      selectedContactFilter === "CAST" ? "#ffffff" : "#000000",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Cast
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor:
                    selectedContactFilter === "CREW" ? "#000000" : "#B6BCC330",
                  py: 0.7,
                  width: 70,
                  borderRadius: "6px",
                  cursor: "pointer",
                  ml: 1,
                  ...(selectedContactFilter !== "CREW" && {
                    ":hover": {
                      backgroundColor: `${colors.primaryBlack}15`,
                    },
                  }),
                }}
                onClick={() => setSelectedContactFilter("CREW")}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    color:
                      selectedContactFilter === "CREW" ? "#ffffff" : "#000000",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Crew
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor:
                    selectedContactFilter === "OTHER" ? "#000000" : "#B6BCC330",
                  py: 0.7,
                  width: 70,
                  borderRadius: "6px",
                  cursor: "pointer",
                  ml: 1,
                  ...(selectedContactFilter !== "OTHER" && {
                    ":hover": {
                      backgroundColor: `${colors.primaryBlack}15`,
                    },
                  }),
                }}
                onClick={() => setSelectedContactFilter("OTHER")}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    color:
                      selectedContactFilter === "OTHER" ? "#ffffff" : "#000000",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Other
                </Typography>
              </Box>
            </Box>
            <Box
              sx={[
                styles.flex_Acenter,
                { display: { xs: "flex", sm: "none" } },
              ]}
            >
              <Box
                component="div"
                sx={{
                  backgroundColor: "#000000",
                  py: 0.7,
                  pr: 1,
                  pl: 1.5,
                  width: 80,
                  borderRadius: "6px",
                  cursor: "pointer",
                  ml: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={handleContactFilterMenuOpen}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    color: "#ffffff",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {ContactFilterArr.find(
                    (x: any) => x.id === selectedContactFilter
                  )?.name || ""}
                </Typography>
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              </Box>
              <Menu
                anchorEl={contactFilterMenuAnchor}
                open={openContactFilterMenu}
                onClose={handleContactFilterMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    width: 80,
                    border: "1px #00000030 solid",
                    borderRadius: "8px",
                  },
                }}
                elevation={0}
              >
                {ContactFilterArr.map((filter) => (
                  <MenuItem
                    key={filter.id}
                    selected={selectedContactFilter === filter.id}
                    sx={{
                      borderRadius: "6px",
                      [`&.${menuItemClasses.selected}`]: {
                        backgroundColor: "#41B19931",
                        "&:hover": { backgroundColor: "#41B19995" },
                      },
                      "&:hover": { backgroundColor: "#B6BCC330" },
                      height: 10,
                    }}
                    onClick={() => {
                      setSelectedContactFilter(filter.id);
                      handleContactFilterMenuClose();
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", color: "#00000080" }}>
                      {filter.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={styles.flex_Acenter}>
              <Box sx={{ mr: 1 }}>
                <BlackActionContainedButton
                  variant="contained"
                  disableElevation
                  startIcon={<Share color="secondary" />}
                  onClick={handleReportMenuOpen}
                  sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
                >
                  Export
                </BlackActionContainedButton>
                <Menu
                  anchorEl={reportMenuAnchor}
                  open={openReportMenu}
                  onClose={handleReportMenuClose}
                  sx={{
                    "& .MuiMenu-paper": {
                      border: "1px #00000030 solid",
                      borderRadius: "8px",
                    },
                  }}
                  elevation={0}
                >
                  <MenuItem
                    sx={{
                      borderRadius: "6px",
                      "&:hover": { backgroundColor: "#B6BCC330" },
                    }}
                    onClick={() => {
                      getEveryoneProjectReport({
                        variables: {
                          filter: { projectId: projectId?.project_id },
                          type: "Cast",
                        },
                      });
                      handleReportMenuClose();
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", color: "#00000080" }}>
                      Everyone Project (Cast)
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{
                      borderRadius: "6px",
                      "&:hover": { backgroundColor: "#B6BCC330" },
                    }}
                    onClick={() => {
                      getEveryoneProjectReport({
                        variables: {
                          filter: { projectId: projectId?.project_id },
                          type: "Crew",
                        },
                      });
                      handleReportMenuClose();
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", color: "#00000080" }}>
                      Everyone Project (Crew)
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Box>
                <IconButton
                  onClick={handleFilterMenuOpen}
                  size="small"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                >
                  <FilterAltOutlined
                    fontSize="small"
                    sx={{
                      color: openFilterMenu
                        ? "#41B199"
                        : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
              </Box>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 350 } }}
              >
                <Filters
                  setSearchText={setSearchText}
                  setEmailSearchText={setEmailSearchText}
                  searchText={searchText}
                  emailSearchText={emailSearchText}
                  contactDeptFilter={contactDeptFilter}
                  setContactDeptFilter={setContactDeptFilter}
                />
              </Menu>
              <Box sx={{ mx: 0.55 }}>
                <IconButton
                  onClick={() => setView(0)}
                  size="small"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                >
                  <FormatListBulletedOutlined
                    fontSize="small"
                    sx={{
                      color:
                        view === 0 ? "#41B199" : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  onClick={() => setView(1)}
                  size="small"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                >
                  <GridViewOutlined
                    fontSize="small"
                    sx={{
                      color:
                        view === 1 ? "#41B199" : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        {!loading &&
        !searchText &&
        !emailSearchText &&
        selectedContactFilter === "ALL" &&
        projectContacts.length === 0 ? (
          Boolean(ROLE_PERMISSIONS) &&
          contactsEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]?.CONTACTS_EDIT ? (
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  cursor: "pointer",
                  my: 1,
                }}
                onClick={() => {
                  setAddContactModal(true);
                }}
              >
                <Box
                  sx={{
                    border: "3px #BFBDBC dotted",
                    borderRadius: "20px",
                    backgroundColor: `#BFBDBC22`,
                    flexDirection: "column",
                    px: 5,
                    aspectRatio: 0.8 / 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 210,
                    width: 168,
                  }}
                >
                  <AddCircle sx={{ color: "#BFBDBC", fontSize: 65, mb: 2 }} />
                  <Typography
                    sx={{
                      color: `#BFBDBC`,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    textAlign="center"
                  >
                    Add Contact
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  height: `calc(100vh - 265px)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <Person sx={{ fontSize: 25, color: "#000" }} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create a
                    contact.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
        ) : (
          <></>
        )}
        {!loading &&
          (searchText || emailSearchText || selectedContactFilter !== "ALL") &&
          projectContacts.length === 0 && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Search
                  sx={{ color: `${colors.primaryBlack}30`, fontSize: 80 }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    color: `${colors.primaryBlack}30`,
                    fontSize: "32px",
                    fontWeight: 500,
                  }}
                  textAlign="center"
                >
                  No results found
                </Typography>
              </Box>
            </Grid>
          )}
        {!loading && projectContacts.length > 0 && (
          <>
            {view === 1 &&
              projectContacts.map((contact) => (
                <Grid key={contact.id} item lg={2} md={3} sm={4} xs={6}>
                  <ContactCard
                    contact={contact}
                    setSelectedContactId={setSelectedContact}
                    setEditContactModal={setEditContactModal}
                    setDeleteContactModal={setDeleteModal}
                    setContactInfoModal={setContactInfoModal}
                    setSelectedContactName={setSelectedContactName}
                  />
                </Grid>
              ))}
            {view === 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mx: -3, mb: -3 }}>
                  <Table
                    rows={projectContacts}
                    columns={columns}
                    showCellVerticalBorder={false}
                    showColumnVerticalBorder={false}
                    disableColumnMenu
                    onRowClick={(params: GridRowParams, _, __) => {
                      setSelectedContact(params?.row?.id);
                      setContactInfoModal(true);
                    }}
                    sx={{
                      ...styles.table,
                      "& .MuiDataGrid-columnHeaders": {
                        border: "none",
                        backgroundColor: "#B6BCC320",
                      },
                      "& .MuiDataGrid-row": {
                        borderBottom: "1px #e6e6e6 solid",
                        cursor: "pointer",
                        borderRadius: 0,
                      },
                      borderTop: "1px #e6e6e6 solid",
                      borderRadius: 0,
                    }}
                    hideFooter
                  />
                </Box>
              </Grid>
            )}
          </>
        )}
        {(addContactModal || createContactHook.open) && (
          <AddContactModal
            refetchContacts={refetch}
            open={addContactModal || createContactHook.open}
            handleClose={() => {
              if (createContactHook.open) {
                createContactHook.onClose();
              }
              setAddContactModal(false);
            }}
          />
        )}
        {editContactModal && (
          <EditContactModal
            open={editContactModal}
            setOpen={setEditContactModal}
            setSelectedContact={setSelectedContact}
            selectedContact={selectedContact}
            refetchContacts={refetch}
          />
        )}
        {(contactInfoModal || viewContactModal.open) && (
          <InformationModal
            open={contactInfoModal || viewContactModal.open}
            handleClose={() => {
              if (viewContactModal.open) {
                viewContactModal.onClose();
              }
              setContactInfoModal(false);
              setSelectedContact("");
            }}
            selectedContact={selectedContact || viewContactModal.contactId}
          />
        )}
        {shareModal && (
          <ShareModal
            open={shareModal}
            setOpen={setShareModal}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            selectedContactEmail={selectedContactName}
            setSelectedContactEmail={setSelectedContactName}
          />
        )}
        {deleteModal && (
          <DeleteModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            selectedContactName={selectedContactName}
            setSelectedContactName={setSelectedContactName}
            refetch={refetch}
          />
        )}
        {exportFeedbackModal && (
          <ExportFeedbackModal
            open={exportFeedbackModal}
            setOpen={setExportFeedbackModal}
            message={exportFeedbackMessage}
            setMessage={setExportFeedbackMessage}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
