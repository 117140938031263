import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Box, Button, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import { RouteNames } from "routes/routeNames";
import { images } from "assets/images";
import { ForgotPwdLabels, GlobalLabels } from "common/AppConstants";
import { VERIFY_OTP_FORGOT_PWD } from "graphql/users";
import { useStore } from "utils/store";

import { ForgotPwdStyles as styles } from "./styles";

const VerificationCode = () => {
  const navigate = useNavigate();
  const { setLoading } = useStore();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const enteredEmail = useMemo(() => sessionStorage.getItem("email"), []);

  const [fireVerifyOTPApi, { loading }] = useMutation(VERIFY_OTP_FORGOT_PWD, {
    onCompleted: (data) => {
      setLoading(false);
      const { verifyOtp } = data;
      const { status, error, token, me } = verifyOtp;
      if (status) {
        sessionStorage.removeItem("email");
        navigate(`${RouteNames.RESET_PASSWORD}?token=${token}&id=${me.id}`);
      } else {
        //error toast
        setError(error);
      }
    },
  });

  const handleSubmit = (e: any) => {
    fireVerifyOTPApi({
      variables: { payload: { email: enteredEmail?.trim(), otp: otp } },
    });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (error) {
      toast.error(error, { delay: 10 });
      toast.onChange((toastOpt) => {
        if (toastOpt.status === "removed") {
          setError("");
        }
      });
    }
  }, [error]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ px: { xs: 2, sm: "auto" }, py: { xs: 4, sm: "auto" } }}
    >
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
        <img src={images.LOGO} alt="logo" />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={[styles.content_center, styles.form_container]}
      >
        <img src={images.OTP_VERIFICATION} alt="otp_verification" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
        <Typography sx={styles.code_verify_text}>
          {ForgotPwdLabels.VERIFY_CODE}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
        <Box>
          <Typography sx={styles.inst_text}>
            {ForgotPwdLabels.SENT_TO_EMAIL}
          </Typography>
          <Typography sx={styles.email_text}>{enteredEmail}</Typography>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} xs={12} sm={12} sx={styles.content_center}>
        <OtpInput
          shouldAutoFocus
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
          containerStyle={styles.otp_container}
          inputStyle={styles.otp_input}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
        <Box>
          <Button
            variant="contained"
            size="small"
            sx={styles.continue_btn}
            disabled={otp.length < 4}
            onClick={handleSubmit}
          >
            {GlobalLabels.CONTINUE}
          </Button>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
        <Box>
          <Button
            sx={styles.back_btn}
            onClick={() => navigate(RouteNames.FORGOT_PWD)}
          >
            {GlobalLabels.GO_BACK}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerificationCode;
