import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CameraAlt, Close, ModeEditOutlineOutlined } from "@mui/icons-material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { ClientUsersLabels } from "common/AppConstants";
import {
  CREATE_USER,
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS,
} from "graphql/users";
import { useStore } from "utils/store";
import { GET_ROLES_ARR } from "graphql/roles";
import { GET_ALL_PROJECTS_DROPDOWN } from "graphql/projects";
import PhoneNumberInput from "common/PhoneNumberInput";
import { BlackActionContainedButton } from "common/Buttons";
import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";
import { colors } from "theme/colors";

import { AccessStyles as styles } from "./styles";

interface IProjectUser {
  fullName: string;
  roles: string;
  email: string;
  mobile: string;
  projects: string[];
  profile_picture: any;
}

interface IAddEditUserModalProps extends IModalProps {
  selectedUser: string;
  setSelectedUser: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
}

const AddEditAdminModal = (props: IAddEditUserModalProps) => {
  const { open, setOpen, selectedUser, setSelectedUser, refetchTableData } =
    props;

  const { setLoading } = useStore();

  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
  const [initialValues, setInitialValues] = useState<IProjectUser>({
    fullName: "",
    roles: "",
    email: "",
    mobile: "",
    projects: [],
    profile_picture: null,
  });
  const [rolesArr, setRolesArr] = useState<IArr[]>([]);
  const [projectArr, setProjectArr] = useState<IArr[]>([]);
  const [projectsLoaded, setProjectsLoaded] = useState(false);
  const [profilePictureHovered, setProfilePictureHovered] = useState(false);

  const { loading: gettingRoles } = useQuery(GET_ROLES_ARR, {
    onCompleted: (data) => {
      // setLoading(false);
      const { roles } = data;
      const { status, roles: rawRoles } = roles;
      if (status) {
        setRolesArr(rawRoles);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingProjects } = useQuery(GET_ALL_PROJECTS_DROPDOWN, {
    onCompleted: (data) => {
      // setLoading(false);
      setProjectsLoaded(true);
      const { projects } = data;
      const { status, projects: rawProjects } = projects;
      if (status) {
        if (rawProjects.length > 0) {
          setProjectArr([{ id: "ALL", name: "All Projects" }, ...rawProjects]);
        } else {
          setProjectArr(rawProjects);
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getUserDetails, { loading: gettingDetails }] = useLazyQuery(
    GET_USER_DETAILS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { user } = data;
        if (Boolean(user)) {
          const { fullName, roles, mobile, email, projects, profilePicture } =
            user;
          const projectsArr = projects ? projects.map((x: any) => x.id) : [];
          let parsedMobile = "";
          if (mobile.length === 10) {
            const tempStr = mobile.slice(1);
            const areaCode = tempStr.substring(0, 3);
            const middle = tempStr.substring(3, 6);
            const last = tempStr.substring(6, 9);
            parsedMobile = `${areaCode} ${middle} ${last}`;
          } else {
            parsedMobile = mobile;
          }
          setInitialValues({
            fullName,
            roles: roles[0],
            mobile: parsedMobile,
            email,
            projects:
              projectsArr.length ===
              projectArr.filter((x) => x.id !== "ALL").length
                ? ["ALL"]
                : projectsArr,
            profile_picture: profilePicture,
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const getProjectDropdownValues = (projects: string[]) => {
    const values: IArr[] = [];
    if (projectArr.length > 0) {
      projectArr.forEach((x) => {
        if (projects.includes(x.id)) {
          values.push(x);
        }
      });
    }
    return values;
  };

  const handleClose = () => {
    setSelectedUser("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireCreateUserApi, { loading: adding }] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      setLoading(false);
      const { createUser } = data;
      const { status, message } = createUser;
      handleClose();
      if (status) {
        toast.success(message);
        refetchTableData();
      } else {
        toast.error(message);
      }
    },
  });

  const [fireUpdateUserApi, { loading: updating }] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateUser } = data;
        const { status, message } = updateUser;
        handleClose();
        if (status) {
          toast.success(message);
          refetchTableData();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const handleUpsert = (values: IProjectUser) => {
    const { roles, email, projects, profile_picture, ...rest } = values;
    if (Boolean(selectedUser)) {
      fireUpdateUserApi({
        variables: {
          payload: {
            ...rest,
            id: selectedUser,
            roles: [roles],
            projects: projects.includes("ALL")
              ? projectArr.map((x) => x.id).filter((y) => y !== "ALL")
              : projects,
            ...(profile_picture === null && { removeProfilePhoto: true }),
          },
          ...(Boolean(profile_picture) &&
            typeof profile_picture !== "string" && { file: profile_picture }),
        },
      });
    } else {
      fireCreateUserApi({
        variables: {
          payload: {
            userDetails: {
              ...rest,
              email: email.trim(),
              roles: [roles],
            },
            projects: projects.includes("ALL")
              ? projectArr.map((x) => x.id).filter((y) => y !== "ALL")
              : projects,
          },
          ...(Boolean(profile_picture) &&
            typeof profile_picture !== "string" && { file: profile_picture }),
        },
      });
    }
  };

  useEffect(() => {
    if (Boolean(selectedUser) && projectsLoaded) {
      getUserDetails({ variables: { id: selectedUser } });
    }
  }, [getUserDetails, projectsLoaded, selectedUser]);

  useEffect(() => {
    setLoading(
      adding || gettingDetails || gettingProjects || gettingRoles || updating
    );
  }, [
    adding,
    gettingDetails,
    gettingProjects,
    gettingRoles,
    setLoading,
    updating,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpsert}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().required("Please enter the full name"),
        email: Yup.string()
          .required("Please enter the email id")
          .email("Please enter a valid email id"),
        mobile: Yup.string()
          .required("Please enter your contact number")
          .matches(
            /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
            "Contact number must be of 10 digits"
          ),
        roles: Yup.string().required("Please select a role"),
        projects: Yup.array()
          .of(Yup.string())
          .min(1, "Please select atleast 1 project"),
      })}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{ sx: { borderRadius: "24px", overflow: "hidden" } }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    py: 2,
                    backgroundColor: "#B6BCC325",
                    position: "relative",
                    px: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: "13px",
                        color: colors.primaryBlack,
                        fontWeight: 600,
                      }}
                    >
                      User Details
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose}>
                    <Close sx={{ color: "#00000055" }} fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    pt: 4,
                    px: 6,
                    pb: 3,
                    maxHeight: "calc(100vh - 120px)",
                    overflow: "auto",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: { xs: "none", md: "flex" },
                          justifyContent: "center",
                          position: "relative",
                        }}
                        onClick={() => setOpenImageCropUploader(true)}
                      >
                        <Box
                          component="div"
                          onMouseEnter={() => {
                            if (!profilePictureHovered) {
                              setProfilePictureHovered(true);
                            }
                          }}
                        >
                          {values?.profile_picture ? (
                            <Avatar
                              sx={{
                                height: 100,
                                width: 100,
                                cursor: "pointer",
                              }}
                              src={
                                typeof values?.profile_picture === "string"
                                  ? values?.profile_picture
                                  : URL.createObjectURL(values?.profile_picture)
                              }
                            />
                          ) : (
                            <Avatar
                              sx={{
                                height: 100,
                                width: 100,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </Box>
                        {profilePictureHovered && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              position: "absolute",
                              top: 0,
                            }}
                          >
                            <Box
                              component="div"
                              onMouseLeave={() => {
                                if (profilePictureHovered) {
                                  setProfilePictureHovered(false);
                                }
                              }}
                            >
                              <Avatar
                                sx={{
                                  height: 100,
                                  width: 100,
                                  backgroundColor: "#000000",
                                  cursor: "pointer",
                                  opacity: 0.4,
                                }}
                              >
                                <CameraAlt
                                  color="secondary"
                                  sx={{ opacity: 1, fontSize: 35 }}
                                />
                              </Avatar>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "flex", md: "none" },
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        {values?.profile_picture ? (
                          <Avatar
                            sx={{ height: 100, width: 100, cursor: "pointer" }}
                            src={
                              typeof values?.profile_picture === "string"
                                ? values?.profile_picture
                                : URL.createObjectURL(values?.profile_picture)
                            }
                          />
                        ) : (
                          <Avatar
                            sx={{
                              height: 100,
                              width: 100,
                              cursor: "pointer",
                            }}
                          />
                        )}
                        <Box sx={{ position: "absolute", bottom: -10 }}>
                          <IconButton
                            size="small"
                            sx={{
                              backgroundColor: "#fff",
                              border: "1px #e6e6e6 solid",
                              height: 25,
                              width: 25,
                            }}
                            onClick={() => setOpenImageCropUploader(true)}
                          >
                            <ModeEditOutlineOutlined sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        {ClientUsersLabels.ADMIN_FORM.FULL_NAME}{" "}
                        <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      <TextField
                        id="fullName"
                        value={values?.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.input_field }}
                      />
                      {touched?.fullName && errors?.fullName && (
                        <Typography sx={styles.error_text}>
                          {errors?.fullName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Email <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      <TextField
                        id="email"
                        value={values?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={Boolean(selectedUser)}
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.input_field }}
                      />
                      {touched?.email && errors?.email && (
                        <Typography sx={styles.error_text}>
                          {errors?.email}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Mobile <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      {/* <TextField
                        type="number"
                        id="mobile"
                        value={values?.mobile}
                        onChange={(e) => {
                          if (e.target.value.toString().length <= 10) {
                            setFieldValue("mobile", e.target.value.toString());
                          }
                        }}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.input_field }}
                      /> */}
                      <PhoneNumberInput
                        value={values.mobile}
                        onChange={(e) => {
                          setFieldValue("mobile", e.target.value.toString());
                        }}
                        onBlur={handleBlur("mobile")}
                      />
                      {touched?.mobile && errors?.mobile && (
                        <Typography sx={styles.error_text}>
                          {errors?.mobile}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        {ClientUsersLabels.ADMIN_FORM.ROLE}{" "}
                        <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      {values.roles === "admin" ? (
                        <TextField
                          size="small"
                          fullWidth
                          value={values.roles}
                          disabled
                          inputProps={{ style: styles.input_field }}
                        />
                      ) : (
                        <Autocomplete
                          id="roles"
                          value={
                            rolesArr.length > 0
                              ? rolesArr.find((x) => x.name === values.roles)
                              : { id: "", name: "" }
                          }
                          onChange={(_, newValue) =>
                            setFieldValue(`roles`, newValue.name)
                          }
                          onBlur={handleBlur}
                          disableClearable
                          size="small"
                          options={rolesArr}
                          getOptionLabel={(option) => option.name}
                          sx={styles.input_field}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  ...styles.input_field,
                                  backgroundColor: "transparent",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                      {touched?.roles && errors?.roles && (
                        <Typography sx={styles.error_text}>
                          {errors?.roles}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        {ClientUsersLabels.ADMIN_FORM.PROJ}{" "}
                        <span style={{ color: "#D3010E" }}>*</span>
                      </Typography>
                      <Autocomplete
                        id="projects"
                        value={getProjectDropdownValues(values.projects)}
                        onChange={(_, newValue) =>
                          setFieldValue(
                            `projects`,
                            newValue.map((x) => x.id)
                          )
                        }
                        onBlur={handleBlur}
                        multiple
                        disableClearable
                        size="small"
                        options={projectArr}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) =>
                          values.projects.includes("ALL") && option.id !== "ALL"
                        }
                        limitTags={3}
                        sx={styles.input_field}
                        // ChipProps={{
                        //   sx: {
                        //     borderRadius: "4px",
                        //     backgroundColor: "#285EC720",
                        //     color: colors.primaryBlack,
                        //   },
                        //   deleteIcon: <Close fontSize="small" />,
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                      {touched?.projects && errors?.projects && (
                        <Typography sx={styles.error_text}>
                          {errors?.projects}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={styles.flex_JCfe}>
                        <BlackActionContainedButton
                          variant="contained"
                          size="small"
                          sx={{
                            borderRadius: "20px",
                            ml: 1,
                            fontSize: "13px",
                            fontWeight: 600,
                            width: "120px",
                          }}
                          disableElevation
                          disableRipple
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </BlackActionContainedButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {openImageCropUploader && (
                <ProfilePhotoCircularCropper
                  open={openImageCropUploader}
                  setOpen={setOpenImageCropUploader}
                  width={100}
                  imgSrc={values?.profile_picture}
                  setImgSrc={(file: any) =>
                    setFieldValue("profile_picture", file)
                  }
                />
              )}
            </Grid>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default AddEditAdminModal;
