export const emptyAgencyValues = {
  companyName: "",
  spocName: "",
  spocMobile: "",
  spocEmailId: "",
  website: "",
  address: null,
  street: "",
  suburb: "",
  companyCity: "",
  companyState: "",
  companyCountry: "",
  pinCode: "",
  abn: "",
  acn: "",
  registrationNumber: "",
  publicLiabilityPolicy: "",
  provider: "",
  policyNumber: "",
  insurancePeriod: "",
  financialDetails: {
    accountName: "",
    accountNumber: "",
    accountBsb: "",
    accountSwiftCode: "",
    bankName: "",
    branchName: "",
    accountInstructions: "",
    abn: "",
    superannuationFundName: "",
    superannuationAccountNo: "",
    taxFileNo: "",
    memberNumber: "",
    haveSuperannuationAccount: "",
  },
};

export const setAgencyDetails = (
  values: any,
  setFieldValue: any,
  setAddressField: any
) => {
  const {
    id,
    // companyCity,
    // companyCountry,
    // companyState,
    agentFinancialDetails,
    photo,
    __typename,
    ...rest
  } = values;
  setAddressField(rest?.address);
  setFieldValue("agencyDetails", {
    ...rest,
    financialDetails: {
      accountName: agentFinancialDetails?.accountName || "",
      accountNumber: agentFinancialDetails?.accountNumber || "",
      accountBsb: agentFinancialDetails?.accountBsb || "",
      accountSwiftCode: agentFinancialDetails?.accountSwiftCode || "",
      bankName: agentFinancialDetails?.bankName || "",
      branchName: agentFinancialDetails?.branchName || "",
      accountInstructions: agentFinancialDetails?.accountInstructions || "",
      abn: agentFinancialDetails?.abn || "",
      superannuationFundName:
        agentFinancialDetails?.superannuationFundName || "",
      superannuationAccountNo:
        agentFinancialDetails?.superannuationAccountNo || "",
      taxFileNo: agentFinancialDetails?.taxFileNo || "",
    },
  });
};
