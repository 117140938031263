import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Avatar,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CameraAlt, Close, ModeEditOutlineOutlined } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import {
  CREATE_CHECKLIST,
  GET_CHECKLIST_DETAILS,
  UPDATE_CHECKLIST,
} from "graphql/checklist";
import { GET_USERS_ARR } from "graphql/users";
import { useStore } from "utils/store";
import { BlackActionContainedButton } from "common/Buttons";
import { IChecklistFields } from "models/checklists";
import PhoneNumberInput from "common/PhoneNumberInput";
import DatePickerInput from "common/DatePickerInput";
import DeliveryPhotoCropper from "common/ImageCropper/DeliveryPhotoCropper";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IAddEditChecklistModalProps extends IModalProps {
  refetch: any;
  selectedChecklist: string;
  setSelectedChecklist: Dispatch<SetStateAction<string>>;
  setSelectedChecklistTemplate: Dispatch<SetStateAction<IArr | null>>;
  selectedChecklistTemplate: IArr | null;
}

function AddEditChecklistModal(props: IAddEditChecklistModalProps) {
  const {
    open,
    setOpen,
    selectedChecklist,
    setSelectedChecklist,
    refetch,
    selectedChecklistTemplate,
    setSelectedChecklistTemplate,
  } = props;

  const { setLoading } = useStore();
  const projectId = useParams();

  const [initialValues, setInitialValues] = useState<IChecklistFields>({
    id: "",
    name: "",
    description: "",
    deliveryDate: "",
    scheduleVersion: "",
    clientContactName: "",
    clientContactNumber: "",
    profile_picture: null,
    schedule_copy: null,
    deliveryManagerId: "",
  });
  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
  const [profilePictureHovered, setProfilePictureHovered] = useState(false);
  const [usersArr, setUsersArr] = useState<IArr[]>([]);

  const [getChecklistDetails, { loading: gettingDetails }] = useLazyQuery(
    GET_CHECKLIST_DETAILS,
    {
      onCompleted: (data) => {
        const { getChecklist } = data;
        const { status, checklist } = getChecklist;
        if (status) {
          const {
            id,
            name,
            description,
            photo,
            deliveryDate,
            scheduleVersion,
            clientContactName,
            clientContactNumber,
            deliveryManager,
            scheduleName,
            template,
          } = checklist;
          setInitialValues({
            id,
            name,
            description,
            profile_picture: photo,
            deliveryDate,
            scheduleVersion,
            clientContactName,
            clientContactNumber,
            schedule_copy: scheduleName,
            deliveryManagerId: deliveryManager?.id || "",
          });
          setSelectedChecklistTemplate({
            id: template.id,
            name: template.name,
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const {} = useQuery(GET_USERS_ARR, {
    variables: {
      filter: { projects__project__id__in: [projectId?.project_id] },
    },
    onCompleted: (data) => {
      const { users } = data;
      const { count, users: rawUsers } = users;
      if (count > 0) {
        setUsersArr(rawUsers.map((x: any) => ({ id: x.id, name: x.fullName })));
      } else {
        setUsersArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setSelectedChecklist("");
    setSelectedChecklistTemplate(null);
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireCreateChecklistApi, { loading: adding }] = useMutation(
    CREATE_CHECKLIST,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { createChecklist } = data;
        const { status, message } = createChecklist;
        handleClose();
        if (status) {
          toast.success(message);
          refetch();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const [fireUpdateChecklistApi, { loading: updating }] = useMutation(
    UPDATE_CHECKLIST,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateChecklist } = data;
        const { status, message } = updateChecklist;
        handleClose();
        if (status) {
          toast.success(message);
          refetch();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const handleChecklistUpsert = (values: IChecklistFields) => {
    const {
      id,
      profile_picture,
      deliveryDate,
      schedule_copy,
      deliveryManagerId,
      ...rest
    } = values;
    if (Boolean(selectedChecklist)) {
      fireUpdateChecklistApi({
        variables: {
          payload: {
            projectId: projectId?.project_id,
            id: id,
            ...rest,
            ...(Boolean(deliveryManagerId) && { deliveryManagerId }),
            ...(Boolean(deliveryDate) && { deliveryDate }),
            ...(profile_picture === null && { removeProfilePhoto: true }),
          },
          ...(Boolean(profile_picture) &&
            typeof profile_picture !== "string" && { file: profile_picture }),
          ...(Boolean(schedule_copy) &&
            typeof schedule_copy !== "string" && { schedule: schedule_copy }),
        },
      });
    } else {
      fireCreateChecklistApi({
        variables: {
          payload: {
            projectId: projectId?.project_id,
            ...rest,
            ...(Boolean(deliveryDate) && { deliveryDate }),
            ...(Boolean(deliveryManagerId) && { deliveryManagerId }),
            ...(Boolean(selectedChecklistTemplate) && {
              templateId: selectedChecklistTemplate!.id,
            }),
          },
          ...(Boolean(profile_picture) &&
            typeof profile_picture !== "string" && { file: profile_picture }),
          ...(Boolean(schedule_copy) &&
            typeof schedule_copy !== "string" && { schedule: schedule_copy }),
        },
      });
    }
  };

  useEffect(() => {
    if (Boolean(selectedChecklist)) {
      getChecklistDetails({
        variables: { project_id: projectId?.project_id, id: selectedChecklist },
      });
    }
  }, [getChecklistDetails, projectId?.project_id, selectedChecklist]);

  useEffect(() => {
    setLoading(adding || gettingDetails || updating);
  }, [adding, gettingDetails, setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Please enter the name of the delivery schedule")
          .matches(/^[^/]*$/, "Delivery schedule name cannot contain '/'"),
        description: Yup.string().required(
          "Please give the description of the checklist"
        ),
        clientContactNumber: Yup.string().matches(
          /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
          "Contact number must be of 10 digits"
        ),
      })}
      onSubmit={handleChecklistUpsert}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        setFieldTouched,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="md"
            PaperProps={{
              sx: { borderRadius: "24px", height: "680px", overflow: "hidden" },
            }}
          >
            <Box
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  py: 2,
                  backgroundColor: "#B6BCC325",
                  position: "relative",
                  px: { xs: 2, sm: 3, md: 4 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: "13px",
                      color: colors.primaryBlack,
                      fontWeight: 600,
                    }}
                  >
                    Delivery Schedule
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <IconButton size="small" onClick={handleClose}>
                  <Close sx={{ color: "#00000055" }} fontSize="small" />
                </IconButton>
              </Box>
              <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                <Box
                  sx={{
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      px: { xs: 2, sm: 6 },
                      py: 3,
                    }}
                  >
                    {Boolean(selectedChecklistTemplate) && (
                      <Box sx={{ mb: 2 }}>
                        <Typography sx={{ color: "#000", fontSize: "13px" }}>
                          Template:{" "}
                          <span style={{ fontWeight: 600 }}>
                            {selectedChecklistTemplate?.name}
                          </span>
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: { xs: "center", sm: "flex-start" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          alignItems: { xs: "center", sm: "flex-start" },
                          flexDirection: "column",
                          width: { xs: "100%", sm: "auto" },
                        }}
                      >
                        <Box
                          sx={{
                            width: 210,
                            height: 147,
                            aspectRatio: 1 / 0.7,
                            backgroundColor: "#B6BCC320",
                            borderRadius: "15px",
                            position: "relative",
                            cursor: "pointer",
                            display: { xs: "block", md: "none" },
                          }}
                        >
                          {values.profile_picture ? (
                            <img
                              src={
                                Boolean(values.profile_picture) &&
                                typeof values.profile_picture === "string"
                                  ? values.profile_picture
                                  : URL.createObjectURL(values.profile_picture)
                              }
                              alt="logo"
                              style={{
                                width: "210px",
                                height: "147px",
                                objectFit: "cover",
                                borderRadius: "15px",
                                aspectRatio: 1 / 0.7,
                              }}
                            />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                p: 1,
                              }}
                            ></Box>
                          )}
                          <Box
                            sx={{ position: "absolute", top: 10, right: 10 }}
                          >
                            <IconButton
                              sx={{ backgroundColor: "#fff", opacity: 0.8 }}
                              size="small"
                              onClick={() => setOpenImageCropUploader(true)}
                            >
                              <ModeEditOutlineOutlined fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box onClick={() => setOpenImageCropUploader(true)}>
                          <Box
                            sx={{
                              width: 210,
                              height: 147,
                              aspectRatio: 1 / 0.7,
                              backgroundColor: "#B6BCC320",
                              borderRadius: "15px",
                              position: "relative",
                              cursor: "pointer",
                              display: { xs: "none", md: "block" },
                            }}
                            component="div"
                            onMouseEnter={() => {
                              if (!profilePictureHovered) {
                                setProfilePictureHovered(true);
                              }
                            }}
                          >
                            {values.profile_picture ? (
                              <img
                                src={
                                  Boolean(values.profile_picture) &&
                                  typeof values.profile_picture === "string"
                                    ? values.profile_picture
                                    : URL.createObjectURL(
                                        values.profile_picture
                                      )
                                }
                                alt="logo"
                                style={{
                                  width: "210px",
                                  height: "147px",
                                  objectFit: "cover",
                                  borderRadius: "15px",
                                  aspectRatio: 1 / 0.7,
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  p: 1,
                                }}
                              ></Box>
                            )}
                            {profilePictureHovered && (
                              <Box
                                sx={{
                                  width: 210,
                                  height: 147,
                                  aspectRatio: 1 / 0.7,
                                  backgroundColor: "#000000",
                                  borderRadius: "15px",
                                  position: "absolute",
                                  cursor: "pointer",
                                  top: 0,
                                  opacity: 0.1,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: " center",
                                }}
                                component="div"
                                onMouseLeave={() => {
                                  if (profilePictureHovered) {
                                    setProfilePictureHovered(false);
                                  }
                                }}
                              >
                                <Avatar
                                  sx={{
                                    height: 70,
                                    width: 70,
                                    backgroundColor: "#000000",
                                    cursor: "pointer",
                                    opacity: 1,
                                  }}
                                >
                                  <CameraAlt
                                    color="secondary"
                                    sx={{ opacity: 1, fontSize: 40 }}
                                  />
                                </Avatar>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          ml: { xs: 0, sm: 2 },
                          mt: { xs: 2, sm: 0 },
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={styles.field_label}>
                              Delivery Name{" "}
                              <span style={styles.error_text}>*</span>
                            </Typography>
                            <TextField
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              size="small"
                              fullWidth
                              inputProps={{ style: styles.input_field }}
                            />
                            {touched.name && (
                              <Typography sx={styles.error_text}>
                                {errors.name}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={styles.field_label}>
                              Description{" "}
                              <span style={styles.error_text}>*</span>
                            </Typography>
                            <TextField
                              id="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              size="small"
                              fullWidth
                              inputProps={{
                                style: {
                                  ...styles.input_field,
                                  backgroundColor: "transparent",
                                },
                              }}
                              multiline
                              minRows={1}
                              maxRows={1}
                              sx={{
                                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                  backgroundColor: "#e6e6e640",
                                },
                              }}
                            />
                            {touched.description && (
                              <Typography sx={styles.error_text}>
                                {errors.description}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2, flexGrow: 1 }}>
                      <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Delivery Date
                          </Typography>
                          <DatePickerInput
                            textValue={
                              Boolean(values.deliveryDate)
                                ? moment(values.deliveryDate).format(
                                    "DD MMMM YYYY"
                                  )
                                : ""
                            }
                            value={
                              Boolean(values.deliveryDate)
                                ? new Date(values.deliveryDate)
                                : null
                            }
                            onChange={(newValue: any) => {
                              setFieldTouched("deliveryDate", true);
                              setFieldValue(
                                "deliveryDate",
                                moment(newValue).format("YYYY-MM-DD")
                              );
                            }}
                            onOpen={() => setFieldTouched("deliveryDate", true)}
                            onCloseCustom={() => {}}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Schedule Version
                          </Typography>
                          <TextField
                            id="scheduleVersion"
                            value={values.scheduleVersion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{ style: styles.input_field }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Client Contact Name
                          </Typography>
                          <TextField
                            id="clientContactName"
                            value={values.clientContactName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{ style: styles.input_field }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Client Contact Number
                          </Typography>
                          <PhoneNumberInput
                            value={values.clientContactNumber}
                            onChange={(e) => {
                              setFieldValue(
                                "clientContactNumber",
                                e.target.value
                              );
                            }}
                            onBlur={handleBlur("clientContactNumber")}
                          />
                          {touched.clientContactNumber && (
                            <Typography sx={styles.error_text}>
                              {errors.clientContactNumber}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography sx={styles.field_label}>
                            Delivery Manager
                          </Typography>
                          <Autocomplete
                            id="deliveryManagerId"
                            value={
                              usersArr.find(
                                (x) => x.id === values.deliveryManagerId
                              ) || {
                                id: "",
                                name: "",
                              }
                            }
                            onChange={(_, newValue) => {
                              setFieldValue("deliveryManagerId", newValue.id);
                            }}
                            sx={styles.input_field}
                            size="small"
                            disableClearable
                            fullWidth
                            options={usersArr}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  style: {
                                    ...styles.input_field,
                                    backgroundColor: "transparent",
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography sx={styles.field_label}>
                            Copy of Schedule
                          </Typography>
                          {!Boolean(values.schedule_copy) && (
                            <Upload
                              beforeUpload={(file: RcFile, __) => {
                                // setScriptError("");
                                setFieldValue("schedule_copy", file);
                                return Promise.resolve();
                              }}
                            >
                              <Box
                                sx={[styles.upload_file_container, { mt: 1 }]}
                              >
                                <img
                                  src={images.UPLOAD}
                                  alt="upload"
                                  style={{ height: 30 }}
                                />
                                <Typography
                                  sx={[
                                    styles.upload_text,
                                    { fontSize: "13px" },
                                  ]}
                                >
                                  Drag & drop, or browse your files
                                </Typography>
                              </Box>
                            </Upload>
                          )}
                          {values.schedule_copy && (
                            <Box
                              sx={[
                                styles.flex_JCsb_Acenter,
                                {
                                  border: `1px #e6e6e6 solid`,
                                  py: 1.5,
                                  px: 2,
                                  borderRadius: "4px",
                                  mt: 1,
                                },
                              ]}
                            >
                              <Box sx={styles.flex_Acenter}>
                                <Typography sx={{ fontSize: "13px" }}>
                                  {Boolean(values.schedule_copy)
                                    ? typeof values.schedule_copy === "string"
                                      ? values.schedule_copy
                                      : (values.schedule_copy as any)["name"]
                                    : ""}
                                </Typography>
                              </Box>
                              <Upload
                                beforeUpload={(file: RcFile, __) => {
                                  // setScriptError("");
                                  setFieldValue("schedule_copy", file);
                                  return Promise.resolve();
                                }}
                              >
                                <IconButton size="small">
                                  <ModeEditOutlineOutlined
                                    sx={{ color: "#00000055" }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Upload>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ pt: 1.5, pb: 1 }}>
                      <Box sx={styles.flex_JCfe}>
                        <BlackActionContainedButton
                          variant="contained"
                          size="small"
                          sx={{
                            borderRadius: "20px",
                            fontSize: "13px",
                            fontWeight: 600,
                            width: "120px",
                          }}
                          disableElevation
                          disableRipple
                          disabled={!isValid}
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </BlackActionContainedButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {openImageCropUploader && (
                <DeliveryPhotoCropper
                  open={openImageCropUploader}
                  setOpen={setOpenImageCropUploader}
                  width={210}
                  height={147}
                  imgSrc={values.profile_picture}
                  setImgSrc={(file: any) =>
                    setFieldValue("profile_picture", file)
                  }
                />
              )}
            </Box>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

export default AddEditChecklistModal;
