import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Grid, IconButton, Menu, Typography } from "@mui/material";
import { Add, FilterAltOutlined, Person, Search } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";

import Table from "common/Table";
import { IUser } from "models/user";
import { GET_USERS } from "graphql/users";
import { useStore } from "utils/store";
import { useClientSettingsTabs } from "hooks/useClientSettingsTabs";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { colors } from "theme/colors";

import Filters from "./Filters";
import AddEditAdminModal from "./AddEditAdminModal";
import DeleteModal from "./DeleteModal";
import { AccessStyles as styles } from "./styles";
import Settings from "..";
import { getPermissions } from "permissions/utils";

export const Users = () => {
  const { setLoading } = useStore();
  const settingsTab = useClientSettingsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const settingsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const settingsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [addEditAdminModal, setAddEditAdminModal] = useState(false);
  // const [permissionsModal, setPermissionsModal] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userCount, setUserCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const { loading, refetch, fetchMore } = useQuery(GET_USERS, {
    variables: {
      page: 1,
      limit: 50,
      filter: {
        fullName__icontains: searchText,
      },
    },
    onCompleted: (data) => {
      setLoading(false);
      const { users } = data;
      const { count, users: rawUsers } = users;
      setUserCount(count);
      if (count > 0) {
        setUsers([...rawUsers]);
      } else {
        setUsers([]);
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      settingsViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[settingsViewIndex]?.SETTINGS_VIEW
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const columns: GridColDef[] = useMemo(
    () => [
      // { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 90 },
      {
        field: "fullName",
        headerName: "Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box sx={styles.flex_Acenter}>
            {Boolean(params?.row?.profilePicture) ? (
              <Avatar
                sx={[styles.avatar, styles.mr1]}
                src={params?.row?.profilePicture}
              />
            ) : (
              <Avatar sx={[styles.avatar, styles.mr1]}>
                <Person />
              </Avatar>
            )}
            <Typography
              sx={[
                styles.cell_text,
                { width: "100%", wordBreak: "break-word" },
              ]}
            >
              {params?.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "roles",
        headerName: "Role",
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, { fontWeight: 600 }]}>
            {params?.value}
          </Typography>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 250,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography
            sx={[styles.cell_text, { width: "100%", wordBreak: "break-word" }]}
          >
            {params?.value}
          </Typography>
        ),
      },
      {
        field: "mobile",
        headerName: "Phone",
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      ...(Boolean(ROLE_PERMISSIONS) &&
      settingsEditIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[settingsEditIndex]?.SETTINGS_EDIT
        ? [
            {
              field: "actions",
              type: "actions",
              headerName: "",
              sortable: false,
              width: 90,
              getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  showInMenu
                  label="Edit"
                  onClick={() => {
                    setSelectedUser(params.row.id);
                    setAddEditAdminModal(true);
                  }}
                  sx={styles.action_text}
                />,
                <GridActionsCellItem
                  showInMenu
                  label="Delete"
                  onClick={() => {
                    setSelectedUser(params.row.id);
                    setSelectedUserName(params.row.fullName);
                    setDeleteModal(true);
                  }}
                  sx={styles.action_text}
                />,
              ],
            },
          ]
        : []),
    ],
    [ROLE_PERMISSIONS, settingsEditIndex]
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (users.length < userCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(users.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.users.users;
              return {
                users: {
                  ...fetchMoreResult?.users,
                  users: [...users, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, setLoading, users, userCount]);

  useEffect(() => {
    settingsTab.setSelectedTab(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Settings>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={styles.flex_JCsb_Acenter}>
            {Boolean(ROLE_PERMISSIONS) &&
              settingsEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[settingsEditIndex]
                ?.SETTINGS_EDIT && (
                <NewGreenPrimaryContainedButton
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => setAddEditAdminModal(true)}
                  sx={{
                    fontWeight: 600,
                    fontSize: "13px",
                    width: "120px",
                    borderRadius: "8px",
                    py: 0.7,
                  }}
                  startIcon={<Add fontSize="small" sx={{ color: "#000000" }} />}
                >
                  Add User
                </NewGreenPrimaryContainedButton>
              )}
            <Box sx={[styles.flex_JCfe, { flexGrow: 1 }]}>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 350 } }}
              >
                <Filters
                  setSearchText={setSearchText}
                  searchText={searchText}
                />
              </Menu>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {!loading && searchText && users.length === 0 && (
            <Box
              sx={{
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Search
                sx={{ color: `${colors.primaryBlack}30`, fontSize: 80 }}
              />
              <Typography
                sx={{
                  mt: 2,
                  color: `${colors.primaryBlack}30`,
                  fontSize: "32px",
                  fontWeight: 500,
                }}
                textAlign="center"
              >
                No results found
              </Typography>
            </Box>
          )}
          {!loading && users.length > 0 && (
            <Table
              rows={users}
              columns={columns}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnMenu
              rowCount={userCount}
              sx={{
                ...styles.table,
                "& .MuiDataGrid-row": {
                  borderBottom: "1px #B6BCC340 solid",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "transparent",
                },
              }}
              hideFooter
            />
          )}
        </Grid>
        {addEditAdminModal && (
          <AddEditAdminModal
            open={addEditAdminModal}
            setOpen={setAddEditAdminModal}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            refetchTableData={refetch}
          />
        )}
        {deleteModal && (
          <DeleteModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            selectedUserName={selectedUserName}
            setSelectedUserName={setSelectedUserName}
            refetchTableData={refetch}
          />
        )}
      </Grid>
    </Settings>
  );
};
