import React from "react";
import {
  Box,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

import { images } from "assets/images";
import { colors } from "theme/colors";

function PhoneNumberInput(
  props: TextFieldProps & { backgroundColor?: string }
) {
  const { backgroundColor = "#e6e6e640", ...restProps } = props;

  const getDigitOnlyPhone = (value: any) =>
    value.toString().replace(/\D/g, "").substring(0, 10);

  const getFormattedPhone = (value: any) => {
    if (!value) return "";

    const phone = getDigitOnlyPhone(value);
    const areaCode = phone.substring(0, 4);
    const middle = phone.substring(4, 7);
    const last = phone.substring(7, 10);

    let formattedPhone = "";
    if (phone.length > 7) {
      formattedPhone = `${areaCode} ${middle} ${last}`;
    } else if (phone.length > 4) {
      formattedPhone = `${areaCode} ${middle}`;
    } else if (phone.length > 0) {
      formattedPhone = `${areaCode}`;
    }

    return formattedPhone;
  };

  return (
    <TextField
      {...restProps}
      value={getFormattedPhone(props.value)}
      onChange={(e) => {
        if (e.target.value.length <= 12) {
          props.onChange && props.onChange(e);
        }
      }}
      size="small"
      placeholder="0444 444 444"
      fullWidth
      inputProps={{
        style: {
          backgroundColor: backgroundColor,
          borderRadius: "6px",
          fontSize: "13px",
          color: colors.primaryBlack,
          paddingLeft: "8px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: 0 }}>
            <Box
              sx={{
                backgroundColor: backgroundColor,
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
                py: "3px",
                pl: 2,
                ml: -1.75,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={images.AUS_FLAG} alt="flag" height={30} />
              <Typography sx={{ ml: 1, fontSize: "13px" }}>+61</Typography>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PhoneNumberInput;
