import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";
import { FormikProps } from "formik";
import { useLazyQuery, useQuery } from "@apollo/client";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { ICreateContactFieldsNew } from "models/contacts";
import { IGeoCoding } from "models/common";
import { IAgent } from "models/agents";
import { GET_ADDRESS_AUTOCOMPLETE } from "graphql/meta";
import { GET_ALL_AGENTS } from "graphql/agents";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { getPermissions } from "permissions/utils";

import { setAgencyDetails } from "./utils";

interface IAgentArr extends IAgent {
  inputValue?: string;
}

const filter = createFilterOptions<IAgentArr>();

interface ICompanyDetailsFormProps {
  formikBag: FormikProps<ICreateContactFieldsNew>;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setPrevActiveSection: Dispatch<SetStateAction<number>>;
  setNewAgent: Dispatch<SetStateAction<boolean>>;
}

function CompanyDetailsForm(props: ICompanyDetailsFormProps) {
  const {
    formikBag,
    setActiveSection,
    setActiveStep,
    setPrevActiveSection,
    setNewAgent,
  } = props;
  const { values, handleChange, setFieldValue, handleBlur, errors, touched } =
    formikBag;

  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const agentsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
  const [addressSearchText, setAddressSearchText] = useState("");
  const [agentsArr, setAgentsArr] = useState<IAgentArr[]>([]);

  const {} = useQuery(GET_ALL_AGENTS, {
    variables: { sorted: "company_name" },
    onCompleted: (data) => {
      const { getAllAgents } = data;
      const { status, agents } = getAllAgents;
      if (status) {
        setAgentsArr(agents);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
    onCompleted: (data) => {
      const { autocompleteAddress } = data;
      setAddressArr(autocompleteAddress);
      if (
        autocompleteAddress.length > 0 &&
        Boolean(values.agencyDetails.address) &&
        typeof values.agencyDetails.address === "string"
      ) {
        setFieldValue("agencyDetails.address", autocompleteAddress[0]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (addressSearchText) {
      getGeoCodeAddress({ variables: { filter: addressSearchText } });
    }
  }, [addressSearchText, getGeoCodeAddress]);

  useEffect(() => {
    if (Boolean(values.agencyDetails.address)) {
      if (typeof values.agencyDetails.address === "string") {
        setAddressSearchText(values.agencyDetails.address);
      } else {
        setAddressSearchText(
          (values.agencyDetails.address as IGeoCoding).freeformAddress
        );
      }
    } else {
      setAddressSearchText("");
    }
  }, [values.agencyDetails.address]);

  const isValidArrStep3 = (errors: any) => {
    const agencyErrs: any = errors?.agencyDetails || {};
    return ["spocEmailId", "spocMobile", "website"].filter(
      (x: string) => agencyErrs[x]
    );
  };

  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 14 },
        px: 4,
        pb: 1.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Company Name</Typography>
          {!(
            Boolean(ROLE_PERMISSIONS) &&
            agentsViewIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[agentsViewIndex]?.AGENTS_VIEW
          ) ? (
            <TextField
              id="agencyDetails.companyName"
              value={values.agencyDetails.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              inputProps={{ style: styles.input_field }}
            />
          ) : (
            <Autocomplete
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={
                agentsArr.find(
                  (x) => x.id === values.agencyDetails.companyName
                ) || values.agencyDetails.companyName
              }
              onChange={(_, newValue) => {
                if (typeof newValue === "string") {
                  setNewAgent(true);
                  setFieldValue("agencyDetails.companyName", newValue);
                } else if (newValue && newValue.inputValue) {
                  setNewAgent(true);
                  setFieldValue(
                    "agencyDetails.companyName",
                    newValue.inputValue
                  );
                } else {
                  setAgencyDetails(newValue, setFieldValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    companyName: `Add "${params.inputValue}"`,
                    id: "",
                    spocName: "",
                    spocEmailId: "",
                    spocMobile: "",
                    website: "",
                    address: "",
                    street: "",
                    suburb: "",
                    companyCity: "",
                    companyState: "",
                    companyCountry: "",
                    pinCode: "",
                    abn: "",
                    acn: "",
                    registrationNumber: "",
                    publicLiabilityPolicy: "",
                    provider: "",
                    policyNumber: "",
                    insurancePeriod: "",
                    agentFinancialDetails: {
                      id: "",
                      accountName: "",
                      accountNumber: "",
                      accountBsb: "",
                      accountSwiftCode: "",
                      bankName: "",
                      branchName: "",
                      accountInstructions: "",
                      abn: "",
                      superannuationFundName: "",
                      superannuationAccountNo: "",
                      taxFileNo: "",
                      memberNumber: "",
                      haveSuperannuationAccount: "",
                    },
                    __typename: "",
                  });
                }

                return filtered;
              }}
              sx={styles.input_field}
              size="small"
              disableClearable
              fullWidth
              options={agentsArr}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return option.companyName;
              }}
              renderOption={(props1, opt, _, __) => (
                <Typography
                  {...props1}
                  sx={{ fontSize: "14px", fontWeight: 600 }}
                >
                  {opt.companyName}
                  {!Boolean(opt.inputValue) && (
                    <span style={{ color: "#00000080", fontWeight: 400 }}>
                      &nbsp;{`(${opt.spocName})`}
                    </span>
                  )}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      ...styles.input_field,
                      backgroundColor: "transparent",
                    },
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Agent Full Name</Typography>
          <TextField
            id="agencyDetails.spocName"
            value={values.agencyDetails.spocName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Email</Typography>
          <TextField
            id="agencyDetails.spocEmailId"
            value={values.agencyDetails.spocEmailId}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched?.agencyDetails?.spocEmailId &&
            errors?.agencyDetails?.spocEmailId && (
              <Typography sx={styles.error_text}>
                {errors?.agencyDetails?.spocEmailId}
              </Typography>
            )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Mobile</Typography>
          <PhoneNumberInput
            value={values.agencyDetails.spocMobile}
            onChange={(e) => {
              setFieldValue("agencyDetails.spocMobile", e.target.value);
            }}
            onBlur={handleBlur("agencyDetails.spocMobile")}
          />
          {touched?.agencyDetails?.spocMobile &&
            errors?.agencyDetails?.spocMobile && (
              <Typography sx={styles.error_text}>
                {errors?.agencyDetails?.spocMobile}
              </Typography>
            )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Address</Typography>
          <Autocomplete
            id="agencyDetails.address"
            getOptionLabel={(option) => option.freeformAddress}
            value={
              Boolean(values.agencyDetails.address)
                ? typeof values.agencyDetails.address !== "string"
                  ? values.agencyDetails.address!
                  : {
                      streetNumber: "",
                      streetName: "",
                      municipalitySubdivision: "",
                      municipality: "",
                      countrySubdivision: "",
                      postalCode: "",
                      country: "",
                      freeformAddress: "",
                    }
                : undefined
            }
            onChange={(event, newValue) => {
              setFieldValue("agencyDetails.address", newValue);
            }}
            inputValue={addressSearchText}
            onInputChange={(event: any, newInputValue) => {
              if (Boolean(event?.target)) {
                setAddressSearchText(newInputValue);
              }
            }}
            options={addressArr}
            popupIcon={
              <LocationOnOutlined
                fontSize="small"
                sx={{ color: "#00000050" }}
              />
            }
            sx={[
              styles.input_field,
              { "& .MuiAutocomplete-popupIndicator": { transform: "none" } },
            ]}
            size="small"
            disableClearable
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...styles.input_field,
                    backgroundColor: "transparent",
                  },
                }}
              />
            )}
            noOptionsText={
              <Typography sx={{ fontSize: "13px", color: "#0f0f0f80" }}>
                {addressSearchText ? "No match found" : ""}
              </Typography>
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Website</Typography>
          <TextField
            id="agencyDetails.website"
            value={values.agencyDetails.website}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched?.agencyDetails?.website &&
            errors?.agencyDetails?.website && (
              <Typography sx={styles.error_text}>
                {errors?.agencyDetails?.website}
              </Typography>
            )}
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
            <OutlinedBtn
              size="small"
              sx={{
                borderRadius: "20px",
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
                color: "#000",
              }}
              disableElevation
              disableRipple
              onClick={() => {
                setPrevActiveSection(2);
                setActiveStep(1);
              }}
            >
              Back
            </OutlinedBtn>
            <BlackActionContainedButton
              variant="contained"
              size="small"
              sx={{
                borderRadius: "20px",
                ml: 1,
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
              }}
              disableElevation
              disableRipple
              disabled={isValidArrStep3(errors).length > 0}
              onClick={() => setActiveSection(1)}
            >
              Next
            </BlackActionContainedButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CompanyDetailsForm;
